import React, { useState } from "react";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
import DefaultFriend from "assets/images/default_friend.png";
import FriendSearchCard from "components/card/friend/FriendSearchCard";
import InviteSentIcon from "assets/images/mdi_tick.png";
import { useAddFriendRequestMutation } from "store/slicer/friends";
import { useSearchUserFriendMutation } from "store/slicer/friends";
import Loader from "components/Loader/Loader";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Invite-Friend-Modal-Module
 *@developer Aditya P
 */

const InviteFriendModal = ({ handleModalStatus }) => {
  const [sentFriendRequest] = useAddFriendRequestMutation();
  const [SearchfriendQuery] = useSearchUserFriendMutation();

  const [IssentInvite, setsentInvite] = useState(false);
  const [sentInvite, SetsentInvite] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sentRequest, setSentrequest] = useState({
    sent_email_id: "",
  });
  const [requesterror, setRequestError] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [searchResult, setSearchResult] = useState(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSentrequestChange = (e) => {
    setSentrequest(() => {
      return {
        sent_email_id: e.target.value,
      };
    });
    setRequestError("");
  };

  /**
   *@desc Implement Function Sent Request via email
   */
  const handleSentRequestViaEmail = (e) => {
    setLoad(true);
    e.preventDefault();
    sentFriendRequest({
      email: sentRequest?.sent_email_id,
    })
      .then((res) => {
        setLoad(false);
        if (res.data.response.statusEnum === 1) {
          SetsentInvite(!sentInvite);
        }
        if (res.data.response.statusEnum === 0) {
          setRequestError(res.data.response.message);
        }
        if (res.data.response.statusEnum === 2) {
          setRequestError(res.data.response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *@desc Implement Function search request via email
   */
  const handleSearchFriend = (e) => {
    if (searchTerm.length === 0) return 0;
    setLoad(true);
    e.preventDefault();
    SearchfriendQuery({
      name: searchTerm,
    })
      .then((res) => {
        setLoad(false);
        setSearchResult(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex bg-[#00000099] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9998] outline-none focus:outline-none">
        Overlay
      </div>
      <div
        data-testid="invite-friend-modal"
        className="__invite_search_component hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]"
      >
        <div className="bg-[#2C2C2C] __modal__container w-[35%] max-[768px]:w-[100%] max-h-[400px] max-[1920px]:max-h-[500px] max-[768px]:h-auto max-[1280px]:w-[50%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          {sentInvite ? (
            <></>
          ) : (
            <div className="__modal_close_icon_container flex justify-end p-[10px]">
              <div
                onClick={handleModalStatus}
                className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[20px] h-[20px]"
              >
                <img
                  src={ModalCloseIcon}
                  className="w-[10px] h-[10px] "
                  alt="modal_close_btn"
                />
              </div>
            </div>
          )}
          <div className="__modal_content_main px-[35px] mb-[20px] max-[768px]:p-0 z-9">
            <div className="__modal_heading text-center">
              <h4 className="font-heading text-[13px] ">
                {sentInvite ? "" : "Send Invite to Friends"}
              </h4>
            </div>
            <div className="__input_group_main py-[18px] mx-[20px] max-[768px]:p-0 max-[768px]:mt-[15px]">
              {/* Search Input Component */}
              {IssentInvite ? (
                ""
              ) : (
                <>
                  <form>
                    <div>
                      <label className="font-body text-[14px] text-[#FFFFFFCC] max-[768px]:text-[11px]">
                        Enter name, username
                      </label>
                    </div>
                    <div className="flex mt-[5px] gap-[10px]">
                      <div className="__input_ele w-[80%]">
                        <input
                          className="bg-transparent w-[100%] border border-[#FFFFFF4D] py-[5px] rounded-[8px] px-[10px] text-[14px] font-light  max-[768px]:py-[3px]"
                          type="text"
                          name="search_ele"
                          onChange={(e) => handleSearchChange(e)}
                          required
                        />
                      </div>
                      <div className="__search_button_main w-[20%]">
                        <button
                          onClick={handleSearchFriend}
                          className="border-[2px] py-[5px] rounded-[8px] px-[15px] text-[14px] border-[#F3A151] max-[768px]:py-[3px] max-[768px]:px-[10px] max-[768px]:text-[10px]"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <div className="__invite_via_email_container text-center">
                    <p className="text-[14px] mt-[20px] max-[768px]:text-xs">
                      Did not find User? Try inviting{" "}
                      <span className="text-[#F3A151] font-medium">
                        <button
                          onClick={() => setsentInvite(!IssentInvite)}
                          className="hover:underline"
                        >
                          via Email
                        </button>
                      </span>
                    </p>
                  </div> */}
                </>
              )}

              {/* Sent Email Component */}
              {IssentInvite ? (
                sentInvite ? (
                  ""
                ) : (
                  <form>
                    <div>
                      <label className="font-body text-[14px] text-[#FFFFFFCC] max-[768px]:text-[11px]">
                        Enter email id
                      </label>
                    </div>
                    <div className="flex flex-col mt-[5px] gap-[10px]">
                      <div className="__input_sent_ele w-[100%]">
                        <input
                          className="bg-transparent w-[100%] border border-[#FFFFFF4D] py-[5px] rounded-[8px] px-[10px] text-[14px] font-light max-[768px]:py-[3px]"
                          type="text"
                          name="sent_email_id"
                          placeholder="Enter email"
                          onChange={handleSentrequestChange}
                          required
                        />
                        <span className="pt-4 text-[13px] text-[#F3A151]">
                          {requesterror}
                        </span>
                      </div>
                      <div className="__search_button_main flex justify-center py-[20px]">
                        <button
                          onClick={handleSentRequestViaEmail}
                          className="border-[2px] py-[5px] rounded-[8px] px-[15px] text-[14px] border-[#F3A151] max-[768px]:py-[3px] max-[768px]:px-[10px] max-[768px]:text-[10px]"
                        >
                          {isLoad ? "Sending request..." : "Send Invite"}
                        </button>
                      </div>
                    </div>
                  </form>
                )
              ) : (
                ""
              )}

              {/* Invite Sent Component */}
              {sentInvite ? (
                <div className="__invite_sent_dialog flex flex-col gap-[20px] justify-center items-center mt-[15px]">
                  <img
                    src={InviteSentIcon}
                    alt="invite_sent_icon"
                    className="w-[52px] h-[52px]"
                  />
                  <h4 className="font-heading text-[15px]">Invitation Sent</h4>
                  <p className="text-[16px] text-center">
                    They will added to your friends list once they accept your
                    invite.
                  </p>
                  <button
                    onClick={handleModalStatus}
                    className="border-[2px] py-[5px] rounded-[8px] px-[15px] text-[14px] border-[#F3A151] max-[768px]:py-[3px] max-[768px]:px-[10px] max-[768px]:text-[10px]"
                  >
                    Got it!
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Search Container */}
            <div className="p-2 max-h-[250px] lg:max-h-[300px] overflow-y-auto">
              <div className="__search_result_container max-[768px]:mt-[15px] overflow-y-auto">
                {isLoad ? (
                  <div className="flex justify-center">
                    <Loader />
                  </div>
                ) : searchResult === null ? (
                  ""
                ) : searchResult?.response.statusEnum === 0 ||
                  searchResult?.response?.statusEnum === 1 ? (
                  searchResult?.response?.data?.length === 0 ? (
                    <div className="__no_user_found_main text-center py-[50px] px-[35px]">
                      <span className="text-[14px] font-body">
                        No user was found with this name or username.
                      </span>
                      <br />
                      {/* <button
                    onClick={() => {
                      setsentInvite(!IssentInvite);
                      setSearchResult(null);
                    }}
                    className="text-[#F3A151] text-[14px] hover:underline"
                  >
                    Invite via email
                  </button> */}
                    </div>
                  ) : (
                    searchResult?.response?.data?.map((_searchItem, ind) => {
                      return (
                        <div className="__friend_list_scroll_container pr-[10px] max-h-[250px] px-[25px]">
                          <FriendSearchCard
                            key={_searchItem?._id}
                            avtarImg={
                              _searchItem?.photo === null ||
                              _searchItem?.photo === ""
                                ? DefaultFriend
                                : _searchItem?.photo
                            }
                            username={`@${_searchItem?.userName}`}
                            fullname={`${_searchItem?.firstName} ${
                              _searchItem?.lastName === null
                                ? ""
                                : _searchItem?.lastName
                            }`}
                            email={_searchItem?.email}
                            statustype={_searchItem?.requestStatus}
                          />
                        </div>
                      );
                    })
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteFriendModal;
