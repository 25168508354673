import React from "react";
import CoinIcon from "assets/images/coin-icon.png";
import CoinStarIcon from "assets/images/coin_star.png";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount, removeTrailingZeroes } from "utils";

const buyNow = ({ title, price, type, handleData, onBuyHandle }) => {
  const handleClick = () => {
    handleData("BuyNow");
  };

  let sanitizedPrice = price?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedPrice.split(".").length > 2) {
    sanitizedPrice =
      sanitizedPrice.split(".")[0] + "." + sanitizedPrice.split(".")[1];
  }

  return (
    <>
      <p className="text-white/80 text-[14px] font-bold leading-[17.07px]">
        Price:
      </p>
      <div className="mt-3 mb-3 text-[20px] leading-[30px] font-bold">
        <img
          src={CoinStarIcon}
          className="inline h-[38px] w-[38px] object-cover rounded-full"
          alt="coin_icon"
        />
        <span className="text-[#FBBC5E] pl-2">{sanitizedPrice} USD | </span>
        <span className="text-[#FFDF6E]">
          {sanitizedPrice &&
            removeTrailingZeroes(convertUsdToFstrPrice(sanitizedPrice))}{" "}
          FSTR{" "}
          <span className="text-[16px] leading-[24px]">
            (={" "}
            {sanitizedPrice &&
              removeTrailingZeroes(
                convertUsdToMarketFstrUsd(sanitizedPrice)
              )}{" "}
            USD)
          </span>
        </span>
      </div>
      {/* {title && (
        <h6
          className="mt-4 text-[14px] text-[#FFFFFF66] font-body font-semibold"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h6>
      )} */}
      {price && type === "product" && (
        <div className="my-4 flex gap-2 items-center">
          <img alt="coin" src={CoinIcon} className="w-auto h-auto" />
          <span className="font-extrabold">{price}</span>
        </div>
      )}
      <div className="mt-7 mb-3 bg-[#473e30] p-4 rounded-[8px]">
        <p className="text-[#FFCE1F] font-bold">
          Purchase in FSTR to receive a DISCOUNT of {getFstrDiscount() || "20"}%
        </p>
        <p className="mt-2">
          Select FSTR at checkout to make your purchase and unlock a special
          discount.{" "}
        </p>
      </div>
      <button
        type="button"
        className="font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-full text-black py-3 px-5 mt-5"
        onClick={onBuyHandle}
      >
        {type === "content" ? "Proceed to Checkout" : "Buy Now"}
      </button>
      <button
        type="button"
        className="font-bold text-sm md:text-base rounded-lg border-[1.5px] border-sec justify-center w-full py-2.5 px-5 flex gap-2 mt-5 items-center btn-outline hover:text-dark"
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M22.322 7.931C22.23 7.79808 22.1072 7.68943 21.964 7.61436C21.8209 7.5393 21.6616 7.50006 21.5 7.5H7.833L6.679 4.73C6.52769 4.36523 6.27147 4.05359 5.94282 3.83462C5.61418 3.61564 5.22791 3.49918 4.833 3.5H2.5V5.5H4.833L9.577 16.885C9.65299 17.0672 9.78118 17.2228 9.94542 17.3322C10.1097 17.4416 10.3026 17.5 10.5 17.5H18.5C18.917 17.5 19.29 17.241 19.437 16.852L22.437 8.852C22.4937 8.70063 22.5129 8.53776 22.4928 8.37735C22.4728 8.21695 22.4142 8.06379 22.322 7.931Z"
            fill="currentColor"
          />
          <path
            d="M11 21.5C11.8284 21.5 12.5 20.8284 12.5 20C12.5 19.1716 11.8284 18.5 11 18.5C10.1716 18.5 9.5 19.1716 9.5 20C9.5 20.8284 10.1716 21.5 11 21.5Z"
            fill="currentColor"
          />
          <path
            d="M18 21.5C18.8284 21.5 19.5 20.8284 19.5 20C19.5 19.1716 18.8284 18.5 18 18.5C17.1716 18.5 16.5 19.1716 16.5 20C16.5 20.8284 17.1716 21.5 18 21.5Z"
            fill="currentColor"
          />
        </svg>
        Add to Cart
      </button>
    </>
  );
};

export default buyNow;
