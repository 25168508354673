import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Entertainment-Data-Fetching-Api-Calls
 *@developer Shubham K
 */

export const EntertainmentSlice = createApi({
  reducerPath: "Entertainment",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVolumetricVideos: builder.query({
      query: ({ channelId = "", searchQuery = "", page=1,limit=1000  }) => ({
        url:
          EndpointsSlug.GET_VOL_VIDEOS +
          "?channelHandle=" +
          channelId +
          "&search=" +
          searchQuery +
          "&page=" +
          page +
          "&limit="+
          limit,
        method: "GET",
      }),
    }),
    getSingleVolumetricVideo: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_VOL_VIDEO + `/${id}`,
        method: "GET",
      }),
    }),
    get180Videos: builder.query({
      query: ({ channelId = "", searchQuery = "", page=1,limit=1000 }) => ({
        url:
          EndpointsSlug.GET_180_VIDEOS +
          "?channelHandle=" +
          channelId +
          "&search=" +
          searchQuery +
          "&page=" +
          page +
          "&limit="+
          limit,
        method: "GET",
      }),
    }),
    getSingle180Video: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_180_VIDEO + `/${id}`,
        method: "GET",
      }),
    }),
    get360Videos: builder.query({
      query: ({ channelId = "", searchQuery = "", page=1,limit=1000  }) => ({
        url:
          EndpointsSlug.GET_360_VIDEOS +
          "?channelHandle=" +
          channelId +
          "&search=" +
          searchQuery +
          "&page=" +
          page +
          "&limit="+
          limit,
        method: "GET",
      }),
    }),
    getSingle360Video: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_360_VIDEO + `/${id}`,
        method: "GET",
      }),
    }),
    get2DVideos: builder.query({
      query: ({ channelId = "", searchQuery = "", page=1,limit=1000  }) => ({
        url:
          EndpointsSlug.GET_2D_VIDEOS +
          "?channelHandle=" +
          channelId +
          "&search=" +
          searchQuery +
          "&page=" +
          page +
          "&limit="+
          limit,
        method: "GET",
      }),
    }),
    getSingle2DVideo: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_2D_VIDEO + `/${id}`,
        method: "GET",
      }),
    }),
    getMusicVideos: builder.query({
      query: ({ channelId = "", searchQuery = "", page=1,limit=1000  }) => ({
        url:
          EndpointsSlug.GET_MUSIC_VIDEOS +
          "?channelHandle=" +
          channelId +
          "&search=" +
          searchQuery +
          "&page=" +
          page +
          "&limit="+
          limit,
        method: "GET",
      }),
    }),
    getSingleMusicVideo: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_MUSIC_VIDEO + `/${id}`,
        method: "GET",
      }),
    }),
    getAllChannelsList: builder.query({
      query: ({ searchQuery = "" }) => ({
        url: EndpointsSlug.GET_ALL_CHANNELLIST + "?searchQuery=" + searchQuery,
        method: "GET",
      }),
    }),
    getSingleChannel: builder.query({
      query: (id) => ({
        url: EndpointsSlug.GET_SINGLE_CHANNEL + `/${id}`,
        method: "GET",
      }),
    }),
    getPremiumVideos: builder.query({
      query: (channelId = "") => ({
        url: EndpointsSlug.GET_PREMIUM_VIDEOS + "?channelId=" + channelId,
        method: "GET",
      }),
    }),
    getPremiumChannels: builder.query({
      query: (page, limit) => ({
        url: EndpointsSlug.GET_PREMIUM_CHANNELS,
        method: "GET",
      }),
    }),
    getRecommendedContent: builder.query({
      query: (data, category) => ({
        url:
          EndpointsSlug.GET_RECOMMENDED_CONTENT +
          "?userId=" +
          data.userId +
          "&contentId=" +
          data.contentId +
          "&category=" +
          data.category,
        method: "GET",
      }),
    }),
    getRecommendedChannels: builder.query({
      query: (userId = "", channelId) => ({
        url:
          EndpointsSlug.GET_RECOMMENDED_CHANNELS +
          "?userId=" +
          userId +
          "&channelId=" +
          channelId,
        method: "GET",
      }),
    }),
    getMostTrendingContent: builder.query({
      query: (contentType) => ({
        url:
          EndpointsSlug.GET_MOST_TRENDING_CONTENT +
          "?contentType=" +
          contentType,
        method: "GET",
      }),
    }),
    getTrendingChannels: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.POST_TRENDING_CHANNELS,
        method: "POST",
        body: payload,
      }),
    }),
    getAllChannelContent: builder.query({
      query: ({ searchQuery = "" }) => ({
        url: EndpointsSlug.GET_ALL_CHANNEL_CONTENT + "?search=" + searchQuery,
        method: "GET",
      }),
    }),
    getChannelContentAsset: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_CHANNEL_CONTENT_ASSET,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetVolumetricVideosQuery,
  useGetSingleVolumetricVideoQuery,
  useGet360VideosQuery,
  useGetSingle360VideoQuery,
  useGet180VideosQuery,
  useGetSingle180VideoQuery,
  useGet2DVideosQuery,
  useGetSingle2DVideoQuery,
  useGetMusicVideosQuery,
  useGetSingleMusicVideoQuery,
  useGetAllChannelsListQuery,
  useGetSingleChannelQuery,
  useGetPremiumVideosQuery,
  useGetPremiumChannelsQuery,
  useGetRecommendedChannelsQuery,
  useGetRecommendedContentQuery,
  useGetMostTrendingContentQuery,
  useGetTrendingChannelsMutation,
  useGetAllChannelContentQuery,
  useGetChannelContentAssetQuery,
} = EntertainmentSlice;
