import React, { useEffect, useState } from "react";
import CoinIcon from "assets/images/coin-icon.png";
import CoinStarIcon from "assets/images/coin_star.png";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount, removeTrailingZeroes } from "utils";

const BuyNowTheme = ({
  title,
  price,
  type,
  handleData,
  onBuyHandle,
  selectedThemeIndex = 0,
  onThemeChange,
  themes,
  star,
  isAddedToCart = false,
  isOwned = false,
}) => {
  //   themes = ["Classic Theme", "Theme 1", "Theme 2"];
  const [showDropDown, setShowDropDown] = useState(false);

  const onClickDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown((prev) => !prev);
  };

  const handleClick = () => {
    handleData("BuyNow");
  };

  // to close dropdown on outside click
  useEffect(() => {
    const hideDropDown = () => {
      console.log("called");
      setShowDropDown(false);
    };

    document.addEventListener("click", hideDropDown);

    return () => document.removeEventListener("click", hideDropDown);
  }, []);

  let sanitizedPrice = price?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedPrice.split(".").length > 2) {
    sanitizedPrice =
      sanitizedPrice.split(".")[0] + "." + sanitizedPrice.split(".")[1];
  }

  return (
    <>
      {title && (
        <h6
          className="text-[20px] text-white leading-[24.38px] font-body font-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h6>
      )}

      <div className="w-full">
        <p className="mt-6 text-[14px] text-white/80 leading-[17.07px] font-body font-bold">
          Theme selected:
        </p>
        <div
          className="mt-2 flex justify-between items-center px-2 py-2 border border-white/50 rounded-[8px] cursor-pointer relative"
          onClick={onClickDropDown}
        >
          <p className="text-[18px] leading-[23.85px] font-medium text-[#fbbc5e]">
            {themes[selectedThemeIndex]}
          </p>
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.50178 4.16585H17.5018C17.6536 4.16632 17.8025 4.20821 17.9323 4.28699C18.0622 4.36578 18.1681 4.47848 18.2386 4.61297C18.3092 4.74746 18.3417 4.89865 18.3327 5.05025C18.3237 5.20185 18.2736 5.34813 18.1876 5.47335L10.6876 16.3067C10.3768 16.7558 9.62845 16.7558 9.31678 16.3067L1.81678 5.47335C1.72997 5.34839 1.67906 5.20204 1.66958 5.05018C1.66011 4.89833 1.69243 4.74678 1.76304 4.61201C1.83365 4.47723 1.93984 4.36438 2.07008 4.28573C2.20032 4.20707 2.34963 4.16561 2.50178 4.16585Z"
                fill="white"
              />
            </svg>
          </div>
          {showDropDown && (
            <div className="__DROPDOWN_OPTIONS__ absolute w-full top-[104%] left-0 bg-[#2b2724] rounded-[8px]">
              {themes.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="py-2 px-2 text-[18px] leading-[23.85px] font-medium text-white/80 hover:text-[#fbbc5e]"
                    onClick={() => onThemeChange(index)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {isOwned && (
        <button
          type="button"
          className="font-bold rounded-lg  bg-[#FBBC5E0D]  bg-opacity-5 justify-center w-full py-4 px-5 flex gap-2 mt-8 items-center  text-[#25B39E] cursor-default"
        >
          <svg
            className="stroke-[#25B39E]"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M21.5 6.99984L9.5 18.9998L4 13.4998L5.41 12.0898L9.5 16.1698L20.09 5.58984L21.5 6.99984Z"
              fill="currentColor"
            />
          </svg>
          This content is already added to your assets.
        </button>
      )}

      {isAddedToCart && !isOwned && (
        <>
          <button
            type="button"
            className="font-bold rounded-lg  bg-[#FBBC5E0D]  bg-opacity-5 justify-center w-full py-4 px-5 flex gap-2 mt-8 items-center  text-[#25B39E] cursor-default"
          >
            <svg
              className="stroke-[#25B39E]"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M21.5 6.99984L9.5 18.9998L4 13.4998L5.41 12.0898L9.5 16.1698L20.09 5.58984L21.5 6.99984Z"
                fill="currentColor"
              />
            </svg>
            This theme is already added to your cart.
          </button>
          <button
            type="button"
            className="font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-full text-black py-3 px-5 mt-3"
            onClick={onBuyHandle}
            disabled
          >
            Purchase now
          </button>
        </>
      )}

      {!isAddedToCart && !isOwned && (
        <>
          {" "}
          <p className="mt-8 text-white/80 text-[14px] font-bold leading-[17.07px]">
            Price:
          </p>
          <div className="mt-3 mb-3 text-[20px] leading-[30px] font-bold">
            <img
              src={CoinStarIcon}
              className="inline h-[38px] w-[38px] object-cover rounded-full"
              alt="coin_icon"
            />
            <span className="text-[#FBBC5E] pl-2">{sanitizedPrice} USD | </span>
            <span className="text-[#FFDF6E]">
              {sanitizedPrice &&
                removeTrailingZeroes(
                  convertUsdToFstrPrice(sanitizedPrice)
                )}{" "}
              FSTR{" "}
              <span className="text-[16px] leading-[24px]">
                (={" "}
                {sanitizedPrice &&
                  removeTrailingZeroes(
                    convertUsdToMarketFstrUsd(sanitizedPrice)
                  )}{" "}
                USD)
              </span>
            </span>
          </div>
          <div className="mt-5 mb-4 bg-[#4d4232] p-4 rounded-[8px]">
            <p className="text-[#FFCE1F] font-bold">
              Purchase in FSTR to receive a DISCOUNT of{" "}
              {getFstrDiscount() || "20"}%
            </p>
            <p className="mt-2">
              Select FSTR at checkout to make your purchase and unlock a special
              discount.{" "}
            </p>
          </div>
          <button
            type="button"
            className="font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-full text-black py-3 px-5 mt-3"
            onClick={onBuyHandle}
          >
            Proceed to Checkout
          </button>
          <button
            type="button"
            className="font-bold text-sm md:text-base rounded-lg border-[1.5px] border-sec justify-center w-full py-2.5 px-5 flex gap-2 mt-5 items-center btn-outline hover:text-dark"
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M22.322 7.931C22.23 7.79808 22.1072 7.68943 21.964 7.61436C21.8209 7.5393 21.6616 7.50006 21.5 7.5H7.833L6.679 4.73C6.52769 4.36523 6.27147 4.05359 5.94282 3.83462C5.61418 3.61564 5.22791 3.49918 4.833 3.5H2.5V5.5H4.833L9.577 16.885C9.65299 17.0672 9.78118 17.2228 9.94542 17.3322C10.1097 17.4416 10.3026 17.5 10.5 17.5H18.5C18.917 17.5 19.29 17.241 19.437 16.852L22.437 8.852C22.4937 8.70063 22.5129 8.53776 22.4928 8.37735C22.4728 8.21695 22.4142 8.06379 22.322 7.931Z"
                fill="currentColor"
              />
              <path
                d="M11 21.5C11.8284 21.5 12.5 20.8284 12.5 20C12.5 19.1716 11.8284 18.5 11 18.5C10.1716 18.5 9.5 19.1716 9.5 20C9.5 20.8284 10.1716 21.5 11 21.5Z"
                fill="currentColor"
              />
              <path
                d="M18 21.5C18.8284 21.5 19.5 20.8284 19.5 20C19.5 19.1716 18.8284 18.5 18 18.5C17.1716 18.5 16.5 19.1716 16.5 20C16.5 20.8284 17.1716 21.5 18 21.5Z"
                fill="currentColor"
              />
            </svg>
            Add to Cart
          </button>
        </>
      )}
    </>
  );
};

export default BuyNowTheme;
