import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, EndpointsSlug } from 'constant/api/Endpoints'

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-Assets-Reducer-functions
 *@developer Shubham K
 */


// Handling State function or logics to check and add user assets status
// Replace the CreateSlice method with RTK function - createAPI for storing api data directly into slicer
export const UserAssets = createApi({
    reducerPath: 'UserAssets',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken') || "";
            headers.set('Content-type', 'application/json; charset=UTF-8')
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
                
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
        PostCheckUserAssets: builder.mutation({
            query: (payload) => ({
                url: BASE_URL + EndpointsSlug.POST_CHECK_USER_ASSETS_INFO,
                method: 'POST',
                body: payload,               
            })
        }),
        PostAddFreeAssetForUser: builder.mutation({
            query: (payload) => ({
                url: BASE_URL + EndpointsSlug.POST_CHECK_USER_ADD_FREE_ASSET,
                method: 'POST',
                body: payload,               
            })
        }),
        CheckUserSubscriptions: builder.mutation({
            query: (payload) => ({
                url: BASE_URL + EndpointsSlug.CHECK_USER_SUBSCRIPTION,
                method: 'POST',
                body: payload,               
            })
        }),
    })
})


export const {
    usePostCheckUserAssetsMutation,
    usePostAddFreeAssetForUserMutation,
    useCheckUserSubscriptionsMutation
} = UserAssets