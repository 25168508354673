/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Grid, Typography } from "@mui/material";
// import { Fade, Zoom } from "react-reveal";
import ShadowFrame from "components/shadow-frame";
import LandingBannerImg from "assets/images/Landing-page-hero-section-image.jpg";
// import Landing_1 from "assets/images/home_2.png";
// import Landing_2 from "assets/images/Frame 31.jpg";
// import Landing_3 from "assets/images/Frame 55.png";
// import Landing_4 from "assets/images/home_5.png";
import imageMobile from "assets/images/mobile-bg-image.png";
import Loader from "components/Loader/Loader";
import heroImage2 from "assets/images/home/hero-image-2.png";
import tokenInfographic from "assets/images/home/token-infographic.png";
import infographic from "assets/images/home/infographic.png";
import vrSection from "assets/images/home/vr-section.png";
import roadmap from "assets/images/home/roadmap.png";
import team1 from "assets/images/home/team-1.png";
import team2 from "assets/images/home/team-2.png";
import team3 from "assets/images/home/team-3.png";
import advisor1 from "assets/images/home/advisor1.png";
import advisor2 from "assets/images/home/advisor2.png";
import advisor3 from "assets/images/home/advisor3.png";
import advisor4 from "assets/images/home/advisor4.png";
import list1 from "assets/images/home/list-1.png";
import list2 from "assets/images/home/list-2.png";
import list3 from "assets/images/home/list-3.png";
import slider1 from "assets/images/home/slider-1.png";
import slider2 from "assets/images/home/slider-2.png";
import slider3 from "assets/images/home/slider-3.png";
import slider4 from "assets/images/home/slider-4.png";
import slider5 from "assets/images/home/slider-5.png";
import slider6 from "assets/images/home/slider-6.png";
import slider7 from "assets/images/home/slider-7.png";
import slider8 from "assets/images/home/slider-8.png";
import slider9 from "assets/images/home/slider-9.png";
import slider10 from "assets/images/home/slider-10.png";
import slider11 from "assets/images/home/slider-11.png";
import slider12 from "assets/images/home/slider-12.png";
import slider13 from "assets/images/home/slider-13.png";
import slider14 from "assets/images/home/slider-14.png";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
// import Glider from "react-glider";
// import "glider-js/glider.min.css";
// import "./styles.css";

/* The above code is a React component called "Index". It renders a grid layout with multiple sections,
each containing an image and some text. The component uses various React and Material-UI components
and styles to create a visually appealing and responsive layout. The purpose of the code is to
showcase different features and aspects of the Fourth Star platform, such as the experience
marketplace, collectible assets, ultimate gaming, and connecting with friends in the metaverse. */

const Index = () => {
  const [bannerImageLoaded, setBannerImageLoaded] = useState(false);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const heroImageRef = useRef(null);
  const gliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isWhiteHovered, setIsWhiteHOvered] = useState(false);
  const [isDiamondHovered, setIsDiamondHovered] = useState(false);
  const { userInfo, userToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    infinite: false,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    afterChange: (index) => {
      setSlideIndex(index);
    },
  };

  const onBannerImageLoad = (ev) => {
    setBannerImageLoaded(true);
  };
  const onHeroImageLoad = (ev) => {
    setHeroImageLoaded(true);
  };

  const openWhitePaperPdf = async () => {
    const s3Url = `${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/whitepaper.pdf`;

    fetch(s3Url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "whitepaper.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) =>
        console.error("Error downloading whitepaper PDF:", error)
      );
  };

  const openBusinessPdf = async () => {
    const s3Url = `${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/business_presentation.pdf`;

    fetch(s3Url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "business_presentation.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) =>
        console.error("Error downloading business presentation PDF:", error)
      );
  };

  const openDiamondPdf = async () => {
    const s3Url = `${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/diamond_pledge.pdf`;

    fetch(s3Url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "diamond_pledge.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) =>
        console.error("Error downloading download pledge PDF:", error)
      );
  };

  useEffect(() => {
    const heroImages = heroImageRef.current;

    Array.from(heroImages).forEach((image) => {
      if (image.complete) {
        image.style.height = "100%";
        image.previousElementSibling.style.display = "none";
      }
      image.onload = (e) => {
        image.style.height = "100%";
        image.previousElementSibling.style.display = "none";
      };
    });
  }, []);

  return (
    <>
      <Box className="container-fluid w-[100%]">
        <Box className="bg-gradient-to-b w-[100%] from-black via-transparent to-[#1F1E21] relative z-10">
          {!bannerImageLoaded && (
            <div className="relative h-[calc(100vh-50px)] md:h-[450px] lg:h-[550px] xl:h-[700px] 2xl:h-[calc(100vh-50px)] grid place-content-center">
              <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
              <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
              <Loader />
            </div>
          )}
          <picture className="" onLoad={onBannerImageLoad}>
            <source
              media="(max-width: 799px)"
              srcSet={imageMobile}
              style={{
                height: bannerImageLoaded ? "auto" : "0px",
              }}
            />
            <source
              media="(min-width: 800px)"
              srcSet={LandingBannerImg}
              style={{
                height: bannerImageLoaded ? "auto" : "0px",
              }}
            />
            <img
              src={LandingBannerImg}
              alt="shadowcast"
              className="cursor-pointer w-[100%] h-auto"
              style={{
                height: bannerImageLoaded ? "auto" : "0px",
              }}
            />
          </picture>
          <div className="shadow-bottom" />
        </Box>

        {/* <Box className="h-100 flex max-[768px]:flex-col-reverse justify-between pl-16 pr-16 pt-16 pb-12 sm:pl-48 sm:pr-32 sm:pt-40 sm:pb-32 relative ">
          <Box className="flex-1 relative lg:pr-32">
            <div className="landingPage-rightShadow"></div>{" "}
            <div className="flex h-full">
              <Fade duration={3000}>
                <img
                  src={Landing_1}
                  className="cursor-pointer w-[100%] h-auto relative rounded-[20px]"
                  alt="Network problem"
                />
              </Fade>
            </div>
          </Box>
        </Box>

        <Box className="h-100 flex max-[768px]:flex-col justify-between pl-16 pr-16 pt-16 pb-12 sm:pl-48 sm:pr-32 sm:pt-40 sm:pb-32 ">
          <Box className="flex-1 relative lg:pl-16">
            <Box className="landingPage-leftShadow"></Box>

            <div className="flex h-full">
              <Fade duration={3000}>
                <img
                  src={Landing_2}
                  className="cursor-pointer w-[100%] h-auto relative rounded-[20px]"
                  alt="Network problem"
                />
              </Fade>
            </div>
          </Box>
        </Box>

        <Box className="h-100  flex max-[768px]:flex-col max-[768px]:mb-[50px] justify-between pl-16 pr-16 pt-16 pb-12 sm:pl-48 sm:pr-32 sm:pt-40 sm:pb-32 ">
          <Box className="flex-1 relative lg:pl-16">
            <Box className="landingPage-leftShadow"></Box>
            <div className="flex  h-full ">
              <Fade duration={3000}>
                <img
                  src={Landing_4}
                  className="cursor-pointer w-[100%] h-auto relative rounded-[20px]"
                  alt="Network problem"
                />
              </Fade>
            </div>
          </Box>
        </Box> */}

        <div className="hero-text-container">
          <h1 className="hero-text">Welcome to Fourth Star</h1>
          <p className="hero-text-desc">
            Discover and create your own Virtual Reality within the Fourth Star
            Entertainment and Gaming Metaverse, the new VR platform for
            integrated Immersive content and Gaming. Watch the latest Science
            Fiction Movie in full 360 from the Entertainment Suite, aboard your
            own ship and then hyperdrive to a nearby planet. Embark on an
            interactive quest, and then invite your friends to take a break and
            select from hours of fully immersive entertainment content from your
            favourite channels.
          </p>
        </div>
        <div className="hero-image-container">
          {!heroImageLoaded && (
            <div className="relative h-[calc(100vh-50px)] md:h-[450px] lg:h-[550px] xl:h-[700px] 2xl:h-[calc(100vh-50px)] grid place-content-center">
              <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
              <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
              <Loader />
            </div>
          )}
          <picture className="" onLoad={onHeroImageLoad}>
            <img ref={heroImageRef} src={heroImage2} alt="" />
          </picture>

          <div className="shadow-frame hero-shadow"></div>
        </div>
        <div className="hero-text-container">
          <h1 className="hero-text">Discover the Limitless</h1>
          <p className="hero-text-desc" sx="margin-bottom: 0px">
            Teleport aboard the Genesis, the Entertainment and Gaming Mothership
            that transports you to the Fourth Star universe. Explore over thirty
            environments, meet friends in the Café, or spend the evening in your
            own Ship or Apartment, and select your favourite immersive content
            from the fully integrated Entertainment Suite. Fly your Ship from
            the Genesis to an undiscovered planet and don’t forget to dress to
            impress with your new Flight Suit.
          </p>
        </div>
        <div className="carousel-container">
          <div className="glider-container">
            <div className="glider">
              <Slider ref={gliderRef} {...settings}>
                <div className="glider-item">
                  <img src={slider1} alt="slider-img" />
                  <p target="">Exquisite Elegance: Luxury Apartment Living</p>
                </div>
                <div className="glider-item">
                  <img src={slider2} alt="slider-img" />
                  <p target="">Ships with Integrated Entertainment Suites</p>
                </div>
                <div className="glider-item">
                  <img src={slider3} alt="slider-img" />
                  <p target="">Modern and Stylish Interior</p>
                </div>
                <div className="glider-item">
                  <img src={slider4} alt="slider-img" />
                  <p target="">Game On: Virtual Adventures Await!</p>
                </div>
                <div className="glider-item">
                  <img src={slider5} alt="slider-img" />
                  <p target="">AI Companions: Your Virtual Allies</p>
                </div>
                <div className="glider-item">
                  <img src={slider6} alt="slider-img" />
                  <p target="">
                    Immersive Content: Dive into Unforgettable Events
                  </p>
                </div>
                <div className="glider-item">
                  <img src={slider7} alt="slider-img" />
                  <p target="">Invite Friends, Share and Enjoy Together</p>
                </div>
                <div className="glider-item">
                  <img src={slider8} alt="slider-img" />
                  <p target="">
                    Discover Your Unique Style in a Diverse Collection
                  </p>
                </div>
                <div className="glider-item">
                  <img src={slider9} alt="slider-img" />
                  <p target="">Cozy Luxury: Relax in Stylish Comfort</p>
                </div>
                <div className="glider-item">
                  <img src={slider10} alt="slider-img" />
                  <p target="">Virtual Gatherings: Meet Anywhere, Anytime</p>
                </div>
                <div className="glider-item">
                  <img src={slider11} alt="slider-img" />
                  <p target="">Share Immersive Content With Your Friends</p>
                </div>
                <div className="glider-item">
                  <img src={slider12} alt="slider-img" />
                  <p target="">Modern Aesthetic Workplace</p>
                </div>
                <div className="glider-item">
                  <img src={slider13} alt="slider-img" />
                  <p target="">
                    Preview Your Ship: Experience it Live in the Metaverse
                  </p>
                </div>
                <div className="glider-item">
                  <img src={slider14} alt="slider-img" />
                  <p target="">Discover Virtual Hangouts</p>
                </div>
              </Slider>
            </div>

            <button
              aria-label="Previous"
              // ref={leftRef}
              className="glider-prev"
              disabled={slideIndex === 0}
              onClick={() => gliderRef?.current?.slickPrev()}
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8C0 8.39752 0.207253 8.79503 0.414507 8.99379L7.25389 15.5528C7.87565 16.1491 8.91192 16.1491 9.53368 15.5528C10.1554 14.9565 10.1554 13.9627 9.53368 13.3665L3.93782 8L9.53368 2.63354C10.1554 2.03727 10.1554 1.04348 9.53368 0.447206C8.91192 -0.149067 7.87565 -0.149067 7.25389 0.447206L0.621762 6.80745C0.207254 7.20497 0 7.60249 0 8Z"
                  fill={slideIndex === 0 ? "rgb(105, 102, 102)" : "white"}
                />
              </svg>
            </button>
            <button
              aria-label="Next"
              // ref={rightRef}
              className="glider-next"
              disabled={slideIndex === 11}
              onClick={() => gliderRef?.current?.slickNext()}
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 8C10 8.39752 9.79275 8.79503 9.58549 8.99379L2.74611 15.5528C2.12435 16.1491 1.08808 16.1491 0.466321 15.5528C-0.15544 14.9565 -0.15544 13.9627 0.466321 13.3665L6.06218 8L0.466321 2.63354C-0.15544 2.03727 -0.15544 1.04348 0.466321 0.447206C1.08808 -0.149067 2.12435 -0.149067 2.74611 0.447206L9.37824 6.80745C9.79275 7.20497 10 7.60249 10 8Z"
                  fill={slideIndex === 11 ? "rgb(105, 102, 102)" : "white"}
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="list-container">
          <div className="list-item">
            <div className="list-image-container">
              <img src={list1} alt="" />
            </div>
            <div className="list-text-container">
              <h2>VR Metaverse</h2>
              <p className="list-item-desc">
                Fourth Star is an Immersive Metaverse platform for Gaming,
                Streaming, and Entertainment. Explore interactive environments,
                join quests, invite friends, and access exclusive content. A
                rich virtual world awaits you!
              </p>
            </div>
            <div className="shadow-frame list-shadow"></div>
          </div>
          <div className="list-item">
            <div className="list-image-container">
              <img src={list2} alt="" />
            </div>
            <div className="list-text-container">
              <h2>Player Portal</h2>
              <p className="list-item-desc">
                Select your own personal Luxury Apartment or Spaceship.
                Customize your avatar or search for your new AI Companion – a
                Robotic Butler, Martial Arts Sensei, or Cyborg Lion. Browse
                content channels and access Games and Virtual Concerts, or just
                invite your friends to join you for a Planet Flyover and then
                relax with a 360-horror movie.
              </p>
            </div>
            <div className="shadow-frame list-shadow"></div>
          </div>
          <div className="list-item">
            <div className="list-image-container">
              <img src={list3} alt="" />
            </div>
            <div className="list-text-container">
              <h2>Creator Portal</h2>
              <p className="list-item-desc">
                Calling all Immersive content creators to join our community and
                unleash your creativity. Stream and monetize your short and
                long-form content in 180, 360, and volumetric video. Create your
                own channel and showcase your content in the event screening
                room, or stream your fully immersive movie directly to the
                Apartments and Ships. 
              </p>
            </div>
            <div className="shadow-frame list-shadow"></div>
          </div>
        </div>

        <div className="unlock-text-container">
          <h1 className="hero-text">FSTR</h1>
          <p className="hero-text-desc">
            The Fourth Star Token is the financial bedrock of the Fourth Star
            Metaverse, the ultimate Utility Token that unlocks discounts on
            purchases for Apartments, Ships, Companions, and Contents. Token
            holders enjoy special ranking and privileges, including VIP invites,
            Red Carpet and Early Screenings . Please see the example purchase
            below for the ALPHA LEO AI Battle Bot. The price listed is for
            illustrative purposes only, and the discount is calculated based on
            the FSTR Public Sale Price of $0.51.
          </p>
          <p className="fstr-text">
            FSTR UTILITY TOKEN - ACCESS HUGE DISCOUNTS
          </p>
          <a href="https://buy.fourthstar.com/" class="btn">
            {" "}
            FSTR KYC{" "}
          </a>
        </div>

        <div className="token-info-container">
          <img src={tokenInfographic} alt="" />
        </div>

        <div className="unlock-text-container">
          <h1 className="hero-text">FSTR Discounts & the Market</h1>
          <p className="hero-text-desc">
            FSTR will “pre-exchange” public launch at $0.51 and will always be
            worth a minimum of $1 on the platform. As an example, when
            purchasing FSTR at the public launch price of $0.51 and purchasing
            an item on the platform, each FSTR will be worth a minimum of $1,
            ensuring a discount of 80% on purchases. When FSTR has a market rate
            of $1 or more, a standard discount of 25% will be applied. Please
            see the following as examples of discounts calculated for varying
            FSTR market price rates. Examples are for illustrative purposes only
            and are not intended as a prediction of market prices.
          </p>
          <p className="fstr-text">
            ALWAYS WORTH A MINIMUM OF A $1 AND ONGOING DISCOUNT OF 25%
          </p>
          <a href="https://buy.fourthstar.com/" class="btn">
            {" "}
            FSTR KYC{" "}
          </a>
        </div>

        <div className="layout-info-container">
          <img src={infographic} alt="" />
        </div>

        <div className="relative mt-[100px]">
          <img src={vrSection} alt="" />
          <NavLink
            to={userInfo && userToken ? "/sidequest" : "/signin"}
            className="flex gap-x-[10px] bg-[#fbbc5e] hover:bg-[#db9149] rounded-[8px] text-[12px] md:text-[16px] font-bold px-[5px] md:py-[12px] md:px-[24px] text-black absolute top-[60%] left-[15%] md:top-[60%] md:left-[23%] cursor-pointer"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                fill="black"
              />
            </svg>
            Metaverse
          </NavLink>
        </div>

        <div className="unlock-text-container">
          <h1 className="hero-text">Roadmap</h1>
          <p className="hero-text-desc">
            Our journey started with the initial conception, engineering a proof
            of concept, and then completing workshops with leading content
            creators from music and film and taking the vital feedback from our
            500 closed Beta testers. The platform today has been iterated
            through Agile Development by a team of Solutions Architects,
            Designers, Concept Artists, 3D Artists, Machine Learning Engineers,
            Game Engine Engineers, and Full Stack Engineers to today's VR
            Metaverse, User, and Creator Portal.
          </p>
          <p className="fstr-text">
            BECOME AN FSTR TOKEN HOLDER AND ENJOY EARLY ACCESS
          </p>
        </div>

        <div className="token-info-container">
          <img src={roadmap} alt="" />
        </div>

        <div className="token-text-container">
          <h1 className="hero-text">
            Business Presentation & Token White Paper{" "}
          </h1>
          <p className="hero-text-desc">
            Discover the vision, technology, roadmap, and tokenomics that drive
            our platform.
          </p>
          <div className="flex max-[768px]:flex-col max-[768px]:gap-y-[20px] gap-x-[20px]">
            <button
              className="download-btn"
              onClick={openWhitePaperPdf}
              onMouseEnter={() => setIsWhiteHOvered(true)}
              onMouseLeave={() => setIsWhiteHOvered(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                  fill={isWhiteHovered ? "black" : "#FBBC5E"}
                />
              </svg>
              White Paper
            </button>
            <button
              className="download-btn"
              onClick={openBusinessPdf}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                  fill={isHovered ? "black" : "#FBBC5E"}
                />
              </svg>
              Business Presentation
            </button>
            <button
              className="download-btn"
              onClick={openDiamondPdf}
              onMouseEnter={() => setIsDiamondHovered(true)}
              onMouseLeave={() => setIsDiamondHovered(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                  fill={isDiamondHovered ? "black" : "#FBBC5E"}
                />
              </svg>
              Diamond Pledge
            </button>
          </div>

          <a href="https://buy.fourthstar.com/" class="btn">
            {" "}
            FSTR KYC{" "}
          </a>
          <div className="shadow-frame token-text-shadow"></div>
        </div>

        <div className="team-container mobile-team">
          <div className="shadow-frame team-shadow-1"></div>
          <div className="shadow-frame team-shadow-2"></div>
          <h2 className="hero-text">Executive Team</h2>
          <div className="team-members-container">
            <div className="team-member executive1">
              <div className="member-img-container">
                <img src={team1} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Greg Simon</h3>
                <div className="team-executive-link">
                  <p className="member-role">CEO</p>
                  <p className="member-role" sx="margin-top: 14px">
                    <a
                      href="https://www.linkedin.com/in/gregorylsimon?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      className="footer-link footer-icon"
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                          fill="#FBBC5E"
                        />
                      </svg>
                    </a>
                  </p>
                </div>

                <p>
                  15 years experience in international finance. 10 years
                  experience in blockchain/Web3. Serial entrepreneur
                  CEO/Founder. Columbia MBA, Former CPA, holder of multiple
                  blockchain patent grants, fluent in Japanese, avid world
                  traveller.
                </p>
              </div>
            </div>
            <div className="team-member executive1">
              <div className="member-img-container">
                <img src={team2} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Craig Wiltshire</h3>
                <div className="team-executive-link">
                  <p className="member-role">CTO</p>
                  <p className="member-role" sx="margin-top: 14px">
                    <a
                      href="https://www.linkedin.com/in/craig-wiltshire-101a1115?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      className="footer-link footer-icon"
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                          fill="#FBBC5E"
                        />
                      </svg>
                    </a>
                  </p>
                </div>
                <p>
                  Executive MBA, PCG Design. Solutions architect for spatial
                  computing and machine learning. Former IPC Europa Cup downhill
                  skier and Royal Marines commando. Specialising in advanced
                  AR/MR/VR eCommerce.
                </p>
              </div>
            </div>

            <div className="team-member executive1">
              <div className="member-img-container">
                <img src={team3} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Michael Doran</h3>
                <div className="team-executive-link">
                  <p className="member-role">CBO</p>
                  <p className="member-role" sx="margin-top: 14px">
                    <a
                      href="https://www.linkedin.com/in/michael-doran-493a1175/"
                      className="footer-link footer-icon"
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                          fill="#FBBC5E"
                        />
                      </svg>
                    </a>
                  </p>
                </div>
                <p>
                  Michael was previously Director of Security & Director of
                  Engineering for Loyyal Corp, an industry-leading
                  blockchain-technology company. Prior to that he was Team Lead
                  in Engineering, for Apple Inc. His career includes multiple
                  years at other leading firms including AT&T;, Disney, and
                  VoiceBox Technologies.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="team-container mobile-team">
          <div className="shadow-frame team-shadow-1"></div>
          <div className="shadow-frame team-shadow-2"></div>
          <h2 className="hero-text">Advisors</h2>
          <div className="team-members-container">
            <div className="team-member advisor-member">
              <div className="member-img-container">
                <img src={advisor1} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Zhenbin Xu</h3>
                <p className="member-role">Web3 and AI Advisor</p>
                <p className="member-role">
                  <a
                    href="https://www.linkedin.com/in/zhenbinxu?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    className="footer-link footer-icon"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                        fill="#FBBC5E"
                      />
                    </svg>
                  </a>
                </p>
                <p>
                  Zhenbin is a seasoned technology executive with over two
                  decades of product leadership and innovation. He is current
                  the CPO of Pacvue Corporation on AI accelerated eCommerce. He
                  was a former Senior Director at Microsoft. After Microsoft, he
                  served as Chief AI Officer and the top technology executive
                  for an insurance group where he led their digital and AI
                  transformation and FinTech initiatives leveraging AI and Web3.
                  Zhenbin has been advisor for leading VCs and companies on AI +
                  Web3.
                </p>
              </div>
            </div>

            <div className="team-member advisor-member">
              <div className="member-img-container">
                <img src={advisor2} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Thom Kozik</h3>
                <p className="member-role">
                  Incentivization, Behavioral Economics, Gamification Advisor
                </p>
                <p className="member-role">
                  <a
                    href="https://www.linkedin.com/in/thomkozik?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    className="footer-link footer-icon"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                        fill="#FBBC5E"
                      />
                    </svg>
                  </a>
                </p>
                <p>
                  Thom Kozik's career is a testament to his expertise in
                  incentivization, behavioral economics, gamification marketing,
                  and the strategic application of blockchain technology, with
                  demonstrated leadership in various sectors, including the
                  loyalty marketing and video game industries.  He brings
                  decades of success ranging from former President of Marriott
                  Rewards,  Chief Product Officer at Loyyal Corp, Exec VP of
                  Atari, and Sr. Director for Yahoo! Games, in addition to
                  advising the executive management of Verizon, Target, and
                  Allergan on incentivization and loyalty strategies, and the
                  economic modeling needed to support them.
                </p>
              </div>
            </div>
            <div className="team-member advisor-member">
              <div className="member-img-container">
                <img src={advisor3} alt="" />
              </div>
              <div className="member-text-container">
                <h3>Paul Chou</h3>
                <p className="member-role">Regulatory and Exchange Advisor</p>
                <p className="member-role">
                  <a
                    href="https://www.linkedin.com/in/plchou/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    className="footer-link footer-icon"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                        fill="#FBBC5E"
                      />
                    </svg>
                  </a>
                </p>
                <p>
                  Paul is currently the CEO and Co-Founder of Foundation Coin;
                  was CEO and Co-Founder of LedgerX, a New York cryptocurrency
                  derivatives exchange; was appointed as a government advisor to
                  the CFTC's Technology Advisory Committee; has a passion for
                  post-quantum crypto, financial tools for space, and AI
                  implications for both.
                </p>
              </div>
            </div>
            <div className="team-member advisor-member last-advisor">
              <div className="member-img-container">
                <img src={advisor4} alt="" />
              </div>
              <div className="member-text-container">
                <h3>LaurieAnne (LA) Lassek</h3>
                <p className="member-role">Brand Strategy</p>
                <p className="member-role">
                  <a
                    href="https://www.linkedin.com/in/lalassek?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    className="footer-link footer-icon"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8817 2.33317H4.11834C3.89618 2.33009 3.67559 2.37079 3.46916 2.45297C3.26274 2.53514 3.07452 2.65718 2.91527 2.8121C2.75601 2.96702 2.62883 3.1518 2.54099 3.35588C2.45316 3.55996 2.40638 3.77935 2.40334 4.0015V23.9982C2.40638 24.2203 2.45316 24.4397 2.54099 24.6438C2.62883 24.8479 2.75601 25.0326 2.91527 25.1876C3.07452 25.3425 3.26274 25.4645 3.46916 25.5467C3.67559 25.6289 3.89618 25.6696 4.11834 25.6665H23.8817C24.1038 25.6696 24.3244 25.6289 24.5308 25.5467C24.7373 25.4645 24.9255 25.3425 25.0847 25.1876C25.244 25.0326 25.3712 24.8479 25.459 24.6438C25.5469 24.4397 25.5936 24.2203 25.5967 23.9982V4.0015C25.5936 3.77935 25.5469 3.55996 25.459 3.35588C25.3712 3.1518 25.244 2.96702 25.0847 2.8121C24.9255 2.65718 24.7373 2.53514 24.5308 2.45297C24.3244 2.37079 24.1038 2.33009 23.8817 2.33317ZM9.43834 21.8632H5.93834V11.3632H9.43834V21.8632ZM7.68834 9.89317C7.20564 9.89317 6.74272 9.70142 6.4014 9.36011C6.06008 9.01879 5.86834 8.55586 5.86834 8.07317C5.86834 7.59048 6.06008 7.12755 6.4014 6.78624C6.74272 6.44492 7.20564 6.25317 7.68834 6.25317C7.94465 6.2241 8.20421 6.2495 8.45002 6.3277C8.69584 6.4059 8.92236 6.53514 9.11477 6.70696C9.30717 6.87878 9.46112 7.0893 9.56652 7.32473C9.67192 7.56017 9.72641 7.81522 9.72641 8.07317C9.72641 8.33113 9.67192 8.58617 9.56652 8.82161C9.46112 9.05705 9.30717 9.26757 9.11477 9.43938C8.92236 9.6112 8.69584 9.74044 8.45002 9.81864C8.20421 9.89684 7.94465 9.92224 7.68834 9.89317ZM22.0617 21.8632H18.5617V16.2282C18.5617 14.8165 18.06 13.8948 16.7883 13.8948C16.3948 13.8977 16.0116 14.0212 15.6903 14.2485C15.3691 14.4759 15.1252 14.7963 14.9917 15.1665C14.9004 15.4407 14.8608 15.7295 14.875 16.0182V21.8515H11.375V11.3515H14.875V12.8332C15.193 12.2815 15.6554 11.8269 16.2125 11.5186C16.7696 11.2102 17.4003 11.0597 18.0367 11.0832C20.37 11.0832 22.0617 12.5882 22.0617 15.8198V21.8632Z"
                        fill="#FBBC5E"
                      />
                    </svg>
                  </a>
                </p>
                <p>
                  U.S.-based enterprise technology executive and brand
                  strategist, LaurieAnne “L.A.” Lassek, brings 20+ years of
                  global marketing communications experience to the table. She
                  is passionate about connecting the dots, and people,
                  cross-functionally, to drive clear and consistent
                  communications both internally and externally. LA rolls up her
                  sleeves to extract and curate point-of-views with SMEs and
                  leaders across the organization, developing executive
                  platforms that build awareness and champion customer success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Index;
