/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Onboarding-Complete-Page
 *@developer Shubham K
 */

import { useNavigate } from "react-router-dom";
import CheckIcon from "assets/images/check-onboarding.png";

const OnboardingComplete = () => {
  const navigate = useNavigate();

  return (
    <div className="py-20 px-24 rounded-lg bg-[#dfdfdf]/10 backdrop-blur-[2px]">
      <div className="mx-auto h-[58px] w-[58px] rounded-full mb-8">
        <img className="h-full w-full object-cover" src={CheckIcon} alt="" />
      </div>

      <div>
        <h1 className="text-[20px] font-heading text-center mb-8">
          You're All Set!
        </h1>
      </div>
      <p className="text-center text-[18px] leading-[32.35px] font-body mb-8 lg:max-w-[75%] mx-auto">
        Begin your metaverse journey now. Explore, customize and enjoy your
        Virtual world!
      </p>
      <button
        className="mx-auto font-bold rounded-lg btn-gradient text-black py-3 px-10 flex justify-center"
        onClick={() => {
          navigate("/");
        }}
      >
        Let's Begin
      </button>
    </div>
  );
};

export default OnboardingComplete;
