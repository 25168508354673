import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module My-Order-Reducer-functions
 *@developer Amardeep Singh
 */

// Handling State function or logics of my orders Module
// Replace the CreateSlice method with RTK function - createAPI for storing api data directly into slicer
export const MyOrders = createApi({
  reducerPath: "MyOrders",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetMyOrdersList: builder.query({
      query: ({ page, size }) => ({
        url: EndpointsSlug.GET_MY_ORDERS_ASSET,
        method: "GET",
        params: { page, size },
      }),
    }),
    GetMyOrderDetail: builder.query({
      query: ({ orderId }) => ({
        url: EndpointsSlug.GET_MY_ORDER_DETAIL,
        method: "GET",
        params: { orderId },
      }),
    }),
    RequestRefund: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.POST_REQUEST_REFUND,
        method: "POST",
        body: payload,
      }),
    }),
    CheckRefundStatus: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.CHECK_REFUND_STATUS,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMyOrdersListQuery,
  useGetMyOrderDetailQuery,
  useRequestRefundMutation,
  useCheckRefundStatusMutation,
} = MyOrders;
