import React, { useRef, useState } from "react";
import ModalClose from "assets/images/myassets/charm_cross.png";
import UploadIcon from "assets/images/upload__icon.png";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "constant";
import AWS from "aws-sdk";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Loader from "components/Loader/Loader";
import { useUpdateFileNameMutation } from "store/slicer/userProfile";
import { useUpdateUserFilePhotoMutation } from "store/slicer/userProfile";
import ErrorAlert from "utils/errorAlert";

// AWS S3-bucket Setup
const _S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const AWS_REGION = process.env.REACT_APP_AWS_S3_BUCKET_REGION;

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY_ID,
// });

const MyBucketContainer = new AWS.S3({
  params: { Bucket: _S3_BUCKET },
  region: AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY_ID,
});

const ProfilePhotoModal = ({ handlePhotoModal, UpdateProfilePic }) => {
  const [updateFilenameQuery] = useUpdateFileNameMutation();
  const [updateFilePhotoQuery] = useUpdateUserFilePhotoMutation();
  const [IsNextUpload, setNextUpload] = useState(false);
  const [ProfileViewImage, setProfileViewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const Inputref = useRef();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [progressData, setprogressData] = useState(0);
  const [s3ImageURL, setS3ImageURL] = useState("");
  const [isProgress, setProgress] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [serverFileNameMeta, setServerFileNameMeta] = useState({});

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async (filename) => {
    try {
      const croppedImage = await getCroppedImg(
        ProfileViewImage,
        croppedAreaPixels,
        rotation
      );
      const response = await fetch(croppedImage);
      // here image is url/location of image
      const blob = await response.blob();
      const fileImg = new File([blob], filename, { type: blob.type });
      console.log("fileImg", fileImg);
      // const dataURL = ReadImageFile()
      // console.log(croppedImage)
      // const getImage = await blobToImage(new Blob([croppedImage]))
      // console.log(getImage)
      // const blob = new Blob([croppedImage]);
      const file = new File([croppedImage], selectedFile);
      console.log(croppedImage);
      return fileImg;
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenFileSystem = () => {
    Inputref.current.click();
  };

  const handleCancelProfileViewer = () => {
    setProfileViewImage(null);
    setProgress(true);
    setNextUpload(!IsNextUpload);
  };

  const handleChangeFile = async (e) => {
    const File = e.target.files[0];
    setSelectedFile(File);
    setNextUpload(!IsNextUpload);
    const ImageDataUrl = await ReadImageFile(File);
    setProfileViewImage(ImageDataUrl.result);
  };

  // read a file selected by user and convert it into data object URL
  const ReadImageFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        resolve(reader);
      });
      reader.readAsDataURL(file);
    });
  };
  const randomDigit = Date.now() + Math.round(Math.random() * 1000);

  // Upload Profile Photo To S3
  const UploadProfileImageToS3 = async () => {
    setLoading(true);
    // Generate unique File name for selected photo
    let currentSeletedFileType = selectedFile?.type;
    let derivedFolderName = "user-profile";

    const fileNameObj = {
      mimetype: currentSeletedFileType,
      path: derivedFolderName,
    };

    const getFilenameMeta = await updateFilenameQuery(fileNameObj);

    if (getFilenameMeta?.data?.response?.statusEnum === 1) {
      setServerFileNameMeta(getFilenameMeta?.data?.response?.data);
      // Upload the photo to s3

      showCroppedImage(getFilenameMeta?.data?.response?.data?.fileName).then(
        (resp) => {
          console.log(resp);
          const params = {
            Bucket: _S3_BUCKET,
            Key: `user-profile/${getFilenameMeta?.data?.response?.data?.fileName}`,
            Body: resp,
          };

          const upload = MyBucketContainer.putObject(params)
            .on("httpUploadProgress", (evt) => {
              setprogressData((evt.loaded * 100) / evt.total);
              setProgress(false);
            })
            .promise();

          upload
            .then(async (data, err) => {
              if (err) {
                ErrorAlert("Error in uploading.");

                console.log(err);
              } else {
                setProgress(true);
                handlePhotoModal();

                const getVerifyfileId = await updateFilePhotoQuery(
                  getFilenameMeta?.data?.response?.data?._id
                );
                if (getVerifyfileId?.data?.response?.statusEnum === 1) {
                  setS3ImageURL(
                    `https://${_S3_BUCKET}.s3.amazonaws.com/user-profile/${getFilenameMeta?.data?.response?.data?.fileName}`
                  );
                  UpdateProfilePic(getVerifyfileId?.data?.response?.data?._id);
                }
                setLoading(false);
              }
            })
            .catch((error) => {
              ErrorAlert("Error while uploading.");
              setLoading(false);
              setProfileViewImage(null);
              setProgress(true);
              handlePhotoModal();
              console.error("Error uploading file:", error);
              // Handle the error (e.g., display an error message to the user)
            });
        }
      );
    } else {
      ErrorAlert("Error in generating imageID.");
      console.log(getFilenameMeta?.data?.message);
    }

    // fetch the user details with upload photo id

    // showCroppedImage().then((resp)=>{
    //   console.log(resp,"resssssp");
    // const params = {
    //   Bucket: _S3_BUCKET,
    //   Key: `user-profile/${randomDigit}${resp.name}`,
    //   Body: resp,
    // };

    // const upload = MyBucketContainer.putObject(params).on('httpUploadProgress', (evt) => {
    //   setprogressData((evt.loaded * 100) / evt.total)
    //   setProgress(false)
    // }).promise()

    // upload.then((data, err) => {
    //   if(err){
    //     console.log(err)
    //   }else{
    //     setProgress(true)
    //     handlePhotoModal()
    //     setS3ImageURL(`https://${_S3_BUCKET}.s3.amazonaws.com/user-profile/${randomDigit}${resp.name}`)
    //     UpdateProfilePic(`https://${_S3_BUCKET}.s3.amazonaws.com/user-profile/${randomDigit}${resp.name}`)
    //   }
    // })})
  };

  return (
    <>
      <div className="flex bg-[#00000099] w-[100%] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9998] outline-none focus:outline-none">
        Overlay
      </div>
      <div className="hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]">
        <div className="bg-[#2C2C2C] __modal__container w-[35%] max-[768px]:w-[100%] h-auto max-[768px]:h-auto max-[1280px]:w-[50%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="__modal_close_icon_container flex justify-end p-[10px]">
            <div
              onClick={() => {
                setProfileViewImage(null);
                setProgress(true);
                handlePhotoModal();
              }}
              className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[20px] h-[20px]"
            >
              <img
                src={ModalClose}
                className="w-[10px] h-[10px] "
                alt="modal_close_btn"
              />
            </div>
          </div>

          <div className="__upload__container__section px-8 pb-[30px]">
            {IsNextUpload ? (
              <div className="__upload__card__container">
                <div className="__profile__photo__viewer__section flex justify-center rounded-full">
                  {ProfileViewImage === null ? (
                    ""
                  ) : (
                    <div className="relative border w-[100%] h-[400px] max-h-[400px] max-[768px]:h-[300px]">
                      <Cropper
                        image={ProfileViewImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        cropShape="round"
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>
                  )}
                  {/* <img
                    className="w-[50%] rounded-full object-contain"
                      src={URL.createObjectURL(ProfileViewImage)}
                      alt="__profile__viewer__image"
                    /> */}
                </div>

                <div className="__upload__action__center__section">
                  <div className="__action__center__button__section flex justify-center gap-[10px] py-[15px] [&>button]:w-[50%] max-[768px]:[&>button]:py-[6px] [&>button]:rounded-[8px] [&>button]:font-semibold [&>button]:py-[10px] max-[768px]:[&>button]:text-[13px]">
                    <button
                      onClick={handleCancelProfileViewer}
                      className="border "
                    >
                      Cancel
                    </button>
                    {isLoading ? (
                      <div className=" w-[50%] h-10 grid place-content-center">
                        <Loader />
                      </div>
                    ) : (
                      <button
                        onClick={UploadProfileImageToS3}
                        className="bg-gradient-to-r text-[#000] from-[#FBBC5E] to-[#F3A151]"
                      >
                        Crop & Upload
                      </button>
                    )}
                  </div>

                  <div className="__upload__progress__bar_-section">
                    {isProgress ? (
                      ""
                    ) : (
                      <div className="flex flex-col gap-[10px]">
                        <span
                          className={`font-medium font-body ${
                            isProgress ? "text-[green]" : "text-[gray]"
                          }`}
                        >
                          {isProgress
                            ? "Upload Successfully!"
                            : "Upload Starting..."}
                        </span>
                        <LinearProgress
                          value={progressData}
                          variant="determinate"
                        />
                      </div>
                    )}
                    {/* <div className="__progres__bar__body w-[100%] bg-[#000] h-[5px]">
                       <div className={`__progress__bar__loader w-[100%] bg-[gray] h-[5px]`}>

                       </div>
                     </div> */}
                  </div>
                </div>
              </div>
            ) : (
              <div
                onClick={handleOpenFileSystem}
                className="__upload__form__container border-2 border-[#FBBC5E] border-dashed hover:bg-[#f3a25112] py-[8px] rounded-[6px] decoration-dotted hover:cursor-pointer"
              >
                <form>
                  <input
                    ref={Inputref}
                    onChange={handleChangeFile}
                    name="upload_file"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    hidden
                  />
                  <div className="__upload__contents flex justify-center gap-8 py-[10px] max-[768px]:gap-2 max-[768px]:py-[5px] max-[768px]:leading-4">
                    <div className="__image_icon__container bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] p-4 rounded-full">
                      <img
                        src={UploadIcon}
                        alt="upload__icon"
                        className="w-[30px] h-[30px] max-[768px]:w-[18px] max-[768px]:h-[18px]"
                      />
                    </div>
                    <span className="pt-[20px] font-heading text-[13px] max-[768px]:text-[11px]">
                      Browse File to upload
                    </span>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhotoModal;
