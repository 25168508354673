import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import MetaMaskIcon from "assets/images/metamask-icon.png";

import { GlobalStore } from "store/GlobalStore";

import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import abiJson from "constant/abi-json";
import Loader from "components/Loader/Loader";
import ShadowFrame from "components/shadow-frame";

import Web3 from "web3";
import ConnectWallet from "./components/ConnectWallet";
import Alert from "components/popup/Alert/Alert";
import SelectWalletAccount from "components/popup/SelectWalletAccount/SelectWalletAccount";
import TransactionProcessing from "components/popup/TransactionProcessing/TransactionProcessing";
import TransactionSuccess from "components/popup/TransactionSuccess/TransactionSuccess";
import TransactionFailed from "components/popup/TransactionFailed/TransactionFailed";

const CHAIN_ID = process.env.REACT_APP_WEB3_CHAIN_ID;
const CHAIN_NAME = process.env.REACT_APP_WEB3_CHAIN_NAME;
const CHAIN_RPC_URL = process.env.REACT_APP_WEB3_CHAIN_RPC_URL;
const CURRENCY_NAME = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_NAME;
const CURRENCY_SYMBOL = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_SYMBOL;
const FSTR_CONTRACT = process.env.REACT_APP_WEB3_FSTR_CONTRACT;

const CheckoutFSTR = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [searchParams] = useSearchParams();

  const productType = searchParams.get("productType");
  const productName = searchParams.get("productName");
  const amount = searchParams.get("amount");
  const productId = searchParams.get("productId");
  const themeId = searchParams.get("themeId");
  const isSubscription = searchParams.get("isSubscription");
  const channelId = searchParams.get("channelId");

  // this checks for paid content, if we are not passing required fields in search params then go back, unless its for cart or subscription
  if (
    !searchParams.get("isCart") &&
    !searchParams.get("isSubscription") &&
    (!productType || !amount || !productId)
  ) {
    navigate(-1);
  }

  const [loading, setLoading] = useState(true);
  const [processingText, setProcessingText] = useState("Your transaction is being processed.");
  const [processingMarketPlaceLink, setProcessingMarketPlaceLink] = useState(false);
  const [showProcessingTip, setShowProcessingTip] = useState(false);
  const [showErrorTip, setShowErrorTip] = useState(false);
  const [tokenAdded, setTokenAdded] = useState(false);

  const [metamaskInstalled, setMetamaskInstalled] = useState(false);
  const [web3Instance, setWeb3Instance] = useState(null);
  const [FSTRContract, setFSTRContract] = useState(null);
  const [rejectedWalletConnect, setRejectedWalletConnect] = useState(false);
  const [rejectedChainDownload, setRejectedChainDownload] = useState(false);
  const [rejectedChainSwitch, setRejectedChainSwitch] = useState(false);
  const [reRequestConnectWallet, setReRequestConnectWallet] = useState(false);
  const [reRequestSwitchChain, setReRequestSwitchChain] = useState(false);
  const [reRequestTransaction, setReRequestTransaction] = useState(false);

  const [canInitiateTrans, setCanInitiateTrans] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const selectedAccountRef = useRef(null);

  const [rejectedTransaction, setRejectedTransaction] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState(
    "Something went wrong"
  );

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [allAccounts, setAllAccounts] = useState([]);
  const [showAccountSelectPopup, setShowAccountSelectPopup] = useState(false);

  const [errorInitiating, setErrorInitiating] = useState(false);

  const onErrorPopupClose = () => {
    setErrorPopupMessage("Something went wrong");
    setShowErrorPopup(false);
    setShowErrorTip(false);
  };

  const checkMetamask = async () => {
    // Check if MetaMask is installed
    if (window.ethereum && window.ethereum.isMetaMask) {
      // MetaMask is installed
      setMetamaskInstalled(true);
      console.log("MetaMask is installed");
      return true;
    } else {
      // MetaMask is not installed
      setMetamaskInstalled(false);
      console.error("MetaMask not detected!");
      return false;
    }
  };

  const initContract = (web3Inst) => {
    const contract = new web3Inst.eth.Contract(abiJson, FSTR_CONTRACT);
    setFSTRContract(contract);
  };

  // Initialize Web3
  const initWeb3 = async () => {
    if (!metamaskInstalled) {
      return;
    }
    setLoading(true);
    setRejectedWalletConnect(false);
    if (window.ethereum) {
      const w3Instance = new Web3(window.ethereum);
      try {
        setLoading(true);
        // Request account access if needed
        await window.ethereum.enable();
        setWeb3Instance(w3Instance);
        initContract(w3Instance);
      } catch (error) {
        console.log("User Denied account access", error);
        setRejectedWalletConnect(true);
        setLoading(false);
      }
    } else if (window.web3) {
      // Use the injected web3 instance
      const web3Inst = new Web3(window.web3.currentProvider);
      setWeb3Instance(web3Inst);
      initContract(web3Inst);
    } else {
      console.log("No web3 instance injected, please install MetaMask");
    }
  };

  const addTokenToWallet = async (retriesLeft) => {
    try {
      // 1. fetch all user's accounts here, if there are more than one account then we should show a model to user to choose which account to transact with
      const accounts = await web3Instance.eth.getAccounts();

      if (accounts?.length <= 1) {
        localStorage.setItem("web3FromAddress", accounts[0]);
        setSelectedAccount(accounts[0]);
        selectedAccountRef.current = accounts[0];
      } else {
        setAllAccounts(accounts);
        console.log(showAccountSelectPopup, selectedAccountRef.current);
        if (!selectedAccountRef.current) {
          setLoading(false);
          setShowAccountSelectPopup(true);
        }
        while (!selectedAccountRef.current) {
          await new Promise((resolve) => {
            setTimeout(() => {
              console.log("waiting for user to select account");
              resolve();
            }, 200);
          });
        }
        setShowAccountSelectPopup(false);
        console.log("selected account", selectedAccountRef.current);
      }
      const isAddedAlready =
        localStorage.getItem(selectedAccountRef.current + "-tokencache") === "FSTR";
      if (!isAddedAlready) {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: FSTR_CONTRACT,
              symbol: "FSTR",
              decimals: 18,
            },
          },
        });
        if (!wasAdded) {
          console.log("user rejectect token import");
          setLoading(false);
          setShowErrorPopup(true);
          setErrorPopupMessage(
            "Something went wrong!. Add the tokens to your wallet for your ease access."
          );
          if (retriesLeft) {
            addTokenToWallet(retriesLeft-1);
          }
        }
        localStorage.setItem(selectedAccountRef.current + "-tokencache", "FSTR");
      }
      setTokenAdded(true);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setShowErrorPopup(true);
      setErrorPopupMessage(
        "Something went wrong!. Add the tokens to your wallet for your ease access."
      );
      if (retriesLeft) {
        addTokenToWallet(retriesLeft-1);
      }
    }
    if(!retriesLeft){
      // User don't want to add the token in his wallet
      setTokenAdded(true)
    }
  };

  const switchChain = async () => {
    if (!metamaskInstalled || !web3Instance) {
      return;
    }

    setLoading(true);

    if (window.ethereum.networkVersion == CHAIN_ID) {
      setCanInitiateTrans(true);
    } else if (window.ethereum.networkVersion != CHAIN_ID) {
      setRejectedChainDownload(false);
      setRejectedChainSwitch(false);
      setShowErrorPopup(false);
      setLoading(true);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3Instance.utils.toHex(+CHAIN_ID) }],
        });
        // reaching here means, we have successfully switched chains, call this hook again to set all states correctly
        setReRequestSwitchChain((prev) => !prev);
      } catch (err) {
        setLoading(false);
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          try {
            setLoading(true);
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: CHAIN_NAME,
                  chainId: web3Instance.utils.toHex(+CHAIN_ID),
                  nativeCurrency: {
                    name: CURRENCY_NAME,
                    decimals: 18,
                    symbol: CURRENCY_SYMBOL,
                  },
                  rpcUrls: [CHAIN_RPC_URL],
                },
              ],
            });

            // reaching here means, our chain has just been added to metamask, we should now re request the chain switch from user
            setReRequestSwitchChain((prev) => !prev);
          } catch (error) {
            console.log("user rejected adding the chain", error);
            setRejectedChainDownload(true);
            setLoading(false);
            setShowErrorPopup(true);
            setErrorPopupMessage("You must approve adding the chain");
          }
        } else if (err.code == 4001) {
          console.log("user rejected chain switch");
          setRejectedChainSwitch(true);

          setShowErrorPopup(true);
          setErrorPopupMessage("You must approve switching chains");
        }
      }
    }
  };

  const sendApproval = async () => {
    try {
      const web3ToAddress = localStorage.getItem("web3ToAddress");
      // Initiate transaction

      const transcationTimeout = setTimeout(() => {
        setLoading(false);
        setErrorPopupMessage("Your transaction timed out. Please try again");
        setShowErrorPopup(true);
        setShowErrorTip(true)
      }, 10 * 60 * 1000);

      const speedUpTimeout = setTimeout(() => {
        setProcessingText("Your transaction is being processed. If your transaction is taking longer than expected, you can try speeding it up the by selecting a higher gas fee option in MetaMask");
        setShowProcessingTip(true);
      }, 10 * 1000);
      // Approving maximum amount for crypto autopay
      const tx = await FSTRContract.methods.approve(
        web3ToAddress,
        "0x8000000000000000000000000000000000000000000000000000000000000000"
      );
      const estimateGas = await tx.estimateGas({ from: selectedAccountRef.current });
      const receipt = await tx.send({ from: selectedAccountRef.current, gas: Number(estimateGas) });

      // reaching here means transaction is completed from blockchain
      console.log("purchase success", receipt);
      // storing the transaction hash for later to verify purchase
      localStorage.setItem("transactionhashfstr", receipt.transactionHash);
      clearTimeout(speedUpTimeout)
      clearTimeout(transcationTimeout)
      setShowProcessingTip(false)
      setProcessingText("Transaction is done, let us verify it for you!")

      processCryptTranascationOnBackend();
      verifyTransactionOnBackend();
    } catch (error) {
      // either user rejected the transaction or user has insufficient funds
      console.log("error in approval", error.code);

      setLoading(false);
      setRejectedTransaction(true);
      setErrorPopupMessage("You must approve the admin wallet to have access to your FSTR");
      setShowErrorPopup(true);
    }
  };

  // to send crypto transaction to block chain
  const sendTransaction = async () => {
    try {
      const web3ToAddress = localStorage.getItem("web3ToAddress");
      // Initiate transaction

      const transcationTimeout = setTimeout(() => {
        setLoading(false);
        setErrorPopupMessage("Your transaction timed out. Please try again");
        setShowErrorPopup(true);
        setShowErrorTip(true)
      }, 10 * 60 * 1000);

      const speedUpTimeout = setTimeout(() => {
        setProcessingText("Your transaction is being processed. If your transaction is taking longer than expected, you can try speeding it up the by selecting a higher gas fee option in MetaMask");
        setShowProcessingTip(true);
      }, 10 * 1000);

      const tx = await FSTRContract.methods.transfer(
        web3ToAddress,
        web3Instance.utils.toWei(amount, "ether")
      );
      const estimateGas = await tx.estimateGas({ from: selectedAccountRef.current });
      const receipt = await tx.send({ from: selectedAccountRef.current, gas: Number(estimateGas) });

      // reaching here means transaction is completed from blockchain
      console.log("purchase success", receipt);
      // storing the transaction hash for later to verify purchase
      localStorage.setItem("transactionhashfstr", receipt.transactionHash);
      clearTimeout(speedUpTimeout);
      clearTimeout(transcationTimeout);
      setShowProcessingTip(false)
      setProcessingText("Transaction is done, let us verify it for you!")

      processCryptTranascationOnBackend();
      verifyTransactionOnBackend();
    } catch (error) {
      // either user rejected the transaction or user has insufficient funds
      console.log("error in transaction", error.code);

      setLoading(false);
      setRejectedTransaction(true);
      setErrorPopupMessage(
        "You must approve the transaction request and have sufficient funds"
      );
      setShowErrorPopup(true);
    }
  };

  // this will handle getting user account details, and handle multiple accounts scenarios
  const initiateTransaction = async () => {
    console.log("initiate transaction called");
    setLoading(true);
    // setting it false here in case re requesting transaction
    setRejectedTransaction(false);

    let initiateSuccess = false;

    // 2. call our backend's initiate trans api here, and store the hash,
    if (isSubscription) {
      initiateSuccess = await inititateSubscriptonTransactionOnBackend();
    } else {
      // this will return true or false for successful initiation
      initiateSuccess = await initiateTransactionOnBackend();
    }

    // initiate transaction failed
    if (!initiateSuccess) {
      setLoading(false);
      setErrorInitiating(true);
      setErrorPopupMessage("Something went wrong");
      setShowErrorPopup(true);

      return;
    }

    // 3. call the api to handle the actual transaction
    if (isSubscription) {
      await sendApproval();
    } else {
      await sendTransaction();
    }
  };

  // this will initiate transaction on our backend and we will store required values in localstorage here
  const initiateTransactionOnBackend = async () => {
    try {
      let res = null;
      setLoading(true);
      if (productType === "event") {
        res = await fetch(BASE_URL + EndpointsSlug.INITIATE_CRYPTO_EVENT_PURCHASE, {
          method: "POST",
          body: JSON.stringify({
            eventId: productId,
            web3FromAddress: localStorage.getItem("web3FromAddress"),
          }),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        });
      } else {
        let product = {
          amount: Number(amount),
          productId: productId,
          productType: productType,
          name: productName,
        };

        // for apartments and ships
        if (themeId) {
          product = {
            ...product,
            themeId,
          };
        }

        // payload for checking out a single paid product
        let payload = {
          products: [product],
          paymentGatewayName: "polygon",
          total_amount: Number(amount),
          web3FromAddress: localStorage.getItem("web3FromAddress"),
        };

        // if we are checking out a cart, we are getting the payload stored in session storage from the cart page and usingit here
        if (searchParams.get("isCart")) {
          console.log("cart checkout");
          payload = {
            products: JSON.parse(sessionStorage.getItem("cartCheckoutDataCrypto")),
            paymentGatewayName: "polygon",
            total_amount: JSON.parse(sessionStorage.getItem("cartCheckoutDataCrypto")).reduce(
              (acc, current) => {
                return acc + current.amount;
              },
              0
            ),
            web3FromAddress:  selectedAccountRef.current
          };
        }

        res = await fetch(BASE_URL + EndpointsSlug.INITIATE_CRYPTO_TRANSACTION, {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        });
      }

      if (res === null) {
        throw new Error("Something went wrong!");
      }

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        throw new Error(res.response?.message);
      }

      // reching here means request succeeded
      // extract required values from response here
      console.log(res?.response?.data, "added check");
      localStorage.setItem(
        "transactionpaymenthashfstr",
        res?.response?.data?.gatewayTransaction?.paymentHash
      );
      localStorage.setItem("web3ToAddress", res?.response?.data?.gatewayTransaction?.web3ToAddress);
      // returning if initiate succeeded or not
      return res?.response?.statusEnum == 1;

      // setPaymentUrl(res.response?.data?.session?.url);
    } catch (e) {
      console.log("failed to initiate transaction", e);
      return false;
    }
  };

  const inititateSubscriptonTransactionOnBackend = async () => {
    try {
      setLoading(true);
      let payload = {
        channelId,
        web3FromAddress: selectedAccountRef.current,
      };

      let res = await fetch(
        BASE_URL + EndpointsSlug.INITIATE_CRYPTO_SUBSCRIPTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        throw new Error(res.response?.message);
      }

      // reching here means request succeeded
      // extract required values from response here
      console.log(res?.response?.data, "added check");
      localStorage.setItem(
        "transactionpaymenthashfstr",
        res?.response?.data?.gatewayTransaction?.paymentHash
      );
      localStorage.setItem("web3ToAddress", res?.response?.data?.gatewayTransaction?.web3ToAddress);
      // returning if initiate succeeded or not
      return res?.response?.statusEnum == 1;

      // setPaymentUrl(res.response?.data?.session?.url);
    } catch (e) {
      console.log("failed to initiate transaction", e);
      return false;
    }
  };

  // we dont need the respone of this, just call this to make processing in backend of blockchain faster
  const processCryptTranascationOnBackend = async () => {
    try {
      setLoading(true);
      let payload = {
        transactionHash: localStorage.getItem("transactionhashfstr"),
        paymentHash: localStorage.getItem("transactionpaymenthashfstr"),
      };
      let res = await fetch(BASE_URL + EndpointsSlug.PROCESS_CRYPTO_TRANSACTION, {
        method: "POST",
        body: JSON.stringify(payload),
        mode: "cors",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        setLoading(false);
        setShowErrorPopup(true);
        setErrorPopupMessage("Something went wrong!. We've recorded your transaction, Please contact for support");
        return;
      }
    } catch (error) {
      setLoading(false);
      setShowErrorPopup(true);
      setErrorPopupMessage("Something went wrong!. We've recorded your transaction, Please contact for support");
      return;
    }
  };

  // will verify the transaction using transaction hash
  const verifyTransactionOnBackend = async () => {
    try {
      setLoading(true);
      let payload = {
        paymentHash: localStorage.getItem("transactionpaymenthashfstr"),
      };

      let res = await fetch(BASE_URL + EndpointsSlug.VERIFY_CRYPTO_TRANSACTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        setLoading(false);
        setShowErrorPopup(true);
        setErrorPopupMessage("Something went wrong!. We've recorded your transaction, Please contact for support");
        return;
      }
      const status = res.response.data.gatewayTransactionDetails.status ?? "failed"
      if (status === "pending") {
        setTimeout(() => {
          verifyTransactionOnBackend();
        }, 5000);
        setProcessingText("Your part is done! let us verify the transaction for you!");
        setProcessingMarketPlaceLink(true);
        setShowProcessingTip(false);
        return;
      }

      if (status === "cancelled" || status === "failed") {
        setLoading(false);
        setShowErrorPopup(true);
        setErrorPopupMessage("Something went wrong!. We've recorded your transaction, Please contact for support");
        return;
      }

      setLoading(false);
      setShowSuccessPopup(true);
    } catch (error) {
      setLoading(false);
      setShowErrorPopup(true);
      setErrorPopupMessage("Something went wrong!. We've recorded your transaction, Please contact for support");
      return;
    }
  };

  // this will check if metamask extension is installed or not in user's browser
  useEffect(() => {
    checkMetamask();

    const checkAndReload = async () => {
      const isInstalled = await checkMetamask();
      if (!isInstalled) {
        // Refresh the current page
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", checkAndReload);
    return () => {
      return document.removeEventListener("visibilitychange", checkAndReload);
    };
  }, []);

  // initialize web3
  useEffect(() => {
    (async () => {
      await initWeb3();
    })();
  }, [metamaskInstalled, reRequestConnectWallet]);

  // request to change eth chain to our supported chain
  useEffect(() => {
    (async () => {
      await switchChain();
    })();
  }, [metamaskInstalled, web3Instance, reRequestSwitchChain]);

  // initiate transaction here
  useEffect(() => {
    if (!canInitiateTrans || !web3Instance) {
      return;
    }
    addTokenToWallet(2);
  }, [canInitiateTrans, reRequestSwitchChain]);

  // initiate transaction here
  useEffect(() => {
    if (!canInitiateTrans || !web3Instance || !tokenAdded) {
      return;
    }

    (async () => {
      await initiateTransaction();
    })();
  }, [canInitiateTrans, web3Instance, reRequestTransaction, tokenAdded]);

  if (!metamaskInstalled) {
    return (
      <div className="mt-20 lg:mt-28 mx-auto min-h-screen w-full lg:w-3/4 xl:w-1/2">
        <ConnectWallet
          clickHandler={() =>
            window.open("https://metamask.io/download.html", "_blank")
          }
        />
      </div>
    );
  }

  // if (loading) {
  //   return (
  //     <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
  //       <Loader />
  //       {canInitiateTrans && (
  //         <p className="text-center mt-6 text-white/50 font-medium">
  //           Processing Transaction
  //         </p>
  //       )}
  //       <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
  //     </div>
  //   );
  // }
  if (loading) {
    return (
      <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
        <TransactionProcessing
          open={true}
          canClose={false}
          processingText={processingText}
          showLink={processingMarketPlaceLink}
          showProcessingTip={showProcessingTip}
        />
      </div>
    );
  }

  return (
    <div className="relative min-h-screen w-full px-8">
      {(rejectedChainDownload || rejectedChainSwitch) && (
        <div className="flex flex-col items-center justify-center gap-6 w-full lg:w-3/4 xl:w-1/2 mx-auto mt-20 lg:mt-40 border border-[#313131] rounded-lg py-8 bg-black/10">
          <div className="mt-2 w-fit rounded-full border border-[#898989] p-4 mx-auto">
            <img
              className="w-[56px] h-[52px] object-contain"
              src={MetaMaskIcon}
              alt="metamask-icon"
            />
          </div>
          <h2 className="font-body font-semibold text-xl text-center">
            {rejectedChainDownload
              ? "To proceed you must add the required FSTR chain to MetaMask"
              : "To proceed you must switch to the required FSTR chain in MetaMask"}
          </h2>
          <button
            className="btn-gradient font-bold text-black rounded-[6px] px-6 py-3"
            onClick={() => {
              setLoading(true);
              setShowErrorPopup(false);
              setReRequestSwitchChain((prev) => !prev);
            }}
          >
            {rejectedChainDownload ? "Add FSTR Chain" : "Switch FSTR Chain"}
          </button>
          <Link
            to={`/marketplace`}
            className="mt-2 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]/50 hover:text-[#fbbc5e]"
          >
            Go back to Marketplace
          </Link>
        </div>
      )}
      {rejectedWalletConnect && (
        <div className="flex flex-col items-center justify-center gap-6 w-full lg:w-3/4 xl:w-1/2 mx-auto mt-20 lg:mt-40 border border-[#313131] rounded-lg py-8 bg-black/10">
          <div className="mt-2 w-fit rounded-full border border-[#898989] p-4 mx-auto">
            <img
              className="w-[56px] h-[52px] object-contain"
              src={MetaMaskIcon}
              alt="metamask-icon"
            />
          </div>
          <h2 className="font-body font-semibold text-xl text-center">
            To proceed you must connect your wallet
          </h2>
          <button
            className="btn-gradient font-bold text-black rounded-[6px] px-6 py-3"
            onClick={() => {
              setShowErrorPopup(false);
              setReRequestConnectWallet((prev) => !prev);
            }}
          >
            Connect Wallet
          </button>
          <Link
            to={`/marketplace`}
            className="mt-2 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]/70 hover:text-[#fbbc5e]"
          >
            Go back to Marketplace
          </Link>
        </div>
      )}
      {rejectedTransaction && (
        <div className="flex flex-col items-center justify-center gap-6 w-full lg:w-3/4 xl:w-1/2 mx-auto mt-20 lg:mt-40 border border-[#313131] rounded-lg py-8 px-4 bg-black/10">
          <div className="mt-2 w-fit rounded-full border border-[#898989] p-4 mx-auto">
            <img
              className="w-[56px] h-[52px] object-contain"
              src={MetaMaskIcon}
              alt="metamask-icon"
            />
          </div>
          <h2 className="font-body font-semibold text-xl text-center">
            Transaction Failed
          </h2>
          <h3 className="font-body font-semibold text-base text-white/50 text-center px-10">
            To complete the purchase you must approve the transaction request
            and you must have sufficient funds in your account.
          </h3>
          <button
            className="btn-gradient font-bold text-black rounded-[6px] px-6 py-3"
            onClick={() => {
              setShowErrorPopup(false);
              setReRequestTransaction((prev) => !prev);
            }}
          >
            Try Again
          </button>
          <Link
            to={`/marketplace`}
            className="mt-2 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]/70 hover:text-[#fbbc5e]"
          >
            Go back to Marketplace
          </Link>
        </div>
      )}
      {errorInitiating && (
        <div className="flex flex-col items-center justify-center gap-6 w-full lg:w-3/4 xl:w-1/2 mx-auto mt-20 lg:mt-40 border border-[#313131] rounded-lg py-8 px-4 bg-black/10">
          <div className="mt-2 w-fit rounded-full border border-[#898989] p-4 mx-auto">
            <img
              className="w-[56px] h-[52px] object-contain"
              src={MetaMaskIcon}
              alt="metamask-icon"
            />
          </div>
          <h2 className="font-body font-semibold text-xl text-center">
            Transaction Failed
          </h2>
          <h3 className="font-body font-semibold text-base text-white/50 text-center px-10">
            Something went wrong.
          </h3>
          <button
            className="btn-gradient font-bold text-black rounded-[6px] px-6 py-3"
            onClick={() => {
              setShowErrorPopup(false);
              setReRequestTransaction((prev) => !prev);
            }}
          >
            Try Again
          </button>
          <Link
            to={`/marketplace`}
            className="mt-2 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]/70 hover:text-[#fbbc5e]"
          >
            Go back to Marketplace
          </Link>
        </div>
      )}
      {showSuccessPopup && (
        <TransactionSuccess open={showSuccessPopup} canClose={false} />
      )}
      {showErrorPopup && (
        <TransactionFailed
          open={showErrorPopup}
          message={errorPopupMessage}
          onClose={onErrorPopupClose}
          errorTip={showErrorTip}
          isSuccess={false}
        />
      )}
      {showAccountSelectPopup && (
        <SelectWalletAccount
          open={showAccountSelectPopup}
          accounts={allAccounts}
          canClose={false}
          onSelectAccount={(index) => {
            setLoading(true);
            localStorage.setItem("web3FromAddress", allAccounts[index]);
            setSelectedAccount(allAccounts[index]);
            selectedAccountRef.current = allAccounts[index];
          }}
        />
      )}
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default CheckoutFSTR;
