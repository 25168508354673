/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module EntertainmentsLandingPage
 *@developer Sudhanshu
 */

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Zoom } from "react-reveal";
import ShadowFrame from "components/shadow-frame";
import LargeCarousel from "components/carousel/MostTreading";
import ChannelImage from "assets/images/channel.png";
import {
  useGetMusicVideosQuery,
  useGetPremiumVideosQuery,
  useGetMostTrendingContentQuery,
  useGetTrendingChannelsMutation,
} from "store/slicer/Entertainment";
import Loader from "components/Loader/Loader";
import EntertainmentHero from "assets/images/entertainment-hero.png";
import ImageConstant from "../../../constant/imageConstant";

/* The `Index` component is a React functional component that represents a page in the FourthStar User
Dashboard. It displays a layout of Entertainment Page with category Volumetric, 180, 360, 2D, Music and Channels */
const Index = () => {
  const navigate = useNavigate();

  const [allCategory] = useState([
    { name: "Events", slug: "events" },
    { name: "Volumetric Video", slug: "volumetric-video" },
    { name: "180 video", slug: "180-video" },
    { name: "360 Video", slug: "360-video" },
    { name: "2D Video", slug: "2D-video" },
    { name: "Music", slug: "music" },
    { name: "Channels", slug: "channels" },
  ]);

  const mostTrending = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "1",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "2",
      tags: "Volcap",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "US$ 9.99",
      id: "3",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "4",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "5",
    },
  ];

  // const PremiumContent = [
  //   { title: "StellarVerse Spectacle: Cosmic Symphony", productType: "Premium", id: "1"},
  //   { title: "StellarVerse Spectacle: Cosmic Symphony", productType: "Premium", id: "2", tags: "Volcap"},
  //   { title: "StellarVerse Spectacle: Cosmic Symphony", productType: "Premium", id: "3"},
  //   { title: "StellarVerse Spectacle: Cosmic Symphony", productType: "Premium", id: "4"},
  //   { title: "StellarVerse Spectacle: Cosmic Symphony", productType: "Premium", id: "5"},
  // ]

  //fetching trending channels
  const [TrendingChannelsData, setTrendingChannelsData] = useState([]);
  const [fetchTrendingChannels, trendindChannelInfo] =
    useGetTrendingChannelsMutation();

  useEffect(() => {
    async function fetchTChannels() {
      const res = await fetchTrendingChannels();
      if (res?.data?.result?.statusEnum == 1) {
        let data = res?.data?.result?.data;
        // channel data is stored inside another _id field
        data = data.map((item) => item?._id);
        data = data.map((item) => {
          let url = item?.channelThumbnail || ChannelImage;
          return {
            ...item,
            channelThumbnail: url,
          };
        });
        setTrendingChannelsData(data);
      }
    }

    fetchTChannels();
  }, []);

  // fetching music
  let {
    data: Music = [],
    isFetching: isFetchingMusic,
    isSuccess: isSuccessMusic,
    isError: isErrorMusic,
    error: errorMusic,
  } = useGetMusicVideosQuery({
    searchQuery: "",
  });

  // fetching trending content
  let {
    data: TrendingContent = [],
    isFetching: isFetchingtrendingContent,
    isSuccess: isSuccesstrendingContent,
    isError: isErrortrendingContent,
    error: errortrendingContent,
  } = useGetMostTrendingContentQuery("video");

  // fetching premium videos
  let {
    data: PremiumContent = [],
    isFetching: isFetchingPremium,
    isSuccess: isSuccessPremium,
    isError: isErrorPremium,
    error: errorPremium,
  } = useGetPremiumVideosQuery("");

  // Mapping music data
  if (isSuccessMusic && Music?.response?.statusEnum == 1) {
    Music = Music.response.data.content;

    Music = Music.map((item, ind) => {
      const title = item.contentName;
      let productType = "Free";
      if (
        item.contentPreference == "paid" ||
        item.contentPreference == "premium"
      ) {
        productType = "Paid";
      }
      if (item.contentPreference == "subscription") {
        productType = "Premium";
      }

      return {
        ...item,
        channel: item.channel?.channelHandle || item.channel?._id,
        title,
        productType,
        price: "$" + (item.price || 0),
        id: item._id,
      };
    });
  }

  // Mapping Premium data
  if (isSuccessPremium && PremiumContent?.response?.statusEnum == 1) {
    PremiumContent = PremiumContent.response.data;

    PremiumContent = PremiumContent.map((item, ind) => {
      const title = item.contentName;
      let productType = "Paid";
      if (
        item.contentPreference == "paid" ||
        item.contentPreference == "premium"
      ) {
        productType = "Paid";
      }
      if (item.contentPreference == "subscription") {
        productType = "Premium";
      }

      return {
        ...item,
        channel: item.channel?.channelHandle || item.channel?._id,
        title,
        productType,
        price: "$" + (item.price || 0),
        id: item._id,
      };
    });
  }

  // Mapping Trending Content data
  if (
    isSuccesstrendingContent &&
    !isErrortrendingContent &&
    TrendingContent?.result?.statusEnum == 1
  ) {
    TrendingContent = TrendingContent?.result?.data;

    TrendingContent = TrendingContent.map((item) => {
      return {
        ...item?.contentData,
        channel: item.channelId.channelHandle || item.contentData.channel,
      };
    });

    TrendingContent = TrendingContent.map((item, ind) => {
      const title = item.contentName;
      let productType = "Free";
      if (
        item.contentPreference == "paid" ||
        item.contentPreference == "premium"
      ) {
        productType = "Paid";
      }
      if (item.contentPreference == "subscription") {
        productType = "Premium";
      }

      return {
        ...item,
        title,
        productType,
        price: "$" + (item.price || 0),
        id: item._id,
      };
    });
  }

  // const Music = [
  //   { title: "Supermassive black hole", productType: "Paid", id: "1", price: "US$ 9.99"},
  //   { title: "Supermassive black hole", productType: "Paid", id: "2", price: "US$ 9.99"},
  //   { title: "Supermassive black hole", productType: "Paid", id: "3", price: "US$ 9.99"},
  //   { title: "Supermassive black hole", productType: "Paid", id: "4", price: "US$ 9.99"},
  //   { title: "Supermassive black hole", productType: "Paid", id: "5", price: "US$ 9.99"},
  // ]

  const Channel = [
    { title: "Nureal", id: "1", url: ChannelImage },
    { title: "Nureal", id: "2", url: ChannelImage },
    { title: "Nureal", id: "3", url: ChannelImage },
    { title: "Nureal", id: "4", url: ChannelImage },
    { title: "Nureal", id: "4", url: ChannelImage },
    { title: "Nureal", id: "4", url: ChannelImage },
    { title: "Nureal", id: "4", url: ChannelImage },
    { title: "Nureal", id: "5", url: ChannelImage },
  ];

  const onSearchHandler = (q) => {
    navigate(
      `/marketplace/entertainment/volumetric-video?q=${encodeURIComponent(q)}`
    );
  };

  const searchInputRef = useRef(null);

  return (
    <div className="relative px-2 md:px-4 mb-14 lg:mb-28">
      <Zoom>
        <div
          className="min-h-[200px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.ENTERTAINMENT_BANNER_IMAGE}')`,
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center p-4">
            <h1 className="font-heading text-xl md:text-2xl lg:text-3xl text-center">
              Welcome to Fourth Star Entertainment
            </h1>
          </div>
        </div>
      </Zoom>
      <div className="relative">
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -left-[80px] md:-left-[300px] -top-[80px] md:-top-[150px]" />
        <div className="mb-20 heading relative z-10">
          <div className="flex items-center justify-between mb-4 lg:mb-2">
            <h3 className="font-heading text-sm sm:text-lg md:text-2xl">
              Explore Categories
            </h3>
            <div className="relative w-1/2 sm:max-w-[380px]">
              <svg
                className="absolute top-1/2 -translate-y-1/2 left-3"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  onSearchHandler(searchInputRef?.current?.value);
                }}
              >
                <path
                  d="M11.4351 10.0629H10.7124L10.4563 9.8159C11.3838 8.7401 11.8935 7.36667 11.8925 5.94626C11.8925 4.7702 11.5438 3.62055 10.8904 2.64269C10.237 1.66484 9.30832 0.902692 8.22179 0.452634C7.13525 0.00257642 5.93966 -0.115179 4.7862 0.114258C3.63274 0.343696 2.57322 0.910021 1.74162 1.74162C0.910021 2.57322 0.343696 3.63274 0.114258 4.7862C-0.115179 5.93966 0.00257642 7.13525 0.452634 8.22179C0.902692 9.30832 1.66484 10.237 2.64269 10.8904C3.62055 11.5438 4.7702 11.8925 5.94626 11.8925C7.4191 11.8925 8.77301 11.3528 9.8159 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z"
                  fill="#CFCFCF"
                />
              </svg>
              <input
                className="w-full px-3 py-2 sm:py-3 pl-[34px] bg-transparent border border-[#5C5C5C] rounded-lg placeholder:text-[#cfcfcf] placeholder:text-[14px] placeholder:leading-[9.57px]"
                type="text"
                name="searchquery"
                id="searchquery"
                placeholder="Search"
                ref={searchInputRef}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    onSearchHandler(searchInputRef?.current?.value);
                  }
                }}
              />
            </div>
          </div>
          <p className="text-white text-opacity-90">
            Explore a Multitude of Captivating Experiences.
          </p>
          <div className="mt-5 md:mt-8">
            <ul className="flex gap-5 overflow-x-auto">
              {allCategory?.map((category, i) => {
                if (category.slug == "events") {
                  return (
                    <li key={i}>
                      <NavLink to={category?.slug} className="link-item">
                        <svg
                          className="inline mt-[-4px] mr-2"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM16 6H2V4H16V6ZM9 11H14V16H9V11Z"
                            fill="white"
                          />
                        </svg>
                        {category?.name}
                        <span className="font-heading text-[14px] text-[#fbbc5e]">
                          {" "}
                          LIVE
                        </span>
                      </NavLink>
                    </li>
                  );
                }

                return (
                  <li key={i}>
                    <NavLink to={category?.slug} className="link-item">
                      {category?.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {isSuccesstrendingContent &&
          !isErrortrendingContent &&
          TrendingContent.length > 0 && (
            <div className="item-section mb-12 md:mb-24 relative z-10">
              <h3 className="font-heading text-lg md:text-2xl mb-5">
                Trending
              </h3>
              <LargeCarousel
                slider={TrendingContent}
                type="trending"
              ></LargeCarousel>
            </div>
          )}
        {isFetchingtrendingContent && (
          <div className="min-h-[250px] grid place-items-center item-section mb-12 md:mb-24 relative z-10">
            <Loader />
          </div>
        )}

        {isSuccessPremium && !isErrorPremium && PremiumContent.length > 0 && (
          <div className="item-section mb-12 md:mb-24 relative z-10">
            <h3 className="font-heading text-lg md:text-2xl mb-5">
              Premium Content: top picks for you
            </h3>
            <LargeCarousel
              slider={PremiumContent}
              type="trending"
            ></LargeCarousel>
          </div>
        )}
        {isFetchingPremium && (
          <div className="min-h-[250px] grid place-items-center item-section mb-12 md:mb-24 relative z-10">
            <Loader />
          </div>
        )}

        {/* <div className='item-section mb-12 md:mb-24 relative z-10'>
          <h3 className='font-heading text-lg md:text-2xl mb-5'>Premium Content: top picks for you</h3>
          {isSuccessPremium && !isErrorPremium && <LargeCarousel slider={PremiumContent} type="premium"></LargeCarousel>}
          {isFetchingPremium && <div className='min-h-[250px] grid place-content-center'><Loader/></div>}
        </div> */}
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -right-[80px] md:-right-[300px] -bottom-[80px] md:-bottom-[150px]" />
      </div>
      <div className="relative">
        {!trendindChannelInfo.isLoading && TrendingChannelsData.length > 0 && (
          <div className="item-section mb-12 md:mb-24">
            <div className="flex flex-wrap justify-between items-center mb-5 gap-4">
              <h3 className="font-heading text-lg md:text-2xl">
                Trending Channels
              </h3>
              {!trendindChannelInfo.isLoading &&
                TrendingChannelsData.length > 6 && (
                  <NavLink
                    to="/marketplace/entertainment/channels"
                    className={`clip-text text-[15px] font-semibold`}
                  >
                    See More
                  </NavLink>
                )}
            </div>
            <LargeCarousel
              slider={TrendingChannelsData}
              type="channel"
            ></LargeCarousel>
          </div>
        )}
        {trendindChannelInfo.isLoading && (
          <div className="min-h-[250px] grid place-content-center item-section mb-12 md:mb-24">
            <Loader />
          </div>
        )}

        {isSuccessMusic && !isErrorMusic && Music.length > 0 && (
          <div className="item-section mb-12 md:mb-24">
            <div className="flex flex-wrap justify-between items-center mb-5 gap-4">
              <h3 className="font-heading text-lg md:text-2xl">
                Discover Music
              </h3>
              <NavLink
                to="/marketplace/entertainment/music"
                className={`clip-text text-[15px] font-semibold`}
              >
                See More
              </NavLink>
            </div>
            {console.log("mus", Music)}
            <LargeCarousel slider={Music} type="music"></LargeCarousel>
          </div>
        )}
        {isFetchingMusic && (
          <div className="min-h-[250px] grid place-items-center mb-12 md:mb-24">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
