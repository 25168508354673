/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module BuyCards
 *@developer Sudhanshu

 Type : BuyNow || Free || Premium || MyAsset
 Card : content || product

 */

import React from "react";
import AddToCard from "./buyNow";
import Free from "./free";
import Premium from "./premium";
import InMyAssets from "./myAssets";
import AddToCart from "./AddToCart";
import BuyNowTheme from "./BuyNowTheme";
import ChannelPremium from "./channelPremium";

const BuyCards = ({
  type,
  card,
  title,
  price,
  plan,
  handleData,
  justAdded,
  onBuyHandle,
  selectedThemeIndex,
  onThemeChange,
  themes,
  star,
  isAddedToCart,
  isOwned,
  isSubscription,
}) => {
  const handleClick = (e) => {
    handleData(e);
  };
  return (
    <div className="rounded-[20px] ">
      {type === "BuyNow" && (
        <AddToCard
          type={card}
          title={title}
          price={price}
          handleData={handleClick}
          onBuyHandle={onBuyHandle}
        />
      )}
      {type === "BuyNowTheme" && (
        <BuyNowTheme
          type={card}
          title={title}
          price={price}
          handleData={handleClick}
          onBuyHandle={onBuyHandle}
          selectedThemeIndex={selectedThemeIndex}
          onThemeChange={onThemeChange}
          themes={themes}
          star={star}
          isAddedToCart={isAddedToCart}
          isOwned={isOwned}
        />
      )}
      {type === "Free" && <Free title={title} handleData={handleClick} />}
      {type === "Premium" && (
        <Premium
          price={price}
          plan={plan}
          title={title}
          handleData={handleClick}
          justAdded={justAdded}
        />
      )}
      {type === "MyAsset" && (
        <InMyAssets
          price={price}
          title={title}
          handleData={handleClick}
          justAdded={justAdded}
        />
      )}
      {type === "AddToCart" && (
        <AddToCart
          type={card}
          title={title}
          price={price}
          handleData={handleClick}
        />
      )}
      {type === "ChannelPremium" && (
        <ChannelPremium
          price={price}
          plan={plan}
          title={title}
          handleData={handleClick}
          justAdded={justAdded}
        />
      )}
    </div>
  );
};

export default BuyCards;
