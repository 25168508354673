/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Add-Tags-Page
 *@developer Shubham K
 */

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Loader from "components/Loader/Loader";
import {
  useUpdateUserTagsByTokenMutation,
  useGetUserSuggestedTagsByTokenQuery,
} from "store/slicer/userProfile";
import Alert from "components/popup/Alert/Alert";
import TagsChip from "components/partials/TagsChip";
import CloseTagIcon from "assets/images/cross_tag.png";
import PlusTagIcon from "assets/images/plus_tag.png";

// This function renders a list of suggested tags and allows to add or remove new tags
const AddTags = () => {
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [addedTags, setAddedTags] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  const [submitUserTags, submitUserTagsInfo] =
    useUpdateUserTagsByTokenMutation();

  let {
    data: fetchedTags = [],
    isFetching,
    isSuccess = false,
    isError,
    error,
  } = useGetUserSuggestedTagsByTokenQuery();

  // fetching suggested tags and storing in state
  useEffect(() => {
    if (!isFetching && isSuccess && fetchedTags?.response?.statusEnum == 1) {
      let ft = fetchedTags?.response?.data?.tags;
      // create a copy
      ft = ft.slice();
      if (ft.length > 0) {
        setSuggestedTags(ft);
      }
    }
  }, [isSuccess, isFetching]);

  // checking if form is valid
  useEffect(() => {
    let valid = false;
    if (addedTags && addedTags.length > 0) {
      valid = true;
    }
    setIsFormValid(valid);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    const res = await submitUserTags(addedTags);

    if (res?.data?.response?.statusEnum == 1) {
      navigate("/onboarding-complete");
    } else {
      setAlertMessage("Something went wrong");
      setSuccessAlert(false);
    }
  };

  const updateSelectedTags = (tagName) => {
    // tag is already added
    if (addedTags.indexOf(tagName.trim()) != -1) {
      return;
    }
    if (typeof tagName == "string" && tagName.trim().length > 0) {
      const newAddedTags = addedTags.slice();
      newAddedTags.push(tagName);
      setAddedTags(newAddedTags);

      // remove the tag from suggested tags
      const newSuggestedTags = suggestedTags.filter(
        (item) => item.trim() != tagName.trim()
      );
      setSuggestedTags(newSuggestedTags);
    }
  };

  const removeTag = (tagName) => {
    // removing tag from addedTags
    let newAddedTags = addedTags.filter(
      (item) => item.trim() != tagName.trim()
    );
    setAddedTags(newAddedTags);

    // adding it back to suggested tags if it was there originally
    let ft = fetchedTags?.response?.data?.tags;
    if (Array.isArray(ft) && ft.indexOf(tagName) != -1) {
      let newSuggestedTags = suggestedTags.slice();
      newSuggestedTags.push(tagName.trim());
      setSuggestedTags(newSuggestedTags);
    }
  };

  return (
    <>
      {/* <div className="absolute z-20 w-screen h-full bg-black"></div> */}
      <Box className="w-full max-w-[768px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            Let's Setup Your Account
          </h1>

          <div className="relative flex justify-between items-center mb-[36px] px-0 w-full md:w-3/5 mx-auto">
            <div className="absolute bottom-[53px] left-[35px] h-[2px] w-[calc(50%-35px)] bg-[#FBBC5E] z-0"></div>
            <div className="absolute bottom-[53px] right-[35px] h-[2px] w-[calc(50%-35px)] bg-[#FBBC5E] z-0"></div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-black h-8 w-8 bg-[#FBBC5E] rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                1
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Details
              </p>
            </div>

            <div className="flex flex-col justify-between items-center min-w-[70px] h-[70px] z-10">
              <div className="text-black bg-[#FBBC5E] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                2
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Select Preferences
              </p>
            </div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-black bg-[#FBBC5E] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                3
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Tags
              </p>
            </div>
          </div>

          <form className="auth-form mb-5 w-full " onSubmit={handleSubmit}>
            <div className="__tags__section mb-[50px]">
              <h5 className="font-heading text-[16px] max-[768px]:text-[13px]">
                Tell us more about your preferences
              </h5>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Added tags:
              </h5>

              <div className="__added__tags__container__main w-[80%] max-[768px]:w-full flex flex-wrap justify-start gap-[12px] pt-[34px] max-[768px]:pt-[17px]">
                {addedTags?.map((item, ind) => {
                  return (
                    <TagsChip
                      key={item}
                      styleClass="bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133]"
                      text={item}
                      icon={CloseTagIcon}
                      onRemoveHandle={() => {
                        removeTag(item);
                      }}
                      type="ADDED"
                    />
                  );
                })}
              </div>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Add tags:
              </h5>

              <div className="__add__tags__container pt-[16px]">
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Add tags..."
                  className="w-[90%] max-[768px]:w-full grow-[2] rounded-[8px] py-[8px] text-[14px] font-light px-[13px] focus:outline-none border border-[#FBBC5E] bg-transparent"
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() == "enter") {
                      e.preventDefault();
                      updateSelectedTags(inputRef.current.value);
                      inputRef.current.value = "";
                      e.target.focus();
                    }
                  }}
                />
                <button
                  className="ml-[-50px] text-[16px] text-[#FBBC5E] font-semibold"
                  onClick={() => {
                    updateSelectedTags(inputRef.current.value);
                    inputRef.current.value = "";
                  }}
                  type="button"
                >
                  Add
                </button>
              </div>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Suggested tags:
              </h5>

              <div className="__added__tags__container__main w-full max-[768px]:w-full flex flex-wrap justify-start gap-[12px] pt-[34px] max-[768px]:pt-[17px]">
                {isFetching ? (
                  <div className="h-[250px] w-full grid place-content-center">
                    <Loader />
                  </div>
                ) : (
                  suggestedTags?.map((item, ind) => {
                    return (
                      <TagsChip
                        key={item}
                        styleClass="bg-[#262422]"
                        text={item}
                        icon={PlusTagIcon}
                        onAddHandle={() => {
                          updateSelectedTags(item);
                        }}
                        type="SUGGESTED"
                      />
                    );
                  })
                )}
              </div>
            </div>

            <div className="flex justify-end px-2">
              <button
                className="py-3 px-7 border-[#fbbc5e] border-[2px] rounded-lg font-body font-bold"
                disabled={submitUserTagsInfo.isLoading}
                onClick={() => {
                  navigate("/select-preferences");
                }}
                type="button"
              >
                Back
              </button>
              <button
                className="ml-4 font-bold rounded-lg btn-gradient text-black py-3 px-5 flex justify-center items-center"
                type="submit"
                disabled={submitUserTagsInfo.isLoading || !isFormValid}
              >
                {submitUserTagsInfo.isLoading ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  "Save and Next"
                )}
              </button>
            </div>
          </form>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default AddTags;
