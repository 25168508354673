/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Apartment-Detail-Page
 *@developer Sudhanshu
 */

import React, { useState } from "react";
import {
  Link,
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import PremAptImg from "assets/images/premium_apt.png";
import { Fade, Zoom } from "react-reveal";
import ShadowFrame from "components/shadow-frame";
import BuyCards from "components/buy-cards";
import CartIcon from "assets/images/bxs_cart.png";
import ChannelImage from "assets/images/channel.png";
import videoContent from "assets/images/videoContent.png";
import SneakyPeak from "components/carousel/SneakyPeak";
import LargeCarousel from "components/carousel/MostTreading";
import UnlockPremium from "components/popup/unlockPremium";
import Popup from "components/popup";
import ComingSoon from "components/popup/comingsoon";
import {
  useGetSingle180VideoQuery,
  useGetSingle360VideoQuery,
  useGetSingleVolumetricVideoQuery,
  useGetSingle2DVideoQuery,
  useGetSingleChannelQuery,
  useGetRecommendedContentQuery,
} from "store/slicer/Entertainment";
import { useGetUserProfileByTokenQuery } from "store/slicer/userProfile";
import {
  usePostCheckUserAssetsMutation,
  usePostAddFreeAssetForUserMutation,
  useCheckUserSubscriptionsMutation,
} from "store/slicer/userAssets";
import { usePostCreateUserInteractionMutation } from "store/slicer/Analytics";
import { useSearchUserFriendMutation } from "store/slicer/friends";
import { useEffect } from "react";
import dateFormat, { masks } from "dateformat";
import SliderHeader from "components/partials/SliderHeader";
import Flag from "components/report/Flag";
import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import Loader from "components/Loader/Loader";
import { GlobalStore } from "store/GlobalStore";
import { useUpdateCartItembyTokenMutation } from "store/slicer/Cart";
import { useGetCartItemByTokenQuery } from "store/slicer/Cart";
import Alert from "components/popup/Alert/Alert";
import BuyPopupIap from "components/popup/BuyPopupIap/BuyPopupIap";
import { convertActualUsdToFstrPrice } from "utils";
/* The above code is a React component called "Index". It renders a webpage that displays information
 about an apartment, including its image, price, description, themes, gaming and streaming options,
 and suggested apartments. It also includes a dialog box that allows the user to preview the
 apartment in a 3D model. The component uses various React hooks and components, such as useState,
 useNavigate, Grid, Typography, Button, Dialog, AppBar, Toolbar, Canvas, and Stage, to create the
 desired UI and functionality. */
const Index = () => {
  const {
    data: GetCartItemQuery,
    isLoading: getCartItemLoader,
    refetch,
  } = useGetCartItemByTokenQuery();
  const [AddToCartQuery, { isLoading: addtoCartLoader }] =
    useUpdateCartItembyTokenMutation();
  const navigate = useNavigate();
  const { type, contentId, channelId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [contentType, setContentType] = useState("MyAsset");
  const [justAddedToAssets, setJustAddedToAssets] = useState(false);
  const [buyCardTitle, setBuyCardTitle] = useState("");
  const [comingSoonText, setComingSoonText] = useState("");
  const [cartItem, setCartItem] = useState([]);
  const [channelSubscribed, setChannelSubscribed] = useState(false);

  const [IsItemAdded, setIsItemAdded] = useState(false);

  const [cartContentIds, setCartContentIds] = useState([]);

  const [isItemAddCheck, setIsItemAddCheck] = useState(false);
  const [fetchedContentId, setFetchedContentId] = useState(null);

  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  let dataToFetch = useGetSingleVolumetricVideoQuery;
  let itemCategory = "";

  // here we are deciding which type of content to page for current page
  switch (type) {
    case "volumetric-video":
      dataToFetch = useGetSingleVolumetricVideoQuery;
      itemCategory = "Volumetric Videos";
      break;
    case "2D-video":
      dataToFetch = useGetSingle2DVideoQuery;
      itemCategory = "2D Videos";
      break;
    case "2D-video":
      dataToFetch = useGetSingle2DVideoQuery;
      itemCategory = "2D Videos";
      break;
    case "180-video":
      dataToFetch = useGetSingle180VideoQuery;
      itemCategory = "180 Videos";
      break;
    case "360-video":
      dataToFetch = useGetSingle360VideoQuery;
      itemCategory = "360 Videos";
      break;
    default:
      dataToFetch = useGetSingleVolumetricVideoQuery;
  }

  // fetching content data
  const {
    data = {
      response: {
        data: {},
      },
    },
    isFetching,
    isSuccess,
    isError,
    error,
  } = dataToFetch(contentId);

  // calling create user interaction api
  const [createUserInteractionTrigger, createUserInteractionInfo] =
    usePostCreateUserInteractionMutation();
  // calling create user interaction api
  useEffect(() => {
    let userInteractionPayload = {};

    switch (type) {
      case "volumetric-video":
        userInteractionPayload = {
          keyType: "volumetricvideo",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };

        break;
      case "2D-video":
        userInteractionPayload = {
          keyType: "video2d",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };
        break;
      case "2D-video":
        userInteractionPayload = {
          keyType: "video2d",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };
        break;
      case "180-video":
        userInteractionPayload = {
          keyType: "video180",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };
        break;
      case "360-video":
        userInteractionPayload = {
          keyType: "video360",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };
        break;
      default:
        userInteractionPayload = {
          keyType: "video180",
          contentId: id,
          contentType: "video",
          channelId: channelId,
        };
    }

    if (!isFetching) {
      createUserInteractionTrigger(userInteractionPayload);
    }
  }, [isFetching]);

  // fetching channel data
  const {
    data: channelData = {
      response: {
        data: {},
      },
    },
    isError: isErrorChannel,
    isFetching: isFetchingChannel,
  } = useGetSingleChannelQuery(channelId);

  // fetching user data
  const {
    data: userData = {
      response: {
        data: {},
      },
    },
    isFetching: isFetchingUser,
    isSuccess: isSuccessUser,
    isError: isrrorUser,
    error: errorUser,
  } = useGetUserProfileByTokenQuery();

  // user details
  let { _id: userId = "" } = userData?.response?.data || {};

  // console.log("user id",userId)

  // fetching suggested content here
  const [loadReccContent, setLoadReccContent] = useState(false);

  // content details
  let {
    contentName = "",
    description: contentDescription = "",
    sneakPeakImages = [],
    contentHighlights = [],
    contentPreference = "",
    tag: tags = [],
    category: contentCategory = "",
    price = 0,
    mediaFile,
    thumbnail: contentThumbnail,
    _id: id,
    currency: currencyType,
  } = data?.response?.data || {};

  //channel details
  const {
    _id: channelProductId,
    channelThumbnail,
    channelName,
    channelHandle,
    subscriptionPrice,
  } = channelData?.response?.data;

  sneakPeakImages = sneakPeakImages?.map((item) => ({
    imageURL: item.image,
  }));

  // userId is needed to fetch recommended content
  useEffect(() => {
    if (userId && id) {
      setFetchedContentId(id);
      setLoadReccContent(true);
    }
  }, [isFetchingUser, isSuccessUser, isFetching]);
  // console.log(fetchedContentId, id);

  // data will not be fetched until skip becomes false
  let {
    data: recommendedContentData = [],
    isFetching: isFetchingRecommendedContent,
    isSuccess: isSuccessRecommendedContent,
    isError: isrrorRecommendedContent,
    error: errorRecommendedContent,
  } = useGetRecommendedContentQuery(
    {
      userId,
      contentId: id,
      category: type == "2D-video" ? "2D-video" : type,
    },
    {
      skip: !loadReccContent,
    }
  );

  if (
    isSuccessRecommendedContent &&
    recommendedContentData?.result?.statusEnum == 1
  ) {
    recommendedContentData = recommendedContentData.result.data;

    recommendedContentData = recommendedContentData?.map((item, ind) => {
      const title = item.contentName;
      let productType = "Free";
      if (item.contentPreference == "paid") {
        productType = "Paid";
      }
      if (item.contentPreference == "subscription") {
        productType = "Premium";
      }

      return {
        ...item,
        title,
        channel:
          item.channel?.channelHandle || item.channel?._id || item?.channel,
        productType,
        price: "$" + item.price,
        id: item._id,
      };
    });
  } else {
    recommendedContentData = [];
  }

  // setting buy card initial title

  useEffect(() => {
    switch (contentPreference) {
      case "free":
        setBuyCardTitle(
          "This content is available for free. Add to your assets and enjoy"
        );
        setContentType("Free");
        break;
      case "paid":
        setBuyCardTitle("Buy this Content:");
        setContentType("BuyNow");
        setComingSoonText(
          "Entertainment content purchases will soon be available. Stay tuned!"
        );
        break;
      // change this to 'subscription' when api gets updated
      case "premium":
        setBuyCardTitle(
          `This content is locked. To access this premium content, Buy <b>${channelName}</b> Premium Subscription.`
        );
        setContentType("Premium");
        setComingSoonText(
          "Subscriptions will soon be available, allowing you to unlock premium content and exclusive features. Stay tuned!"
        );
        break;
      case "subscription":
        setBuyCardTitle(
          `This content is locked. To access this premium content, Buy <b>${channelName}</b> Premium Subscription.`
        );
        setContentType("Premium");
        setComingSoonText(
          "Subscriptions will soon be available, allowing you to unlock premium content and exclusive features. Stay tuned!"
        );
        break;
      default:
        setBuyCardTitle(
          "This content is available for free. Add to your assets and enjoy"
        );
        setContentType("Free");
    }
  }, [channelData, data]);

  // console.log(searchParams.get("type"));
  // const entertainmentData = [
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: ` Unlock Premium`,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     tagType: "Free",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "US$ 9.99",
  //     tagType: "Paid",
  //   }
  // ];
  const mostTrending = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "Buy @US$ 9.99",
      id: "1",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "Buy @US$ 9.99",
      id: "2",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "Buy @US$ 9.99",
      id: "3",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "Buy @US$ 9.99",
      id: "4",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "Buy @US$ 9.99",
      id: "5",
    },
  ];

  const SneakyPeek = [
    {
      title: "",
      productType: "",
      price: "",
      id: "1",
    },
    {
      title: "",
      productType: "",
      price: "",
      id: "2",
    },
    {
      title: "",
      productType: "",
      price: "",
      id: "3",
    },
    {
      title: "",
      productType: "",
      price: "",
      id: "4",
    },
    {
      title: "",
      productType: "",
      price: "",
      id: "5",
    },
  ];

  const [open, setOpen] = useState(false);
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  const handleClick = async (e, assetType, id) => {
    if (e === "MyAsset") {
      // add adding asset to user here
      if (userId) {
        // check upper or lower case for 2d video

        const res = await addAssetToUser({
          content_id: id,
          content_type: type.toLowerCase().includes("2d") ? "2D-video" : type,
        });

        if (res?.data?.response?.statusEnum == 1) {
          setJustAddedToAssets(true);
          setContentType("MyAsset");
        }
      }
    }
    if (e === "Premium") {
      setBuyPopupOpen(true);
      setIsPopupCart(false);
    }
    if (e === "BuyNow") {
      setBuyPopupOpen(true);
      setIsPopupCart(true);
    }
  };
  let options = {
    ...(searchParams.get("type") === "Premium" && { price: "$24.00" }),
    ...(searchParams.get("type") === "BuyNow" && { price: "$99.00" }),
    ...(searchParams.get("type") === "Premium" && { plan: "month" }),
  };

  const [IstooltipHover, setTooltipHover] = useState(false);

  const HandletooltipHover = () => {
    setTooltipHover(!IstooltipHover);
  };

  const [isreportModal, setReportModal] = useState(false);

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };
  const [checkUserAsset, userAssetInfo] = usePostCheckUserAssetsMutation();
  const [checkSubscription, checkSubscriptionInfo] = useCheckUserSubscriptionsMutation();
  async function checkIfOwnedAsset() {
    try {
      const res = await checkUserAsset({
        content_id: id,
      });

      if (res?.data?.response?.statusEnum == 1) {
        // check here if user owns the asset
        const isOwned = res?.data?.response?.data;

        if (isOwned) {
          setContentType("MyAsset");
        }
      }
      if (
        (contentPreference === "subscription" ||
          contentPreference === "premium") &&
        channelProductId
      ) {
        let subscriptionInfo = await checkSubscription({
          channelId: channelProductId,
        })
        if (subscriptionInfo?.data?.response?.statusEnum == 1) {
          // check here if user owns the asset
          const isOwned = subscriptionInfo?.data?.response?.data;
          setChannelSubscribed(isOwned);
        }
      }
    } catch (e) {
      console.error("Error while checking user asset:", e);
    }
  }
  const [addAssetToUser, addAssetToUserInfo] =
    usePostAddFreeAssetForUserMutation();
  useEffect(() => {
    if (isSuccessUser && id) {
      // Make sure id has a value before calling the function
      checkIfOwnedAsset();
    }
  }, [isFetchingUser, data, id]); // Add id to the dependency array

  useEffect(() => {
    refetch();
    if (!getCartItemLoader) {
      setCartItem(GetCartItemQuery);
    }
  }, []);

  // Filter cart array or current content id for checking item added to the cart or not

  // const [currentContentId, setCurrentContentId] = useState(data?.response?.data?._id)

  const addToCart = async () => {
    AddToCartQuery({
      category: data?.response?.data?.category,
      itemId: data?.response?.data?._id,
      currencyType: popupCurrency.toLowerCase() == "usd" ? "usd" : "fstr",
    })
      .then((res) => {
        if (res?.data?.response?.statusEnum === 1) {
          setIsItemAdded(true);
          setContentType("AddToCart");
          setIsItemAddCheck(true);
          // console.log(res.data)
        }
        if (res?.data?.response?.statusEnum === 0) {
          console.log(res?.data?.response?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cartList = GetCartItemQuery?.response?.data?.map(
    (item) => item?.itemId
  );
  // console.log(cartList, data?.response?.data?._id);

  useEffect(() => {
    if (cartList?.includes(data?.response?.data?._id)) {
      setIsItemAddCheck(true);
      setContentType("AddToCart");
    }
  }, [data]);

  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [isPopupCart, setIsPopupCart] = useState(false);
  const [popupCurrency, setPopupCurrency] = useState("fstr");

  const checkout = () => {
    if (popupCurrency == "usd") {
      if (contentType == "Premium") {
        navigate(
          `/checkout?productType=channel&amount=${subscriptionPrice}&currency=usd&productId=${channelProductId}&productName=${channelName}`
        );
      } else {
        navigate(
          `/checkout?productType=${type}&amount=${price}&currency=usd&productId=${data?.response?.data?._id}&productName=${contentName}`
        );
      }
    } else if (popupCurrency == "fstr") {
      if (contentType == "Premium") {
        navigate(
          `/checkoutfstr?productType=channel&amount=${convertActualUsdToFstrPrice(
            subscriptionPrice
          )}&cproductId=${channelProductId}&productName=${channelName}`
        );
      } else {
        navigate(
          `/checkoutfstr?productType=${type}&amount=${convertActualUsdToFstrPrice(
            price
          )}&currency=usd&productId=${
            data?.response?.data?._id
          }&productName=${contentName}`
        );
      }
    }
  };

  const onPopupClose = () => {
    setBuyPopupOpen(false);
  };

  const onPurchaseCurrencyChange = (currency) => {
    setPopupCurrency(currency);
  };

  const onPopupActionHandler = () => {
    // for paid content
    if (contentPreference == "paid") {
      // handle logic for usd currency here
      if (popupCurrency == "usd") {
        // add iap to cart
        if (isPopupCart) {
          addToCart();
        }
        // purchase iap
        else {
          checkout();
        }
      }

      // handle logic for purchasing from wallet here
      else if (popupCurrency == "fstr") {
        // add iap to cart
        if (isPopupCart) {
          addToCart();
        }
        // purchase iap
        else {
          checkout();
        }
      }
    }

    // for subscription
    else {
      // handle logic for usd currency here
      if (popupCurrency == "usd") {
        onPopupClose();
        navigate(
          `/checkout?isSubscription=true&channelId=${channelData?.response?.data?._id}`
        );
      }

      // handle logic for purchasing from wallet here
      else if (popupCurrency == "fstr") {
        onPopupClose();
        navigate(
          `/checkoutfstr?isSubscription=true&channelId=${channelData?.response?.data?._id}`
        );
      }
    }
  };

  return (
    <>
      <div className="px-4 mt-[13px]">
        <div className="__back__section flex justify-between">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back</span>
          </button>

          <div
            onMouseEnter={HandletooltipHover}
            onMouseLeave={HandletooltipHover}
            className="flex justify-center items-center"
          >
            <div
              onClick={() => {
                setReportModal(!isreportModal);
              }}
              className={`__report__tooltip__section transition-all delay-100 ${
                IstooltipHover ? "" : "hidden"
              } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
            >
              Report this content
            </div>
            <Flag
            onClick={() => {
              setReportModal(!isreportModal);
            }}
          />
          </div>
        </div>
      </div>
      {isFetching && !isError && (
        <div className="min-h-[568px] grid place-items-center">
          <Loader />
        </div>
      )}
      {(isError || data?.response?.errorCode != 0) && !isFetching && (
        <div className="h-[360px] w-full grid place-content-center text-white/70">
          Something went wrong
        </div>
      )}
      {isSuccess &&
        !isFetching &&
        !isError &&
        data?.response?.errorCode == 0 && (
          <div className="relative px-4 mb-[140px] max-[768px]:mb-[50px] flex flex-col lg:flex-row py-[16px] max-[1280px]:py-[25px] gap-4">
            <div className="w-[100%] lg:w-[59%]  max-[1024px]:w-auto max-[1024px]:p-0 max-[1280px]:p-0 ">
              <div className=" mb-6 md:mb-[19px] z-10 relative rounded flex justify-center items-center">
                <Zoom>
                  <img
                    src={contentThumbnail}
                    alt="Network Problem"
                    className="h-full w-full object-cover aspect-[826/380]"
                  />
                </Zoom>
                <Zoom delay={500}></Zoom>
              </div>
              <div className="relative">
                <div className="volumetric-card-left-shadow "></div>
                <div className="lg:flex jusitfy-between mb-[21px] relative z-10 gap-10 md:gap-8 lg:gap-14 pt-[36px] max-[768px]:pt-[16px]">
                  <div className="__music__detail__description__main">
                    <div className="__header__main">
                      <span className="font-body font-medium text-[#FFFFFF66] text-[14px]">
                        Description:
                      </span>
                    </div>
                    <div className="__description__main pr-[40px] max-[768px]:p-0">
                      <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                        {contentDescription}
                      </p>
                    </div>

                    <div className="__header__main pt-[30px]">
                      <span className="font-body font-medium text-[#FFFFFF66] text-[14px]">
                        Highlight:
                      </span>
                    </div>
                    {contentHighlights?.map((item, index) => {
                      return (
                        <div
                          className="__description__main pr-[40px] max-[768px]:p-0"
                          key={item._id}
                        >
                          <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                            {item.highlightHeading}
                          </h4>
                          <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                            {item.highlightDescription}
                          </p>
                        </div>
                      );
                    })}
                    {/* <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    witness celestial wonders
                  </h4>
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Celestial melodies by artist will take you on an
                    interstellar journey. Experience her enchanting performance
                    that transcends galaxies. Get ready to be captivated by her
                    celestial talent.
                  </p>
                </div>

                <div className="__header__main pt-[30px]">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    Galactic Soundscapes
                  </h4>
                </div>
                <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Journey through the cosmos with electrifying performances by
                    interstellar musicians. Witness the fusion of music and
                    technology in an otherworldly setting, where soundwaves
                    transcend the boundaries of space.
                  </p>
                </div>

                <div className="__header__main pt-[30px]">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    Dynamic Perspectives
                  </h4>
                </div>
                <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Experience a new level of storytelling with dynamic
                    perspectives. As the viewer, you have control over where to
                    look, allowing you to focus on the details that interest you
                    the most.
                  </p>
                </div> */}
                  </div>
                </div>
                <div className="volumetric-card-right-shadow"></div>
              </div>
            </div>
            <div className=" w-[100%] lg:w-[41%] max-[1024px]:w-auto max-[1024px]:h-auto max-[1024px]:mt-[18px]">
              <div className="__entertainment_assets_heading flex flex-col mb-[16px] lg:mb-[20px] sm:flex justify-between pt-4 px-[30px] max-[768px]:p-0">
                <div className="__heading leading-[40px]">
                  <h4 className="text-[24px] font-heading text-[#ffffff]">
                    {contentName}
                  </h4>
                </div>
                <div className="__creator__main pt-[28px]">
                  <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                    Creator:
                  </h6>
                  {isFetchingChannel && (
                    <div className="min-h-[240px] grid place-content-center">
                      <Loader />
                    </div>
                  )}
                  {(isErrorChannel ||
                    channelData?.response?.errorCode != 0) && (
                    <h2 className="font-semibold mt-4 text-white/70">
                      Channel not found
                    </h2>
                  )}
                  {!isErrorChannel &&
                    !isFetchingChannel &&
                    channelData?.response?.errorCode == 0 && (
                      <Link
                        to={`/marketplace/entertainment/channels/${channelId}`}
                      >
                        <div className="__creator__profiler__main pt-[14px] flex gap-[6px]">
                          <img
                            src={
                              channelThumbnail ||
                              "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/default.jpeg"
                            }
                            className="w-[52px] h-[52px] rounded-[50%]"
                            alt="__creator_profile__img"
                          />
                          <div>
                            <h5 className="text-[16px] font-body font-semibold">
                              {channelName}
                            </h5>
                            <span className="text-[14px] font-body font-medium text-[#FFFFFF99]">
                              @{channelHandle}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )}
                </div>
                <div className="__category__main pt-[28px]">
                  <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                    Category:
                  </h6>
                  <span className="font-body font-medium text-[#fff]">
                    {itemCategory}
                  </span>
                </div>
                <div className="__tags__main pt-[28px]">
                  <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                    Tags:
                  </h6>
                  <span className="text-[16px] font-body font-medium">
                    {tags.join(", ")}
                  </span>
                </div>

                <div className="__buy__main pt-[28px] pr-[40px] max-[768px]:pr-0">
                  {/* <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Buy this Content:
              </h6> */}

                  <div className="flex-1 w-full lg:mt-0 min-h-180">
                    {(userAssetInfo.isLoading ||
                      addAssetToUserInfo.isLoading || 
                      checkSubscriptionInfo.isLoading || !channelProductId) && (
                      <div className="min-h-[160px] grid place-content-center">
                        <Loader />
                      </div>
                    )}
                    {!userAssetInfo.isLoading &&
                      !addAssetToUserInfo.isLoading &&
                      !checkSubscriptionInfo.isLoading && channelProductId && (
                        <BuyCards
                          justAdded={justAddedToAssets || channelSubscribed}
                          isSubscription={contentPreference != "paid"} // Aded afterwords
                          type={contentType}
                          title={contentType != "MyAsset" ? buyCardTitle : null}
                          card="content"
                          price={
                            contentType != "MyAsset"
                              ? "$" +
                                (contentType == "Premium"
                                  ? subscriptionPrice
                                  : price)
                              : null
                          }
                          handleData={(e) => {
                            handleClick(e, type, data?.response?.data?._id);
                          }}
                          onBuyHandle={() => {
                            setIsPopupCart(false);
                            setBuyPopupOpen(true);
                          }}
                          // onBuyHandle={() => setOpen(true)}
                        />
                      )}
                    {buyPopupOpen && (
                      <BuyPopupIap
                        open={buyPopupOpen}
                        onClose={onPopupClose}
                        isCart={isPopupCart}
                        addedToCart={IsItemAdded}
                        loading={addtoCartLoader}
                        onCurrencyChange={onPurchaseCurrencyChange}
                        actionBtnHandler={onPopupActionHandler}
                        proceedToCheckoutHandler={checkout}
                        isSubscription={contentPreference != "paid"}
                        priceUsd={
                          contentType == "Premium" ? subscriptionPrice : price
                        }
                      />
                    )}
                    {contentType == "Premium" && (
                      <>
                        {" "}
                        <UnlockPremium
                          status={open}
                          price={price}
                          text={`Unlock the access exclusive content and early releases curated just for you. Subscribe for <b>$${price}/Month ( $${
                            price * 12
                          }/year )</b>.`}
                          heading="Unlock the Premium"
                          handleData={() => setOpen(false)}
                          handleSub={() => {
                            setContentType("MyAsset");
                            setBuyCardTitle(
                              "You unlocked this content by subscribing to <b style='color:white'>Fourth Star</b> channel"
                            );
                          }}
                        />
                      </>
                    )}

                    {/* use handle sub when actually buying the content */}
                    {contentType == "BuyNow" && (
                      <Popup
                        status={open}
                        text="Buy this content and get lifetime access to it."
                        heading="Buy Content"
                        button={`Buy now @$${price}`}
                        handleData={() => setOpen(false)}
                        handleSub={() => {
                          navigate(
                            `/checkout?productType=${type}&amount=${price}&currency=${data?.response?.data?.currency}&productId=${data?.response?.data?._id}&productName=${contentName}`
                          );
                        }}
                      />
                    )}

                    <ComingSoon
                      status={showComingSoonPopup}
                      text={comingSoonText}
                      handleData={() => {
                        setShowComingSoonPopup(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-1  gap-4 ">Content</div> */}
            </div>
          </div>
        )}

      <div className="__sneaky__peek__container px-4 ">
        <div className="__heading">
          <SliderHeader title="Sneaky Peek" />
        </div>
        <div className="__content__slider pt-[24px]">
          <SneakyPeak
            slider={sneakPeakImages}
            SlideToShow={3}
            type="trending"
          ></SneakyPeak>
        </div>
      </div>

      {/* Divider */}

      <hr className="border-[0.1px] border-[#5A5A5A80] my-[100px]" />

      <div className="__suggested__video__container mb-[90px] px-4">
        <div className="__heading">
          <SliderHeader title={`Suggested ${itemCategory}`} />
        </div>
        <div className="__content__slider pt-[24px]">
          {/* <SneakyPeak
            slider={mostTrending}
            SlideToShow={3}
            type="trending"
          ></SneakyPeak> */}
          {recommendedContentData && !isFetchingRecommendedContent && (
            <LargeCarousel
              slider={recommendedContentData}
              isSuggestedContent={true}
              type="trending"
            ></LargeCarousel>
          )}
          {isFetchingRecommendedContent && (
            <div className="w-full min-h[320px] grid place-content-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
      {isreportModal ? (
        <AssetsReportModal
          handleModalStatus={handleCloseReportModal}
          contentType={type?.toLowerCase()}
          idToReport={id}
        />
      ) : (
        <></>
      )}

      {/* {IsItemAdded && (
        <Alert
          open={true}
          onClose={() => {
            setIsItemAdded(!IsItemAdded);
          }}
          heading="Item Added to the Cart"
          message=""
          isSuccess={true}
        />
      )} */}
    </>
  );
};

export default Index;
