/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Select-Preference-Page
 *@developer Shubham K
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Loader from "components/Loader/Loader";
import {
  useUpdateUserPreferenceByTokenMutation,
  useGetUserPreferenceByTokenQuery,
} from "store/slicer/userProfile";
import { Preferencelist } from "constant/index.js";
import Alert from "components/popup/Alert/Alert";

const SelectUserPreferences = () => {
  const [allPreferences, setAllPreferences] = useState(null);
  const [prefLoaded, setPrefLoaded] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  const [submitUserPref, submitUserPrefInfo] =
    useUpdateUserPreferenceByTokenMutation();

  let {
    data: fetchedPreferences = [],
    isFetching,
    isSuccess = false,
    isError,
    error,
  } = useGetUserPreferenceByTokenQuery();

  // fetching user pref and storing in state
  useEffect(() => {
    if (
      !isFetching &&
      Object.keys(fetchedPreferences).length == 0 &&
      (isError || fetchedPreferences?.response?.statusEnum != 1)
    ) {
      console.log(fetchedPreferences);
      setAlertMessage("Something went wrong");
      setSuccessAlert(false);
    }
    if (
      !isFetching &&
      isSuccess &&
      fetchedPreferences?.response?.statusEnum == 1
    ) {
      fetchedPreferences = fetchedPreferences?.response?.data?.preference;
      if (Object.keys(fetchedPreferences).length > 0) {
        let prefState = {
          ...fetchedPreferences,
        };
        setAllPreferences(prefState);
        setPrefLoaded(true);
      }
    }
  }, [isSuccess, isFetching]);

  // checking if form is valid
  useEffect(() => {
    if (allPreferences) {
      let valid = false;

      const keys = Object.keys(allPreferences);

      for (let i = 0; i < keys.length; i++) {
        if (allPreferences[keys[i]] == 1) {
          valid = true;
          break;
        }
      }
      setIsFormValid(valid);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    const res = await submitUserPref(allPreferences);

    if (res?.data?.response?.statusEnum == 1) {
      navigate("/add-tags");
    } else {
      setAlertMessage("Something went wrong");
      setSuccessAlert(false);
    }
  };

  const onChangeInput = (e) => {
    const statekey = e.target.name;
    let stateValue;
    const checked = e.target.checked;

    if (allPreferences[statekey] == 0) {
      stateValue = 1;
    } else {
      stateValue = 0;
    }

    let newState = {
      ...allPreferences,
      [statekey]: checked ? 1 : 0,
    };

    setAllPreferences(newState);
  };

  return (
    <>
      <Box className="w-full max-w-[768px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            Let's Setup Your Account
          </h1>

          <div className="relative flex justify-between items-center mb-[36px] px-0 w-full md:w-3/5 mx-auto">
            <div className="absolute bottom-[53px] left-[35px] h-[2px] w-[calc(50%-35px)] bg-[#FBBC5E] z-0"></div>
            <div className="absolute bottom-[53px] right-[35px] h-[2px] w-[calc(50%-35px)] bg-[#545454] z-0"></div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-black h-8 w-8 bg-[#FBBC5E] rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                1
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Details
              </p>
            </div>

            <div className="flex flex-col justify-between items-center min-w-[70px] h-[70px] z-10">
              <div className="text-black bg-[#FBBC5E] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                2
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Select Preferences
              </p>
            </div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-white bg-[#545454] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                3
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Tags
              </p>
            </div>
          </div>

          {isSuccess && prefLoaded && (
            <form className="auth-form mb-5 w-full" onSubmit={handleSubmit}>
              <h2 className="mb-[32px] text-[18px] font-body font-medium md:pl-10">
                Select Preferences
              </h2>

              <div className="grid grid-cols-2 gap-x-10 lg:gap-x-20 md:pl-10 mb-[50px]">
                {Object.keys(allPreferences).map((item) => {
                  let foundKeyValue = Preferencelist.find(
                    (i) => i?.key == item
                  );
                  // check if this key exists in our key value mapping
                  if (foundKeyValue != null) {
                    return (
                      <div key={item} className="flex items-center mb-5">
                        <div className="relative w-6 h-6 max-[768px]:w-4 max-[768px]:h-4">
                          <input
                            className="w-full h-full peer opacity-0 accent-[#FBBC5E]"
                            type="checkbox"
                            name={item}
                            id={`checkbox-${item}`}
                            defaultChecked={allPreferences[item] == 1}
                            onClick={onChangeInput}
                          />
                          <div className="absolute top-0 left-0 w-full h-full rounded-sm lg:rounded-md border-2 border-white peer-checked:border-0 peer-checked:bg-[#FBBC5E]  pointer-events-none"></div>
                          <span className="absolute top-0 left-0 w-full h-full opacity-0 peer-checked:opacity-100 grid place-content-center pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M2.75 8.75L6.25 12.25L13.25 4.75"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        {/* <input
                            className="w-6 h-6 max-[768px]:w-4 max-[768px]:h-4 accent-[#FBBC5E] bg-red-300 rounded-sm"
                            type="checkbox"
                            name={item}
                            id={`checkbox-${item}`}
                            defaultChecked={allPreferences[item] == 1}
                            onClick={onChangeInput}
                          /> */}

                        <label
                          className="text-[16px] font-thin font-body pl-[12px] max-[400px]:text-[8px] max-[768px]:text-[12px] max-[768px]:pl-[6px]"
                          htmlFor={`checkbox-${item}`}
                        >
                          {foundKeyValue.name}
                        </label>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="flex justify-end px-2">
                <button
                  className="py-3 px-7 border-[#fbbc5e] border-[2px] rounded-lg font-body font-bold"
                  disabled={submitUserPrefInfo.isLoading}
                  onClick={() => {
                    navigate("/add-details");
                  }}
                >
                  Back
                </button>
                <button
                  className="ml-4 font-bold rounded-lg btn-gradient text-black py-3 px-5 flex justify-center items-center"
                  type="submit"
                  disabled={submitUserPrefInfo.isLoading || !isFormValid}
                >
                  {submitUserPrefInfo.isLoading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    "Save and Next"
                  )}
                </button>
              </div>
            </form>
          )}
          {isFetching && (
            <div className="min-h-[360px] grid place-content-center">
              <Loader />
            </div>
          )}
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default SelectUserPreferences;
