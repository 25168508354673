/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Ships-Reducer-functions
 *@developer Aditya P
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

export const ShipSlice = createApi({
  reducerPath: "ship",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    GetAllShip: builder.query({
      query: ({ limit, page }) => ({
        url: EndpointsSlug.GET_ALL_SHIPS,
        method: "GET",
        params: { limit, page },
      }),
    }),
    GetSingleShip: builder.query({
      query: (payload = "") => ({
        url: EndpointsSlug.GET_SINGLE_SHIP + "/" + payload,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllShipQuery, useGetSingleShipQuery } = ShipSlice;
