/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module SingUp
 *@developer Sudhanshu <sudhanshut@instaacoders.com>
 */

import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { isValidUsername } from "utils";
import Alert from "components/popup/Alert/Alert";
import {
  useValidateUsernameMutation,
  useUpdateUsernameWithGenderAndDobMutation,
} from "store/slicer/userProfile";
import dayjs from "dayjs";

import dateFormat from "dateformat";
import { Calendar as HyperCalendar } from "react-date-range";
import "assets/css/hyperxcalendarstyles.css"; // main style file
import "assets/css/hyperxcalendartheme.css"; // theme css file
import { isValidDateValue } from "@testing-library/user-event/dist/utils";

/* This code is a React component for user registration with the option to switch between "User" and "Creator" accounts. It performs real-time validation for name, email, and password, including password strength checks. Users can toggle the visibility of the password, and the form is enabled for submission when all requirements are met.
 */
const AddDetails = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to update screen size
  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  useEffect(() => {
    // Add event listener for resize event
    window.addEventListener("resize", updateScreenSize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, [screenSize.width]);
  const [formData, setFormData] = useState({
    userName: "",
    gender: "",
    dob: "",
  });
  const [formErrors, setFormErrors] = useState({
    userName: "",
    gender: "",
    dob: "",
  });
  const isRequired = (value) => (value === "" ? false : true);

  // Function to validate the form data
  const validateForm = async () => {
    if (!isRequired(formData.userName)) {
      setFormErrors({
        ...formErrors,
        userName: "UserName is required",
      });
    } else if (!isValidUsername(formData.userName)) {
      setFormErrors({
        ...formErrors,
        userName: "Username must only contain letters and numbers",
      });
    } else {
      const payload = {
        username: formData.userName,
      };
      const res = await ValidateUsername(payload);

      // if(res?.data?.response?.statusEnum == 2){
      //   const errMessage = res?.data?.response?.message;
      //   if(errMessage){
      //     setFormErrors({
      //       ...formErrors,
      //       "userName": errMessage,
      //     });
      //   }
      //   setIsValid(false);
      // }

      if (res?.data?.response?.statusEnum != 1) {
        const sets = res?.data?.response?.data?.sets;
        const filteredSet = sets?.filter((set) => set.key == "username");
        const errObj = filteredSet?.length && filteredSet[0];
        const errMessage = errObj?.message || res?.data?.response?.message;
        if (errMessage) {
          setFormErrors({
            ...formErrors,
            userName: errMessage,
          });
        }
      }
    }
    const boolName = isValidUsername(formData.userName);
    const boolGender = checkGender(selectedGender);
    const boolCalenderDate = checkCalenderDate(selectedDate);
    if (boolGender && boolCalenderDate && boolName) {
      return true;
    } else return false;
  };

  const checkGender = (selectedGender) => {
    if (selectedGender === "-Select-") {
      setgenderError(true);
      return false;
    } else {
      setgenderError(false);
      return true;
    }
  };
  const checkCalenderDate = (selectedGender) => {
    if (selectedDate === "MM/DD/YYYY") {
      setDOBError("Please select DOB");
      return false;
    } else {
      setDOBError(null);
      return true;
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim()) {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };
  const [
    UpdateUsernameWithGenderAndDob,
    { data, isLoading, isError, error, isSuccess },
  ] = useUpdateUsernameWithGenderAndDobMutation();
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsValid(false);
      UpdateUsernameWithGenderAndDob(formData);
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data);
      if (data?.response?.statusEnum === 1) {
        setAlertMessage(data?.response?.message);
        setAlertHeading("Changes Saved!");
        setSuccessAlert(true);
        setTimeout(() => {
          setselectedDate("MM/DD/YYYY");
          setselectedGender("-Select-");
          setFormData({
            userName: "",
            gender: "",
            dob: "",
          });
          // navigate("/me");
          navigate("/select-preferences");
        }, 2000);
      } else if (data?.response?.statusEnum === 2) {
        // make a warning alert popup for here if needed
        return;
      } else {
        setAlertMessage(data?.response?.message);
        setSuccessAlert(false);
      }
    }
    if (isError) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
  }, [data, isError, isSuccess]);

  const [
    ValidateUsername,
    {
      isLoading: is_loading,
      isError: is_Error,
      error: u_error,
      isSuccess: is_Success,
    },
  ] = useValidateUsernameMutation();
  const [isValid, setIsValid] = useState(false);
  const [userNameValid, setUserNameValid] = useState(false);
  useEffect(() => {
    // if (is_Success) {
    //   setIsValid(true);
    // } else {
    //   setIsValid(false)
    // }

    if (is_Success && userNameValid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [is_Success, is_loading, userNameValid]);
  // checkName
  const checkName = async (value) => {
    let valid = true;
    const fieldInput = value?.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        userName: "UserName is required",
      });
      valid = false;
    } else if (!isValidUsername(fieldInput)) {
      setFormErrors({
        ...formErrors,
        userName: "Username must only contain letters and numbers",
      });
      valid = false;
    } else {
      const payload = {
        username: formData.userName,
      };
      const res = await ValidateUsername(payload);

      // if(res?.data?.response?.statusEnum == 2){
      //   const errMessage = res?.data?.response?.message;
      //   if(errMessage){
      //     setFormErrors({
      //       ...formErrors,
      //       "userName": errMessage,
      //     });
      //   }
      //   valid = false;
      // }

      if (res?.data?.response?.statusEnum != 1) {
        const sets = res?.data?.response?.data?.sets;
        const filteredSet = sets?.filter((set) => set.key == "username");
        const errObj = filteredSet?.length && filteredSet[0];
        const errMessage = errObj?.message || res?.data?.response?.message;
        if (errMessage) {
          setFormErrors({
            ...formErrors,
            userName: errMessage,
          });
        }

        valid = false;
      }
    }
    setUserNameValid(valid);
    return valid;
  };

  const [userGuideLines, setuserGuideLines] = useState(false);

  const toggleUserGuidelines = (e) => {
    setuserGuideLines(!userGuideLines);
  };
  const [Calender, setCalender] = useState(false);

  const toggleCalender = (e) => {
    setCalender(!Calender);
  };

  const [selectedGender, setselectedGender] = useState("-Select-");
  const handleGender = (e) => {
    // console.log("🚀 ~ file: Index.jsx:193 ~ handleGender ~ e:", e);
    setGender(!Gender);
    setgenderError(false);
    setselectedGender(e);
    setFormData({
      ...formData,
      gender: e,
    });
  };

  const [Gender, setGender] = useState(false);
  const toggleGender = (e) => {
    setGender(!Gender);
  };

  const [focus, setFocus] = useState({
    username: false,
    dob: false,
    gender: false,
  });

  const userGuideLineRef = useRef(null);
  const dobRef = useRef(null);
  const genderDropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dobRef.current && !dobRef.current.contains(event.target)) {
        setCalender(false);
      }

      if (
        genderDropdownRef.current &&
        !genderDropdownRef.current.contains(event.target)
      ) {
        setGender(false);
      }
      if (
        userGuideLineRef.current &&
        !userGuideLineRef.current.contains(event.target)
      ) {
        setuserGuideLines(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [selectedDate, setselectedDate] = useState("MM/DD/YYYY");

  const [currentCalendarView, setCurrentCalendarView] = useState("year");

  const [selectedDay, setSelctedDay] = useState("01");
  const [selectedMonth, setSelectedMonth] = useState("01");
  const [selectedYear, setSelectedYear] = useState("2000");

  // const handleDate = (value) => {
  //   setCalender(false);
  //   const dateString = value.format();
  //   console.log("date string",dateString);
  //   var ToDate = new Date();
  //   if (new Date(dateString).getTime() > ToDate.getTime()) {
  //     setDOBError("Date of Birth must be less than today date");
  //     return
  //   }
  //   // Split into date and time
  //   const [datePart, timePart] = dateString.split("T");
  //   // Split the date into day, month, and year
  //   const [year, month, day] = datePart.split("-");
  //   // Create the desired format (dd/mm/yyyy)
  //   const formattedDate = `${month}/${day}/${year}`;
  //   setDOBError(null)
  //   setselectedDate(formattedDate);
  //   // console.log(formattedDate); // Output: 11/11/2023
  //   setFormData({
  //     ...formData,
  //     dob: formattedDate,
  //   })

  // };
  const handleDate = (value) => {
    setCurrentCalendarView("year");
    setCalender(false);
    let dateString = value.format();
    const chosenDate = dateString.split("T")[0].split("-")[2];
    setSelctedDay(chosenDate);
    var ToDate = new Date();
    if (
      new Date(`${selectedYear}-${selectedMonth}-${chosenDate}`).getTime() >
      ToDate.getTime()
    ) {
      setDOBError("Date of Birth must be less than today date");
      console.log("test");
      return;
    }
    // Split into date and time
    // const [datePart, timePart] = dateString.split("T");
    // // Split the date into day, month, and year
    // const [year, month, day] = datePart.split("-");
    const year = selectedYear;
    const month = selectedMonth;
    const day = chosenDate;
    // Create the desired format (dd/mm/yyyy)
    const formattedDate = `${month}/${day}/${year}`;
    console.log("formatte date", formattedDate);
    setDOBError(null);
    setselectedDate(formattedDate);
    // console.log(formattedDate); // Output: 11/11/2023
    setFormData({
      ...formData,
      dob: formattedDate,
    });
  };

  const onCalendarMonthChange = (value) => {
    setCalender(true);
    setCurrentCalendarView("day");
    const dateString = value.format();
    const month = dateString.split("T")[0].split("-")[1];
    const year = dateString.split("T")[0].split("-")[0];

    const ToDate = new Date();
    if (new Date(`${year}-${month}-01`).getTime() > ToDate.getTime()) {
      setDOBError("Date of Birth must be less than today date");
      setSelctedDay("01");
      setSelectedMonth("1");
      setSelectedYear("2000");
      return;
    }

    // need to change both day and year as well, because changing month can change the year, and different months have different number of days
    setDOBError(null);
    setSelctedDay("01");
    setSelectedMonth(month);
    setSelectedYear(year);
    setselectedDate(`${month}/01/${year}`);
  };

  const onCalendarYearChange = (value) => {
    setCalender(true);
    setCurrentCalendarView("month");
    const dateString = value.format();
    const year = dateString.split("T")[0].split("-")[0];

    const ToDate = new Date();
    if (year > ToDate.getFullYear()) {
      setDOBError("Date of Birth must be less than today date");
      setSelectedYear("2000");
      return;
    }

    setDOBError(null);
    setSelectedYear(year);
    setselectedDate(`${selectedMonth}/${selectedDay}/${year}`);
  };

  const [genderError, setgenderError] = useState(false);
  const [DOBError, setDOBError] = useState("");

  const isDateValid = (dateStr) => {
    return !isNaN(new Date(dateStr));
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const calendarContainerRef = useRef(null);

  // this will close the calendar if we click outside it
  useEffect(() => {
    const handleClickOutsideCalendar = (e) => {
      if (
        e.target != calendarContainerRef.current &&
        !calendarContainerRef.current?.contains(e.target) &&
        !e.target.closest(".rdrCalendarWrapper")
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("click", handleClickOutsideCalendar);

    return () =>
      document.removeEventListener("click", handleClickOutsideCalendar);
  }, []);

  return (
    <>
      <Box className="w-full max-w-[768px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            Let's Setup Your Account
          </h1>

          <div className="relative flex justify-between items-center mb-[36px] px-0 w-full md:w-3/5 mx-auto">
            <div className="absolute bottom-[53px] left-[35px] h-[2px] w-[calc(50%-35px)] bg-[#545454] z-0"></div>
            <div className="absolute bottom-[53px] right-[35px] h-[2px] w-[calc(50%-35px)] bg-[#545454] z-0"></div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-black h-8 w-8 bg-[#FBBC5E] rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                1
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Details
              </p>
            </div>

            <div className="flex flex-col justify-between items-center min-w-[70px] h-[70px] z-10">
              <div className="text-white bg-[#545454] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                2
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Select Preferences
              </p>
            </div>

            <div className="flex flex-col justify-between items-center w-[70px] h-[70px] z-10">
              <div className="text-white bg-[#545454] h-8 w-8  rounded-full grid place-content-center text-[16px] font-bold font-Inter">
                3
              </div>
              <p className="text-white text-center font-normal mt-1 text-[8px] whitespace-nowrap md:text-[12px] font-body">
                Add Tags
              </p>
            </div>
          </div>

          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="font-medium text-white/[.80] text-sm"
                data-testid="Username"
              >
                Username
              </label>
              <div className="mt-2 relative" ref={userGuideLineRef}>
                <input
                  type="text"
                  placeholder="eg. John123"
                  name="userName"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#FBBC5E] font-normal py-3 px-5 leading-normal font-semibold outline-none ${
                    formErrors.userName ? "!border-error" : ""
                  } ${focus ? "" : ""}`}
                  id="userName"
                  value={formData.userName}
                  onChange={handleInputChange}
                  onBlur={(e) => checkName(e.target.value)}
                />
                {is_loading ? (
                  <span className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                ) : (
                  <>
                    <span
                      className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                      onClick={(e) => toggleUserGuidelines(e)}
                      onMouseEnter={(e) => toggleUserGuidelines(e)}
                      onMouseLeave={(e) => toggleUserGuidelines(e)}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="0.75"
                          width="20.5"
                          height="20.5"
                          rx="10.25"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M10.4995 18V9.456H12.4995V18H10.4995ZM11.5075 8.048C11.1342 8.048 10.8248 7.93067 10.5795 7.696C10.3448 7.46133 10.2275 7.17867 10.2275 6.848C10.2275 6.50667 10.3448 6.224 10.5795 6C10.8248 5.76533 11.1342 5.648 11.5075 5.648C11.8808 5.648 12.1848 5.76 12.4195 5.984C12.6648 6.19733 12.7875 6.46933 12.7875 6.8C12.7875 7.152 12.6702 7.45067 12.4355 7.696C12.2008 7.93067 11.8915 8.048 11.5075 8.048Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    {userGuideLines && (
                      <div
                        className={`notification-dropdown popover absolute ${
                          screenSize.width > 1226
                            ? "top-4 right-1"
                            : "top-8 right-4 rotate-90"
                        } delay-200 transition`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="23"
                          viewBox="0 0 20 23"
                          fill="none"
                        >
                          <path
                            d="M19.6752 0.847325L0.643729 11.8411L19.6803 22.826L19.6752 0.847325Z"
                            fill="#716046"
                          />
                        </svg>
                      </div>
                    )}
                    {userGuideLines && (
                      <div
                        data-testid="Username"
                        onBlur={toggleUserGuidelines}
                        className={`absolute ${
                          screenSize.width > 1226
                            ? "top-0 -right-[350px]"
                            : "-right-[0px]"
                        } p-5 max-w-[357px] overflow-hidden z-[1] rounded-md bg-[#716046] backdrop-blur-sm`}
                      >
                        <p className="text-[14px] text-[#FBBC5E] font-bold pb-[9px]">
                          Username Guidelines
                        </p>
                        <ul className="pl-5">
                          <li className="text-[14px] font-semibold list-disc">
                            There should be no spaces.
                          </li>
                          <li className="text-[14px] font-semibold list-disc">
                            Username should not start with a number.
                          </li>
                          <li className="text-[14px] font-semibold list-disc">
                            Username should only contain alphabets and numbers.
                          </li>
                          <li className="text-[14px] font-semibold list-disc">
                            Username should not contain any special character.
                          </li>
                        </ul>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>

              {formErrors.userName && (
                <span className="text-error text-sm">
                  {formErrors.userName}
                </span>
              )}

              {is_Error && (
                <span className="text-error text-sm">{u_error}</span>
              )}

              {isValid && (
                <span className="text-[#25B39E] text-sm flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.5003 5.83516L7.50033 15.8352L2.91699 11.2518L4.09199 10.0768L7.50033 13.4768L16.3253 4.66016L17.5003 5.83516Z"
                      fill="currentColor"
                    />
                  </svg>
                  Username available
                </span>
              )}
            </div>

            <div className="mb-4">
              <p
                className="font-medium text-white/[.80] text-sm"
                data-testid="Date Of Birth"
              >
                Date Of Birth
              </p>

              <div className="mt-2 flex relative" ref={calendarContainerRef}>
                <div
                  className={`rounded-lg cursor-pointer w-full remove-icon bg-transparent border border-white focus:border-[#FBBC5E] font-normal py-3 px-5 leading-normal font-semibold outline-none ${
                    DOBError ? "!border-error" : ""
                  }`}
                  onClick={(e) => setShowCalendar(true)}
                >
                  <div>
                    {" "}
                    {isDateValid(selectedDate?.toString()) &&
                      dateFormat(new Date(selectedDate), "mm/dd/yyyy")}
                    {!isDateValid(selectedDate?.toString()) && "MM/DD/YYYY"}
                  </div>
                  <span
                    data-testid="PasswordVisibility"
                    className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 4H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 10H4V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H7V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8C8.26522 8 8.51957 7.89464 8.70711 7.70711C8.89464 7.51957 9 7.26522 9 7V6H15V7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V10Z"
                        fill={`${focus.dob ? "#FBBC5E" : "white"}`}
                      />
                    </svg>
                  </span>
                </div>
                {showCalendar && (
                  <div className="w-fit absolute top-full left-0 z-10">
                    <HyperCalendar
                      minDate={new Date("01/01/1900")}
                      maxDate={new Date()}
                      onChange={(date) => {
                        setselectedDate(
                          dateFormat(new Date(date)),
                          "mm/dd/yyyy"
                        );
                        setFormData((data) => {
                          return {
                            ...data,
                            dob: date,
                          };
                        });
                      }}
                      date={
                        isDateValid(new Date(selectedDate))
                          ? new Date(selectedDate)
                          : null
                      }
                    />
                  </div>
                )}
                {/* {Calender && (
                  <div className="absolute bg-white text-black top-[51px] z-[4]">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        view={currentCalendarView}
                        disableFuture
                        defaultValue={dayjs("01/01/2000")}
                        value={dayjs(
                          `${selectedYear}/${selectedMonth}/${selectedDay}`
                        )}
                        onChange={(newValue) => handleDate(newValue)}
                        onMonthChange={onCalendarMonthChange}
                        onYearChange={onCalendarYearChange}
                      />
                    </LocalizationProvider>
                  </div>
                )} */}
              </div>
              {DOBError && (
                <span className="text-error text-sm">{DOBError}</span>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="Gender"
                className="font-medium text-white/[.80] text-sm"
                data-testid="Gender"
              >
                Gender
              </label>
              <div className="mt-2 flex relative date" ref={genderDropdownRef}>
                <div
                  className={`rounded-lg cursor-pointer w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] ${
                    genderError ? "!border-error" : ""
                  }`}
                  onClick={(e) => toggleGender(e)}
                >
                  {selectedGender}
                </div>

                <span
                  data-testid="Gender"
                  className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  onClick={(e) => toggleGender(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 8L12 15L5 8"
                      stroke="white"
                      strokeidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                {Gender && (
                  <div className="absolute w-full top-14 rounded-lg border border-[#FBBC5E] bg-[#332e28] z-[3] overflow-hidden">
                    <div
                      className={`w-full bg-transparent focus:border-[#f6f6f6] hover:bg-[#FBBC5E] hover:bg-opacity-10 hover:text-[#FBBC5E] cursor-pointer font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] ${
                        formErrors.password ? "!border-error" : ""
                      }`}
                      onClick={() => {
                        handleGender("Male");
                      }}
                    >
                      Male
                    </div>
                    <div
                      className={` w-full bg-transparent focus:border-[#f6f6f6] hover:bg-[#FBBC5E] hover:bg-opacity-10  hover:text-[#FBBC5E] cursor-pointer font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] ${
                        formErrors.password ? "!border-error" : ""
                      }`}
                      onClick={() => {
                        handleGender("Female");
                      }}
                    >
                      Female
                    </div>
                    <div
                      className={` w-full bg-transparent focus:border-[#f6f6f6] hover:bg-[#FBBC5E] hover:bg-opacity-10 hover:text-[#FBBC5E] cursor-pointer font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] ${
                        formErrors.password ? "!border-error" : ""
                      }`}
                      onClick={() => {
                        handleGender("Others");
                      }}
                    >
                      Other
                    </div>
                  </div>
                )}
              </div>
              {genderError && (
                <span className="text-error text-sm">Please select gender</span>
              )}
            </div>

            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 flex justify-center"
              disabled={isLoading || is_loading}
              // disabled={!isFormValid()}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Save and Next"
              )}
            </button>
          </form>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default AddDetails;
