/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Channel-Subscription-Item
 *@developer Shubham K
 */

import React from "react";
import defaultChannelLogo from "assets/images/channel.png";
import { Link } from "react-router-dom";

const ChannelItem = ({
  id,
  channelThumb,
  channelName,
  channelHandle,
  channelId,
  subscriptionStatus,
  paymentMethod,
  price,
  lastPaymentStatus,
  renewalDate,
  dueDate,
  expiredDate,
  cancelled = false,
  seeAllTransactionsHandler,
  cancelBtnHandler,
  payRenewBtnHandler,
}) => {
  return (
    <div className="flex justify-start items-start md:items-center [&>*]:h-full [&>*]:w-1/4 [&>*]:pr-1 pt-6 pb-6 lg:pb-12 border-b border-b-[#7a7a7a]/50">
      <Link
        to={`/marketplace/entertainments/channels/${channelHandle}`}
        className="__CHANNEL_THUMB_NAME_CONT flex justify-start items-start md:items-center gap-3"
      >
        <img
          src={channelThumb}
          alt="channelthumb"
          className="h-[64px] w-[64px] hidden md:block rounded-full object-cover"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultChannelLogo;
          }}
        />
        <div className="h-full flex flex-col items-start justify-center gap-1 text-ellipsis overflow-hidden">
          <p className="font-semibold text-[12px] md:text-[14px] leading-[21px]">
            {channelName}
          </p>
          <p className="font-body font-medium text-[12px] md:text-[14px] leading-[17.07px] text-white/60 break-all">
            @{channelHandle}
          </p>
        </div>
      </Link>
      <div className="__SUBCR_DETAILS_CONT__ flex flex-col justify-start items-start gap-2">
        <p
          className={`font-semibold text-[12px] md:text-[14px] leading-[17.07px] font-body capitalize ${
            subscriptionStatus?.toLowerCase() == "active"
              ? "text-white"
              : "text-[#FF9393]"
          }`}
        >
          {subscriptionStatus}
        </p>
        <p className="font-normal text-[12px] md:text-[14px] leading-[17.07px] font-body text-white">
          Payment Method:{" "}
          {paymentMethod?.toLowerCase() == "usd" ? "USD" : "FSTR"}
        </p>
        <p className="font-normal text-[12px] md:text-[14px] leading-[17.07px] font-body text-white">
          Price: {price}{" "}
          {paymentMethod?.toLowerCase() == "usd" ? "USD" : "FSTR"}
        </p>
      </div>
      <div className="__DEBIT_STATUS_CONT__ flex flex-col justify-start items-start gap-2">
        {!cancelled && (
          <>
            {" "}
            {lastPaymentStatus?.toLowerCase() == "success" && subscriptionStatus?.toLowerCase() == "active" && (
              <p className="font-normal text-[12px] md:text-[14px] leading-[17.07px] font-body text-[#25B39E]">
                Payment Successful
              </p>
            )}
            {(lastPaymentStatus?.toLowerCase() == "failed" || subscriptionStatus?.toLowerCase() == "failed") && (
                <p className="font-normal text-[12px] md:text-[14px] leading-[17.07px] font-body text-[#EC4134]">
                  <svg
                    className="inline mr-1 mb-[2px]"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_159_11555)">
                      <path
                        d="M8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398ZM8.00065 2.66732C6.58616 2.66732 5.22961 3.22922 4.22941 4.22941C3.22922 5.22961 2.66732 6.58616 2.66732 8.00065C2.66732 9.41514 3.22922 10.7717 4.22941 11.7719C5.22961 12.7721 6.58616 13.334 8.00065 13.334C9.41514 13.334 10.7717 12.7721 11.7719 11.7719C12.7721 10.7717 13.334 9.41514 13.334 8.00065C13.334 6.58616 12.7721 5.22961 11.7719 4.22941C10.7717 3.22922 9.41514 2.66732 8.00065 2.66732ZM8.00065 10.0007C8.17746 10.0007 8.34703 10.0709 8.47205 10.1959C8.59708 10.3209 8.66732 10.4905 8.66732 10.6673C8.66732 10.8441 8.59708 11.0137 8.47205 11.1387C8.34703 11.2637 8.17746 11.334 8.00065 11.334C7.82384 11.334 7.65427 11.2637 7.52925 11.1387C7.40422 11.0137 7.33398 10.8441 7.33398 10.6673C7.33398 10.4905 7.40422 10.3209 7.52925 10.1959C7.65427 10.0709 7.82384 10.0007 8.00065 10.0007ZM8.00065 4.00065C8.17746 4.00065 8.34703 4.07089 8.47205 4.19591C8.59708 4.32094 8.66732 4.49051 8.66732 4.66732V8.66732C8.66732 8.84413 8.59708 9.0137 8.47205 9.13872C8.34703 9.26375 8.17746 9.33398 8.00065 9.33398C7.82384 9.33398 7.65427 9.26375 7.52925 9.13872C7.40422 9.0137 7.33398 8.84413 7.33398 8.66732V4.66732C7.33398 4.49051 7.40422 4.32094 7.52925 4.19591C7.65427 4.07089 7.82384 4.00065 8.00065 4.00065Z"
                        fill="#EC4134"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_159_11555">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Payment Failed
                </p>
              )}
            {lastPaymentStatus?.toLowerCase() != "success" &&
              subscriptionStatus?.toLowerCase() == "onhold" && (
                <p className="font-normal text-[12px] md:text-[14px] leading-[17.07px] font-body text-[#EC4134]">
                  <svg
                    className="inline mr-1 mb-[2px]"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_159_11555)">
                      <path
                        d="M8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398ZM8.00065 2.66732C6.58616 2.66732 5.22961 3.22922 4.22941 4.22941C3.22922 5.22961 2.66732 6.58616 2.66732 8.00065C2.66732 9.41514 3.22922 10.7717 4.22941 11.7719C5.22961 12.7721 6.58616 13.334 8.00065 13.334C9.41514 13.334 10.7717 12.7721 11.7719 11.7719C12.7721 10.7717 13.334 9.41514 13.334 8.00065C13.334 6.58616 12.7721 5.22961 11.7719 4.22941C10.7717 3.22922 9.41514 2.66732 8.00065 2.66732ZM8.00065 10.0007C8.17746 10.0007 8.34703 10.0709 8.47205 10.1959C8.59708 10.3209 8.66732 10.4905 8.66732 10.6673C8.66732 10.8441 8.59708 11.0137 8.47205 11.1387C8.34703 11.2637 8.17746 11.334 8.00065 11.334C7.82384 11.334 7.65427 11.2637 7.52925 11.1387C7.40422 11.0137 7.33398 10.8441 7.33398 10.6673C7.33398 10.4905 7.40422 10.3209 7.52925 10.1959C7.65427 10.0709 7.82384 10.0007 8.00065 10.0007ZM8.00065 4.00065C8.17746 4.00065 8.34703 4.07089 8.47205 4.19591C8.59708 4.32094 8.66732 4.49051 8.66732 4.66732V8.66732C8.66732 8.84413 8.59708 9.0137 8.47205 9.13872C8.34703 9.26375 8.17746 9.33398 8.00065 9.33398C7.82384 9.33398 7.65427 9.26375 7.52925 9.13872C7.40422 9.0137 7.33398 8.84413 7.33398 8.66732V4.66732C7.33398 4.49051 7.40422 4.32094 7.52925 4.19591C7.65427 4.07089 7.82384 4.00065 8.00065 4.00065Z"
                        fill="#EC4134"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_159_11555">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Payment not completed
                </p>
              )}
            <p
              className="font-semibold text-[12px] md:text-[14px] leading-[17.07px] text-white cursor-pointer hover:text-white/80"
              onClick={() => seeAllTransactionsHandler(channelId)}
            >
              See all Transactions
            </p>
            {lastPaymentStatus?.toLowerCase() == "success" && subscriptionStatus?.toLowerCase() == "active" && (
              <p className="text-[12px] md:text-[14px] leading-[17.07px] text-white/50">
                Next renewal date: {renewalDate}
              </p>
            )}
            {lastPaymentStatus?.toLowerCase() == "failed" &&
              subscriptionStatus?.toLowerCase() == "failed" && (
                <p className="text-[12px] md:text-[14px] leading-[17.07px] text-[#EC4134]">
                  Due date: {dueDate}
                </p>
              )}
            {(subscriptionStatus?.toLowerCase() == "expired" || subscriptionStatus?.toLowerCase() == "cancelled") && (
                <p className="text-[12px] md:text-[14px] leading-[17.07px] text-[#EC4134]">
                  Expired on: {expiredDate}
                </p>
              )}
          </>
        )}
        {cancelled && <p>-</p>}
      </div>
      <div className="__ACTION_CONT__ font-normal flex flex-col justify-start items-start gap-2">
        {subscriptionStatus?.toLowerCase() == "active" && <button
          className={`w-full md:max-w-3/4 py-2 px-3 rounded-[8px] border border-white/50 font-medium text-[10px] md:text-[12px] leading-[14.64px] text-center hover:bg-[#EC4134]/80 hover:text-white transition-all disabled:bg-white/10 disabled:text-white/50 disabled:border-0 ${
            !cancelled ? "text-[#EC4134]" : ""
          }`}
          onClick={() => cancelBtnHandler(channelId)}
          disabled={cancelled}
        >
          {cancelled ? "Cancelled Subscription" : "Cancel Subscription"}
        </button>}
        {(lastPaymentStatus?.toLowerCase() != "success" || cancelled == true) &&
          subscriptionStatus?.toLowerCase() != "inactive" && (
            <button
              className="w-full md:max-w-3/4 py-2 px-3 rounded-[8px] border border-white/50 font-medium text-[10px] md:text-[12px] leading-[14.64px] text-center text-[#fbbc5e] hover:text-black btn-outline transition-all"
              onClick={() => payRenewBtnHandler(channelId)}
            >
              {cancelled ? "Renew Subscription" : "PAY NOW"}
            </button>
          )}
      </div>
    </div>
  );
};

export default ChannelItem;
