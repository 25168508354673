import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-Profile-Reducer-functions
 *@developer Shubham K
 */

// Handling user subscriptions api
export const UserSubscriptions = createApi({
  reducerPath: "UserSubscriptions",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSubscriptionsList: builder.query({
      query: ({ page = 1, limit = 9999 }) => ({
        url:
          EndpointsSlug.GET_USER_SUBSCRIPTION_LIST +
          `?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getUserSubscriptionTransactions: builder.query({
      query: ({ channelId, page = 1, limit = 9999 }) => ({
        url:
          EndpointsSlug.GET_USER_SUBSCRIPTION_TRANS_DETAILS +
          `?channelId=${channelId}&page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    patchCancelSubscription: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.CANCEL_SUBSCRIPTION,
        method: 'POST',
        body: payload,
      })
    }),
  }),
});

export const {
  useGetSubscriptionsListQuery,
  useGetUserSubscriptionTransactionsQuery,
  usePatchCancelSubscriptionMutation,
} = UserSubscriptions;
