import React, { useEffect, useState } from "react";
import {
  VolumetricVideo,
  _180Video,
  _2DVideo,
  _360Video,
  Music,
  Subscription,
} from "./components/index";
import { useGetSingleUserAssetsByTokenQuery,useGetSingleUserSubscribedChannelQuery } from "store/slicer/userProfile";
import Loader from "components/Loader/Loader";

const myAssetsTabs = [
  {
    id: 0,
    parentId: 0,
    key: "volumetric-videos",
    name: "Volumetric Videos",
    children: null,
    component: <VolumetricVideo />,
  },
  {
    id: 1,
    parentId: 0,
    key: "360-videos",
    name: "360 Videos",
    children: null,
    component: <_360Video />,
  },
  {
    id: 2,
    parentId: 0,
    key: "180-videos",
    name: "180 Videos",
    children: null,
    component: <_180Video />,
  },
  {
    id: 3,
    parentId: 0,
    key: "2d-videos",
    name: "2D Videos",
    children: null,
    component: <_2DVideo />,
  },
  {
    id: 4,
    parentId: 0,
    key: "music",
    name: "Music",
    children: null,
    component: <Music />,
  },
  {
    id: 5,
    parentId: 0,
    key: "channels",
    name: "Channel Subscription",
    children: null,
    component: <Subscription />,
  },
];

const MyContentsHome = () => {
  const [activeTabs, setActivetab] = useState({
    id: 0,
    parentId: 0,
    key: "volumetric-videos",
    name: "Volumetric Video",
    children: [],
    component: <VolumetricVideo />,
  });

  const { data, isLoading, error } = useGetSingleUserAssetsByTokenQuery(
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  );
  const { data:subscribeData, isLoading:subscribeIsLoading, error:subscribeError } = useGetSingleUserSubscribedChannelQuery(
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  );



  useEffect(() => {
    if (!isLoading && data?.response?.statusEnum == 1) {
      setActivetab({
        id: 0,
        parentId: 0,
        key: "volumetric-videos",
        name: "Volumetric Video",
        children: [],
        component: <VolumetricVideo item={data?.response?.data} />,
      });
    }
  }, [isLoading]);

  const handleClickAsset = (tabs) => {
    switch (tabs.key) {
      case "volumetric-videos":
        setActivetab({
          ...tabs,
          component: <VolumetricVideo item={data?.response?.data} />,
        });
        break;
      case "360-videos":
        setActivetab({
          ...tabs,
          component: <_360Video item={data?.response?.data} />,
        });
        break;
      case "180-videos":
        setActivetab({
          ...tabs,
          component: <_180Video item={data?.response?.data} />,
        });
        break;

      case "2d-videos":
        setActivetab({
          ...tabs,
          component: <_2DVideo item={data?.response?.data} />,
        });
        break;
      case "music":
        setActivetab({
          ...tabs,
          component: <Music item={data?.response?.data} />,
        });
        break;
      case "channels":
        setActivetab({
          ...tabs,
          component: <Subscription item={subscribeData?.response?.data} />,
        });
        break;
      default:
        setActivetab({
          ...tabs,
          component: <VolumetricVideo item={data?.response?.data} />,
        });
    }
  };

  if (isLoading) {
    return (
      <div className="h-[500px] w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div
      data-testid="my-assetsHome-main"
      className="__myassets_container_main px-[15px] py-[35px] flex max-[768px]:flex-col max-[768px]:p-0 max-[1280px]:py-[25px]"
    >
      <div
        data-testid="myAssets-category-main"
        className="__assets_category_panel w-[20%] py-[20px] max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0"
      >
        <h5 className="text-[grey] font-body font-bold tracking-[1px] max-[768px]:hidden">
          My Entertainment
        </h5>
        <ul
          className="overflow-y-scroll
                        relative
                         px-[0px]
                         [&>li]:px-[12px] 
                         [&>li]:py-[10px] 
                         [&>li]:font-light 
                         [&>li]:font-body
                         [&>li]:my-[15px] 
                         hover:[&>li]:bg-[#FBBC5E1A]
                         hover:[&>li]:rounded-[5px]
                         hover:[&>li]:cursor-pointer
                         max-[768px]:overflow-x-scroll
                         max-[768px]:flex
                         max-[768px]:[&>li]:py-[5px]
                         max-[768px]:[&>li]:my-[10px]
                         max-[768px]:px-0
                         max-[768px]:text-[11px]
                         "
        >
          {myAssetsTabs?.map((tabs, index) => {
            return (
              <li
                key={tabs.key}
                className={`hover:bg-[#FBBC5E4D] rounded-[5px] ${
                  activeTabs.key === tabs.key ? "!bg-[#FBBC5E4D]" : ""
                } whitespace-nowrap`}
                onClick={() => handleClickAsset(tabs)}
              >
                {tabs.name}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="__assets_tabs_container_panel w-[80%] p-[10px] max-[768px]:w-auto max-[768px]:h-auto max-[768px]:mt-[18px]">
        {activeTabs.component}
      </div>
    </div>
  );
};
export default MyContentsHome;
