/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Premium
 *@developer Amardeep Singh
 */

import React from "react";
import CoinIcon from "assets/images/coin-icon.png";
import CoinStarIcon from "assets/images/coin_star.png";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount } from "utils";

const ChannelPremium = ({
  title,
  price,
  plan = "Month",
  handleData,
  justAdded,
}) => {
  const handleClick = () => {
    handleData("Premium");
  };

  return justAdded ? (
    <button
      type="button"
      className="font-bold rounded-lg text-[20px] w-full text-[#25B39E] mt-1 "
      disabled
    >
      Subscribed
    </button>
  ) : (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="font-bold rounded-lg border border-sec btn-outline hover:text-black bg-[#1D1D1D] w-full py-2 px-5 "
      >
        Subscribe
      </button>
    </>
  );
};

export default ChannelPremium;
