/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Apartment-Detail-Page
 *@developer Sudhanshu
 */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import Loader from "components/Loader/Loader";
import Flag from "components/report/Flag";

import BuyCards from "components/buy-cards";
import BuyPopupIap from "components/popup/BuyPopupIap/BuyPopupIap";
import SliderHeader from "components/partials/SliderHeader";
import SliderCards from "components/partials/SliderCards";

import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import ComingSoon from "components/popup/comingsoon";
import { useGetSingleClothingQuery } from "store/slicer/Marketplace";
import {
  useGetCartItemByTokenQuery,
  useUpdateCartItembyTokenMutation,
} from "store/slicer/Cart";
import {
  usePostCheckUserAssetsMutation,
  usePostAddFreeAssetForUserMutation,
} from "store/slicer/userAssets";
import DOMPurify from "dompurify";
import { convertUsdToMarketFstrUsd } from "utils";
import { convertActualUsdToFstrPrice } from "utils";
/* The above code is a React component called "Index". It renders a webpage that displays information
about an apartment, including its image, price, description, themes, gaming and streaming options,
and suggested apartments. It also includes a dialog box that allows the user to preview the
apartment in a 3D model. The component uses various React hooks and components, such as useState,
useNavigate, Grid, Typography, Button, Dialog, AppBar, Toolbar, Canvas, and Stage, to create the
desired UI and functionality. */

const Index = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { categorytype, item } = params;
  const { data, isLoading, refetch } = useGetSingleClothingQuery(item);
  const [AddToCartQuery, { isLoading: addtoCartLoader }] =
    useUpdateCartItembyTokenMutation();

  const [isreportModal, setReportModal] = useState(false);
  const [clothingData, setClothingData] = useState({});
  const [IstooltipHover, setTooltipHover] = useState(false);

  const HandletooltipHover = () => {
    setTooltipHover(!IstooltipHover);
  };

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };

  const SuggestedItem = [
    {
      id: 0,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 1,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 2,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 3,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 4,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 5,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 6,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
    {
      id: 7,
      heading: "NebulaWalker Armor",
      isComing: true,
    },
  ];

  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  // const handleClick = (e) => {
  //   if (e === "MyAsset") {
  //     // setContentType("MyAsset");
  //   }
  //   if (e === "Premium") {
  //     setShowComingSoonPopup(true);
  //     // This will open popup for purchasing when implemented, do not remove this will be used later
  //     // setOpen(true);
  //   }
  //   if (e === "BuyNow") {
  //     setShowComingSoonPopup(true);
  //     // This will open popup for purchasing when implemented, do not remove this will be used later
  //     // setOpen(true);
  //   }
  // };
  useEffect(() => {
    if (data && data.response.statusEnum === 1) {
      setClothingData(data.response.data);
    } else if (data) {
      navigate("/marketplace/clothing");
    }
  }, [data]);
  const handleClick = async (e) => {
    if (e === "MyAsset") {
      let uInfo = localStorage.getItem("userInfo");
      if (!uInfo) return;

      uInfo = JSON.parse(uInfo);

      const uId = uInfo.id;

      if (!uId) return;

      const res = await addAssetToUser({
        content_id: clothingData._id,
        content_type: "clothing",
      });

      if (res?.data?.response?.statusEnum == 1) {
        setIsAssetOwned(true);
        setJustAddedToAssets(true);
      }
    }
    if (e === "Premium") {
      setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
    if (e === "BuyNow") {
      setBuyPopupOpen(true);
      setIsPopupCart(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
  };

  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [isPopupCart, setIsPopupCart] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [popupCurrency, setPopupCurrency] = useState("fstr");
  const [isAssetOwned, setIsAssetOwned] = useState(false);
  const [justAddedToAssets, setJustAddedToAssets] = useState(false);

  const [checkUserAsset, userAssetInfo] = usePostCheckUserAssetsMutation();
  const [addAssetToUser, addAssetToUserInfo] =
    usePostAddFreeAssetForUserMutation();

  useEffect(() => {
    async function checkIfOwnedAsset() {
      try {
        const res = await checkUserAsset({
          content_id: data?.response?.data?._id,
          isIap: true,
        });

        if (res?.data?.response?.statusEnum == 1) {
          // check here if user owns the asset
          const isOwned = res?.data?.response?.data;

          if (isOwned) {
            setIsAssetOwned(true);
          }
        }
      } catch (e) {}
    }
    if (data?.response?.statusEnum == 1) {
      checkIfOwnedAsset();
    }
  }, [data]);

  const onPopupClose = () => {
    setBuyPopupOpen(false);
  };

  const onPurchaseCurrencyChange = (currency) => {
    setPopupCurrency(currency);
  };

  const onPopupActionHandler = () => {
    // handle logic for usd currency here
    if (popupCurrency == "usd") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }

    // handle logic for purchasing from wallet here
    else if (popupCurrency == "fstr") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }
  };

  const addIapToCart = () => {
    AddToCartQuery({
      category: "clothing",
      itemId: data?.response?.data?._id,
      currencyType: popupCurrency == "usd" ? "usd" : "fstr",
    })
      .then((res) => {
        console.log(res.data);
        if (res?.data?.response?.statusEnum == 1) {
          setIsAddedToCart(true);
          refetchCartItems();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkoutIap = () => {
    if (popupCurrency == "usd") {
      navigate(
        `/checkout?productType=clothing&productName=${data?.response?.data?.assetName}&amount=${data?.response?.data?.priceFiat}&productId=${data?.response?.data?._id}`
      );
    } else if (popupCurrency == "fstr") {
      navigate(
        `/checkoutfstr?productType=clothing&productName=${
          data?.response?.data?.assetName
        }&amount=${convertActualUsdToFstrPrice(
          data?.response?.data?.priceFiat
        )}&productId=${data?.response?.data?._id}`
      );
    }
  };

  // fetching all cart items
  const {
    data: GetCartItemQuery,
    isLoading: getCartItemLoader,
    refetch: refetchCartItems,
    isSuccess: isFetchCartSuccess,
  } = useGetCartItemByTokenQuery();

  // this effect will check if this item is already added to user's cart
  useEffect(() => {
    if (isFetchCartSuccess && GetCartItemQuery?.response?.statusEnum == 1) {
      const cartList = GetCartItemQuery?.response?.data?.map(
        (item) => item?.itemId
      );
      if (cartList?.includes(data?.response?.data?._id)) {
        setIsAddedToCart(true);
      }
    }
  }, [getCartItemLoader, data]);

  let buyCardType = "Free";
  if (clothingData?.priceFiat && clothingData?.priceFiat != 0) {
    buyCardType = "BuyNow";
  }
  if (isAddedToCart) {
    buyCardType = "AddToCart";
  }
  if (isAssetOwned) {
    buyCardType = "MyAsset";
  }

  return (
    <div className="relative px-4">
      {buyPopupOpen && (
        <BuyPopupIap
          open={buyPopupOpen}
          onClose={onPopupClose}
          isCart={isPopupCart}
          addedToCart={isAddedToCart}
          loading={addtoCartLoader}
          onCurrencyChange={onPurchaseCurrencyChange}
          actionBtnHandler={onPopupActionHandler}
          proceedToCheckoutHandler={checkoutIap}
          priceUsd={clothingData?.priceFiat}
        />
      )}
      <div className="__back__container__section flex justify-between pb-[12px]">
        <button
          onClick={() =>
            navigate(
              `/marketplace/clothing?category=${clothingData?.clothType}`
            )
          }
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
        {/* <div
          onMouseEnter={HandletooltipHover}
          onMouseLeave={HandletooltipHover}
          className="flex justify-center items-center"
        >
          <div
            onClick={() => {
              setReportModal(!isreportModal);
            }}
            className={`__report__tooltip__section transition-all delay-100 ${
              IstooltipHover ? "" : "hidden"
            } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
          >
            report this assets
          </div>
          <Flag
            onClick={() => {
              setReportModal(!isreportModal);
            }}
          />
        </div> */}
      </div>
      <div className="__details__data__container ml-3 mt-[20px] relative z-[10]">
        {isLoading ? (
          <div className="text-center pt-[10%]">
            <Loader />
          </div>
        ) : data?.response?.statusEnum === 1 &&
          data?.response?.data?.length === 0 ? (
          "No data"
        ) : (
          <div className="__detail__page__container flex gap-[50px] max-[668px]:flex-col max-[1280px]:flex-col max-[668px]:gap-[30px]">
            {/* <div className="__image__section bg-[#242424] w-[100%] h-[678px] max-[668px]:h-[300px] rounded-[20px] object-fit contents">
            <img
              className="object-cover rounded-[20px]"
              src={clothingData?.largeThumbnail?.url}
              alt={clothingData?.largeThumbnail?.fileName}
            />
          </div> */}
            <div className="__image__section bg-[#242424] w-[100%] !max-h-[678px] max-[668px]:h-[300px] rounded-[20px] object-fit contents">
              <Zoom>
                <img
                  src={clothingData?.largeThumbnail?.url}
                  alt={clothingData?.largeThumbnail?.fileName}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.style.display = "none";
                  }}
                  className="max-h-[678px] h-full w-full object-cover  rounded-[20px]"
                />
              </Zoom>
            </div>
            <div className="__item__content__section mt-[10px] flex flex-col max-[668px]:mt-[5px] w-[100%]">
              <h4 className="text-[24px] font-heading max-[668px]:text-[18px]">
                {clothingData?.assetName || ""}
              </h4>
              <div className="__category__main pt-[28px]">
                <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                  Category:
                </h6>
                <span className="font-body font-medium text-[#fff] capitalize">
                  {categorytype?.toLowerCase()}
                </span>
              </div>

              <div className="__description__main pt-[28px]">
                <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                  Description:
                </h6>
                {data &&
                  clothingData?.longDescription?.map((htmlString, index) => (
                    <div
                      key={index}
                      className="inner-html-headings text-white/90 max-md:[&>p]:text-[13px]"
                      style={{
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(htmlString),
                      }}
                    />
                  ))}
              </div>
              {/* <div className="__tag_line pt-[20px]">
                <span className="font-body px-[10px] py-[8px] rounded-3xl text-[15px] max-[668px]:text-[13px] bg-white/[0.10]">
                  {categorytype?.split("-").join(" ")}
                </span>
              </div> */}

              <div className="__buy__main pt-[28px] pr-[40px] max-[768px]:pr-0">
                <div className="flex-1 w-full lg:mt-0 min-h-180">
                  {userAssetInfo?.isLoading || addAssetToUserInfo.isLoading ? (
                    <div className="w-full h-[150px] grid place-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <BuyCards
                        type={buyCardType}
                        card="content"
                        title="Buy this Clothing"
                        price={`$ ${clothingData?.priceFiat}`}
                        handleData={(e) => {
                          handleClick(
                            e,
                            clothingData?.category,
                            clothingData?._id
                          );
                        }}
                        onBuyHandle={() => {
                          setBuyPopupOpen(true);
                          setIsPopupCart(false);
                        }}
                      />
                    </>
                  )}
                  <ComingSoon
                    status={showComingSoonPopup}
                    text={`In-app purchases are on the way, and you'll soon have the opportunity to own exciting assets. Stay tuned!`}
                    handleData={() => {
                      setShowComingSoonPopup(false);
                    }}
                  />
                  {/* {contentType == "Premium" && <> <UnlockPremium status={open} text={`Unlock the access exclusive content and early releases curated just for you. Subscribe for <b>${price}/Month ( $288.00/year )</b>.`} heading="Unlock the Premium" handleData={() => setOpen(false)}
                handleSub={() => {setContentType("MyAsset"); setBuyCardTitle("You unlocked this content by subscribing to <b style='color:white'>Fourth Star</b> channel")}}
              />
              </>} */}

                  {/* {contentType == "BuyNow" && <Popup status={open} text="Buy this content and get lifetime access to it." heading="Buy Content" button={`Buy now @$${price}`} handleData={() => setOpen(false)} handleSub={() => {setContentType("MyAsset"); setBuyCardTitle(`Purchased access on <b style="color:white">${dateFormat(new Date(), "d mmmm yyyy")}</b>`)}} />} */}
                </div>
              </div>
              {/* <a
                href="#"
                className="text-[16px] font-body text-[#FFFFFF80] hover:underline underline-offset-4 pt-[44px]"
              >
                Buy this Clothing
              </a>
              <div className="__price__section pt-[30px] flex items-center gap-[10px]">
                <img
                  src={CoinIcon}
                  alt="__coin__icon"
                  className="w-[32px] h-[32px] max-[668px]:w-[28px] max-[668px]:h-[28px]"
                />
                <span className="text-[16px] text-[#FFFFFF80] font-body">
                  $99.00
                </span>
              </div>
              <span className="text-[#F3A151] text-[18px] font-body pt-[24px] max-[668px]:text-[15px]">
                Coming Soon
              </span> */}
            </div>
          </div>
        )}
        {/* Content Divider Section */}
        <div className="__content__divider py-[120px] max-[668px]:py-[80px]">
          <hr className="border-[0.1px] border-[#5A5A5A80]" />
        </div>

        {/* Suggested Item Slider Section */}

        <div className="__suggested__items__container">
          <SliderHeader title={`${`Suggested Clothing`}`} />
          <div className="__pets__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            {/* {id.toLowerCase() != "avatars" && ( */}
            <div className="__slider_card_container flex gap-[20px] overflow-x-scroll py-[10px]">
              {SuggestedItem.map((data) => {
                return (
                  <SliderCards
                    item={data}
                    id={"clothing"}
                    baseRoute={"marketplace/clothing"}
                  />
                );
              })}
            </div>
            {/* {id.toLowerCase() == "avatars" && (
              <div className="__slider_card_container  py-[10px]">
                <Slider {...sliderSettings} className="carousel-section">
                  {SuggestedAvatarItems.map((data) => {
                    return (
                      <SliderCards
                        item={data}
                        id={categorytype}
                        type="COMP"
                        baseRoute="marketplace/avatars"
                      />
                    );
                  })}
                </Slider>
              </div>
            )} */}
          </div>
        </div>
      </div>

      {isreportModal ? (
        <AssetsReportModal
          handleModalStatus={handleCloseReportModal}
          idToReport={data?.response?.data?._id}
          contentType={"clothing"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Index;
