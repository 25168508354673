import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from 'constant/api/Endpoints';
import { BASE_URL_CREATOR, EndpointsSlug } from 'constant/api/Endpoints'

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Analytics-Api-Calls
 *@developer Shubham K
 */

 export const AnalyticsSlice = createApi({
    reducerPath:"Analytics",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL_CREATOR,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken') || "";
            headers.set('Content-type', 'application/json; charset=UTF-8')
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
                
            }
            return headers
        },
    }),
    endpoints: (builder) =>({
        postCreateUserInteraction:builder.mutation({
            query:(payload)=>({
                url:BASE_URL + EndpointsSlug.CREATE_USER_INTERACTION,
                method: 'POST',
                body:payload
            })
        }),        
    })
 })

 export const {
    usePostCreateUserInteractionMutation

 } = AnalyticsSlice