import React from "react";
import Alert from "components/popup/Alert/Alert";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen">
      <Alert
        open={true}
        canClose={false}
        heading="Success"
        message="Transaction Successful"
        buttonText="Go To My Assets"
        onClose={() => navigate("/my-assets")}
      />
    </div>
  );
};

export default Success;
