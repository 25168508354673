import React, { useState } from "react";
import { Zoom } from "react-reveal";
import SliderHeader from "components/partials/SliderHeader";
import ShadowFrame from "components/shadow-frame";
import SliderCards from "components/partials/SliderCards";
import Slider from "react-slick";
import { useGetListMarketplaceAvatarQuery } from "store/slicer/Marketplace";
import Loader from "components/Loader/Loader";
import ImageConstant from "constant/imageConstant";
import ComingSoon from "components/popup/comingsoon";

const sliderSettings = {
  slidesToShow: 4.5,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  swipeToSlide: true,
  infinite: false,
  focusOnSelect: false,
  // arrows: false,
  responsive: [
    {
      breakpoint: 1535,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
};

const Index = () => {
  let {
    data: dataHumanoid,
    isFetching: isFetchingHumanoid,
    isSuccess: isSuccessHumanoid,
    isError: isErrorHumanoid,
  } = useGetListMarketplaceAvatarQuery({
    searchQuery: "",
    species: "humanoid",
    page: 1,
    limit: 12,
  });
  let {
    data: dataAlien,
    isFetching: isFetchingAlien,
    isSuccess: isSuccessAlien,
    isError: isErrorAlien,
  } = useGetListMarketplaceAvatarQuery({
    searchQuery: "",
    species: "alien",
    page: 1,
    limit: 12,
  });
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);

  if (isFetchingHumanoid || isFetchingAlien) {
    return (
      <div className="h-screen w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  if (
    isErrorAlien ||
    dataAlien?.response?.statusEnum != 1 ||
    isErrorHumanoid ||
    dataHumanoid?.response?.statusEnum != 1
  ) {
    return (
      <div className="h-screen w-full text-white/70 grid place-content-center">
        <p>Something went wrong</p>
      </div>
    );
  }
  let filteredDataAlien = [];
  if (isSuccessAlien && dataAlien?.response?.statusEnum == 1) {
    dataAlien = dataAlien.response.data.data;
    filteredDataAlien = dataAlien.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }

  let filteredDataHumanoid = [];
  if (isSuccessHumanoid && dataHumanoid?.response?.statusEnum == 1) {
    dataHumanoid = dataHumanoid.response.data.data;
    filteredDataHumanoid = dataHumanoid.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }
  // let humnaoidData = [];
  // let alienData = [];

  // if (filteredData?.length > 0) {
  //   alienData = filteredData.filter((item) => item.species === "alien");
  //   humnaoidData = filteredData.filter((item) => item.species !== "alien");
  // }
  return (
    <div
      data-testid="__avatar_landing_page"
      className="__avatar_landing_page px-2"
    >
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <Zoom>
        <div
          className="min-h-[200px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.AVATAR_BANNER_IMAGE}')`,
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-left justify-center p-2 lg:p-6 pl-2 md:pl-10">
            <h1 className="font-heading text-lg md:text-2xl lg:text-3xl text-left mb-2 md:mb-4">
              Just Be Yourself!
            </h1>
            <div className="w-full md:w-[47%] py-4 lg:py-10 text-xs sm:text-sm md:text-base lg:text-lg">
              Shape Your Digital Persona: Uncover Avatars Tailored to Your
              Unique Essence!
            </div>
          </div>
        </div>
      </Zoom>

      {/* Slider Section */}

      {/* Pets */}
      <SliderHeader
        title="Humanoid Avatars"
        seeMoreUrl={
          filteredDataHumanoid.length > 4
            ? "/marketplace/avatars/humanoid"
            : null
        }
      />
      <div className="__pets__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
        <div data-testid="slider-card" className="py-[10px]">
          <Slider {...sliderSettings} className="carousel-section">
            {" "}
            {filteredDataHumanoid.map((data) => {
              return (
                <SliderCards
                  item={data}
                  id="humanoid"
                  type="COMP"
                  baseRoute="marketplace/avatars"
                  setShowComingSoonPopup={setShowComingSoonPopup}
                />
              );
            })}
          </Slider>
        </div>
      </div>

      {/* Butlers and Bodyguards slider Section */}
      <SliderHeader
        title="Alien Avatars"
        seeMoreUrl={
          filteredDataAlien.length > 4 ? "/marketplace/avatars/alien" : null
        }
      />
      <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
        <div
          data-testid="slider-card"
          className="__slider_card_container py-[10px]"
        >
          <Slider {...sliderSettings} className="carousel-section">
            {filteredDataAlien.map((data) => {
              return (
                <SliderCards
                  item={data}
                  id="alien"
                  type="COMP"
                  baseRoute="marketplace/avatars"
                  setShowComingSoonPopup={setShowComingSoonPopup}
                />
              );
            })}
          </Slider>
        </div>
        <ComingSoon
          status={showComingSoonPopup}
          text={`This avatar is on the way, and you'll soon have the opportunity to own this asset. Stay tuned!`}
          handleData={() => {
            setShowComingSoonPopup(false);
          }}
        />
      </div>
    </div>
  );
};

export default Index;
