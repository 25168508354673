/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Apartment-Detail-Page
 *@developer Sudhanshu
 */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PremAptImg from "assets/images/premium_apt.png";
import PremAptImg1 from "assets/images/apartment-1.png";
import PremAptImg2 from "assets/images/apartment-2.png";
import { Fade, Zoom } from "react-reveal";
import SlickSlider from "react-slick";
import MarketPlaceCard from "components/card/marketplace";
import ShadowFrame from "components/shadow-frame";
import GameRoom1 from "assets/images/game-room-1.png";
import GameRoom2 from "assets/images/game-room-2.png";
import GameRoom3 from "assets/images/game-room-3.png";
import PremiumApt from "assets/images/Premium-apartment.png";
import ApartmentThumb from "assets/images/apartment-thumbnail.png";
import PremiumApt2 from "assets/images/Premium-final-1.png";
import StandredAppartment from "assets/images/StandredAppartment.png";
import BuyCards from "components/buy-cards";
import BuyPopupIap from "components/popup/BuyPopupIap/BuyPopupIap";
import Slider from "assets/images/slider-1.png";
import Slider2 from "assets/images/slider-2.png";

import SneakyPeakNebulaImg1 from "assets/images/sneaky_peak_nebula-1.jpg";
import SneakyPeakNebulaImg2 from "assets/images/sneaky_peak_nebula-2.jpg";
import SneakyPeakNebulaImg3 from "assets/images/sneaky_peak_nebula-3.jpg";
import SneakyPeakNebulaImg4 from "assets/images/sneaky_peak_nebula-4.jpg";

import SneakyPeakAstralImg1 from "assets/images/sp1 astral.jpg";
import SneakyPeakAstralImg2 from "assets/images/sp2 astral.jpg";
import SneakyPeakAstralImg3 from "assets/images/sp3 astral.jpg";
import SneakyPeakAstralImg4 from "assets/images/sp4 astral.jpg";
import SneakyPeakAstralImg5 from "assets/images/sp5 astral.jpg";

import SneakyPeakOrionImg1 from "assets/images/sp1 orion.jpg";
import SneakyPeakOrionImg2 from "assets/images/sp2 orion.jpg";
import SneakyPeakOrionImg3 from "assets/images/sp3 orion.jpg";
import SneakyPeakOrionImg4 from "assets/images/sp4 orion.jpg";
import SneakyPeakOrionImg5 from "assets/images/sp5 orion.jpg";

import Thumb1 from "assets/images/StandredAppartment-thum.png";
import Thumb2 from "assets/images/StandredAppartment-thum2.png";
import Thumb3 from "assets/images/StandredAppartment-thum3.png";
import Thumb4 from "assets/images/StandredAppartment-thum4.png";
import Preview from "components/popup/Preview";
import Flag from "components/report/Flag";
import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import ComingSoon from "components/popup/comingsoon";
import { useGetSingleApartmentQuery } from "store/slicer/Apartments";
import {
  useGetCartItemByTokenQuery,
  useUpdateCartItembyTokenMutation,
} from "store/slicer/Cart";
import { usePostCheckUserAssetsMutation } from "store/slicer/userAssets";
import Loader from "components/Loader/Loader";
import DOMPurify from "dompurify";
import { convertUsdToFstrPrice, convertActualUsdToFstrPrice } from "utils";

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  swipeToSlide: true,
  infinite: false,
  focusOnSelect: true,
  // arrows: false,
  // responsive: [
  //   {
  //     breakpoint: 1921,
  //     settings: {
  //       slidesToShow: 3,
  //     },
  //   },
  //   {
  //     breakpoint: 1200,
  //     settings: {
  //       slidesToShow: 2,
  //     },
  //   },

  //   {
  //     breakpoint: 1025,
  //     settings: {
  //       slidesToShow: 1.55,
  //     },
  //   },
  //   {
  //     breakpoint: 913,
  //     settings: {
  //       slidesToShow: 1.4,
  //     },
  //   },
  //   {
  //     breakpoint: 821,
  //     settings: {
  //       slidesToShow: 1.2,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 1.1,
  //     },
  //   },
  //   {
  //     breakpoint: 616,
  //     settings: {
  //       slidesToShow: 1,
  //     },
  //   },
  // ],
};

/* The above code is a React component called "Index". It renders a webpage that displays information
about an apartment, including its image, price, description, themes, gaming and streaming options,
and suggested apartments. It also includes a dialog box that allows the user to preview the
apartment in a 3D model. The component uses various React hooks and components, such as useState,
useNavigate, Grid, Typography, Button, Dialog, AppBar, Toolbar, Canvas, and Stage, to create the
desired UI and functionality. */
const Index = () => {
  const { apartmentId, apartment_name } = useParams();
  const { data, isLoading, refetch } = useGetSingleApartmentQuery(apartmentId);
  const [AddToCartQuery, { isLoading: addtoCartLoader }] =
    useUpdateCartItembyTokenMutation();

  const navigate = useNavigate();

  const sliderContainerRef = useRef(null);
  const [sneakPeakItemWidthRatio, setSneakPeakItemWidthRatio] = useState(1);

  useEffect(() => {
    const updateSliderItemWidth = () => {
      if (!sliderContainerRef.current) {
        return;
      }

      const container = sliderContainerRef.current;
      let sliderImageWidth = 615;

      if (container.clientWidth <= 615) {
        sliderImageWidth = container.clientWidth;
      }

      const updatedItemWidthRatio =
        (container.clientWidth / sliderImageWidth) * 0.95;
      setSneakPeakItemWidthRatio(updatedItemWidthRatio);
    };

    if (isLoading) {
      return;
    }

    updateSliderItemWidth();

    window.addEventListener("resize", updateSliderItemWidth);

    return () => {
      return window.removeEventListener("resize", updateSliderItemWidth);
    };
  }, [data]);

  const [gameImage, setGameImage] = useState([
    {
      img: GameRoom1,
      title: "Ultimate Gaming Experience",
    },
    {
      img: "https://d30s60cgc31mft.cloudfront.net/assets/ship-small.png",
      title: "Ultimate Gaming Experience",
    },
    {
      img: GameRoom3,
      title: "Ultimate Gaming Experience",
    },
  ]);

  const [themes, setThemes] = useState([
    {
      img: PremiumApt,
      title: "Classic theme apartment",
      active: true,
    },
    {
      img: PremiumApt2,
      title: "Sci-Fi theme apartment",
      active: false,
    },
  ]);

  const [singleApartmentData, setSingleApartmentData] = useState({});

  const Nebulaslider = [
    {
      id: 0,
      largeImg: SneakyPeakNebulaImg4,
      thumbImg: Thumb1,
    },

    {
      id: 1,
      largeImg: SneakyPeakNebulaImg1,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakNebulaImg2,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakNebulaImg3,
      thumbImg: Thumb3,
    },
  ];

  const Astralslider = [
    {
      id: 0,
      largeImg: SneakyPeakAstralImg1,
      thumbImg: Thumb1,
    },

    {
      id: 1,
      largeImg: SneakyPeakAstralImg2,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakAstralImg3,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakAstralImg4,
      thumbImg: Thumb3,
    },
    {
      id: 4,
      largeImg: SneakyPeakAstralImg5,
      thumbImg: Thumb3,
    },
  ];

  const Orionslider = [
    {
      id: 0,
      largeImg: SneakyPeakOrionImg1,
      thumbImg: Thumb1,
    },

    {
      id: 1,
      largeImg: SneakyPeakOrionImg2,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakOrionImg3,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakOrionImg4,
      thumbImg: Thumb3,
    },
    {
      id: 4,
      largeImg: SneakyPeakOrionImg5,
      thumbImg: Thumb3,
    },
  ];
  const [open, setOpen] = useState(false);
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  const handleClick = (e, category, id) => {
    if (e === "MyAsset") {
      // setContentType("MyAsset");
    }
    if (e === "Premium") {
      setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
    if (e === "BuyNow") {
      setBuyPopupOpen(true);
      setIsPopupCart(true);
      // setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
  };

  let title = "";
  let description = "";
  let bannerImage = PremAptImg;

  switch (apartment_name) {
    case "orion-penthouse":
      title = "Orion Penthouse";
      description =
        "Indulge in the opulence of these towering apartments that capture the essence of a radiant galaxy, offering a sophisticated and cosmopolitan living experience. Immerse yourself in the wonders of virtual living, where every corner is a gateway to adventure, entertainment, and boundless exploration.";
      bannerImage = PremAptImg;
      break;

    case "astral-suite":
      title = "Astral Suite";
      description =
        "Find yourself enchanted by the mesmerizing allure of the aurora in these exclusive enclaves, where the ethereal beauty of the night sky is brought to life.Immerse yourself in the wonders of virtual living, where every corner is a gateway to adventure, entertainment, and boundless exploration.";
      bannerImage = PremAptImg2;
      break;

    case "nebula-apartment":
      title = "Nebula Apartment";
      description =
        "Find yourself enchanted by the mesmerizing allure of the aurora in these exclusive enclaves, where the ethereal beauty of the night sky is brought to life..Immerse yourself in the wonders of virtual living, where every corner is a gateway to adventure, entertainment, and boundless exploration.";
      bannerImage = PremAptImg1;
      break;

    default:
      title = "Orion Penthouse";
      description =
        "Indulge in the opulence of these towering apartments that capture the essence of a radiant galaxy, offering a sophisticated and cosmopolitan living experience. Immerse yourself in the wonders of virtual living, where every corner is a gateway to adventure, entertainment, and boundless exploration.";
      bannerImage = PremAptImg;
  }

  const [apartments, setApartments] = useState([]);

  const [IstooltipHover, setTooltipHover] = useState(false);

  const HandletooltipHover = () => {
    setTooltipHover(!IstooltipHover);
  };

  const [isreportModal, setReportModal] = useState(false);

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };

  useEffect(() => {
    // if(data?.resposne?.statusEnum === 1){
    //   setSingleApartmentData(data?.resposne?.data)
    // }
    refetch();
  }, [apartmentId]);

  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [isPopupCart, setIsPopupCart] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [popupCurrency, setPopupCurrency] = useState("fstr");
  const [isAssetOwned, setIsAssetOwned] = useState(false);
  const [selectedThemeIndex, setSelectedThemIndex] = useState(0);

  const [checkUserAsset, userAssetInfo] = usePostCheckUserAssetsMutation();

  // This needs to be updated from the api side first, we need to be able to pass the theme id to check too
  useEffect(() => {
    async function checkIfOwnedAsset() {
      try {
        const res = await checkUserAsset({
          content_id: data?.response?.data?._id,
          theme_id: themesArr[selectedThemeIndex]?._id,
          isIap: true,
        });

        if (res?.data?.response?.statusEnum == 1) {
          // check here if user owns the asset
          const isOwned = res?.data?.response?.data;

          if (isOwned) {
            setIsAssetOwned(true);
          }
        }
      } catch (e) {}
    }
    if (data?.response?.statusEnum == 1) {
      checkIfOwnedAsset();
    }
  }, [data, selectedThemeIndex]);

  const onPopupClose = () => {
    setBuyPopupOpen(false);
  };

  const onPurchaseCurrencyChange = (currency) => {
    setPopupCurrency(currency);
  };

  const onPopupActionHandler = () => {
    // handle logic for usd currency here
    if (popupCurrency == "usd") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }

    // handle logic for purchasing from wallet here
    else if (popupCurrency == "fstr") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }
  };

  const addIapToCart = () => {
    AddToCartQuery({
      category: "apartment",
      itemId: data?.response?.data?._id,
      currencyType: popupCurrency == "usd" ? "usd" : "fstr",
      itemThemeId: themesArr[selectedThemeIndex]._id,
    })
      .then((res) => {
        console.log(res.data);
        if (res?.data?.response?.statusEnum == 1) {
          setIsAddedToCart(true);
          refetchCartItems();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkoutIap = () => {
    if (popupCurrency == "usd") {
      navigate(
        `/checkout?productType=apartment&productName=${data?.response?.data?.assetName}&amount=${themesArr[selectedThemeIndex]?.priceFiat}&productId=${data?.response?.data?._id}&themeId=${themesArr[selectedThemeIndex]._id}`
      );
    } else if (popupCurrency == "fstr") {
      navigate(
        `/checkoutfstr?productType=apartment&productName=${
          data?.response?.data?.assetName
        }&amount=${convertActualUsdToFstrPrice(
          themesArr[selectedThemeIndex]?.priceFiat
        )}&productId=${data?.response?.data?._id}&themeId=${
          themesArr[selectedThemeIndex]._id
        }`
      );
    }
  };

  // fetching all cart items
  const {
    data: GetCartItemQuery,
    isLoading: getCartItemLoader,
    refetch: refetchCartItems,
    isSuccess: isFetchCartSuccess,
  } = useGetCartItemByTokenQuery(undefined, { skip: isLoading });

  // this effect will check if this item is already added to user's cart
  useEffect(() => {
    if (isFetchCartSuccess && GetCartItemQuery?.response?.statusEnum == 1) {
      let foundItem = GetCartItemQuery?.response?.data?.find((item) => {
        return (
          item?.itemId == data?.response?.data?._id &&
          item?.itemsThemeId == themesArr[selectedThemeIndex]._id
        );
      });

      // found an item in cart list with same item and theme id
      if (foundItem) {
        setIsAddedToCart(true);
      } else {
        setIsAddedToCart(false);
      }
    }
  }, [getCartItemLoader, data, selectedThemeIndex]);

  let buyCardType = "BuyNowTheme";
  if (isAddedToCart) {
    // buyCardType = "AddToCart";
  } else if (isAssetOwned) {
    // buyCardType = "MyAsset";
  }
  if (data?.response && data.response.statusEnum !== 1) {
    navigate("/marketplace/apartments");
  }

  let themesArr = [];
  if (data?.response?.statusEnum == 1) {
    themesArr = data?.response?.data?.themesArr.slice();
  }

  if (isLoading) {
    return (
      <div className="h-[500px] w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative px-4">
      {buyPopupOpen && (
        <BuyPopupIap
          open={buyPopupOpen}
          onClose={onPopupClose}
          isCart={isPopupCart}
          addedToCart={isAddedToCart}
          loading={addtoCartLoader}
          onCurrencyChange={onPurchaseCurrencyChange}
          actionBtnHandler={onPopupActionHandler}
          proceedToCheckoutHandler={checkoutIap}
          priceUsd={themesArr[selectedThemeIndex]?.priceFiat}
        />
      )}
      <div className="__back__container__section flex justify-between pb-[12px]">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
        {/* <div
          onMouseEnter={HandletooltipHover}
          onMouseLeave={HandletooltipHover}
          className="flex justify-center items-center"
        >
          <div
            onClick={() => {
              setReportModal(!isreportModal);
            }}
            className={`__report__tooltip__section transition-all delay-100 ${
              IstooltipHover ? "" : "hidden"
            } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
          >
            Report this asset
          </div>
          <Flag
            onClick={() => {
              setReportModal(!isreportModal);
            }}
          />
        </div> */}
      </div>
      <div className="lg:min-h-[350px] mb-6 md:mb-9 z-10 relative rounded flex justify-center items-center">
        <Zoom>
          <img
            src={data?.response?.data?.largeThumbnail?.url}
            alt={data?.response?.data?.largeThumbnail?.fileName}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none";
            }}
            className="h-full w-full object-cover"
          />
        </Zoom>
      </div>
      <div className="relative">
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -left-[80px] md:-left-[300px] -top-[80px] md:-top-[150px]" />
        <div className="flex justify-stretch xl:justify-between items-start mb-7 md:mb-14 relative z-10 gap-10 md:gap-8 lg:gap-14">
          <div className="xl:max-w-[50%]">
            {/* <p className="clip-text font-medium mb-3">About Apartment</p> */}
            <h1 className="font-heading text-md mb-3">
              {data?.response?.data?.assetName}
            </h1>
            {data?.response?.data?.longDescription?.map((item) => (
              <div
                className="inner-html-headings [&>p]:text-white/90 max-md:[&>p]:text-[13px]"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item),
                }}
              ></div>
            ))}

            {/* <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  data?.response?.data?.longDescription?.[0]
                ),
              }}
              className="text-sm text-white text-opacity-90"
            ></p> */}
          </div>
          <div className="flex-auto xl:max-w-2xl py-[34px] px-[56px] bg-gradient-to-r from-[#312d26] to-[#30261d] rounded-[20px]">
            {/* Type : BuyNow || Free || Premium || MyAsset */}
            <BuyCards
              type={buyCardType}
              isAddedToCart={isAddedToCart}
              isOwned={isAssetOwned}
              card="product"
              title="Buy Apartment"
              price={`$${themesArr[selectedThemeIndex]?.priceFiat}`}
              star={convertUsdToFstrPrice(
                themesArr[selectedThemeIndex]?.priceFiat
              )}
              handleData={(e) => {
                handleClick(
                  e,
                  data?.response?.data?.category,
                  data?.response?.data?._id
                );
              }}
              onBuyHandle={() => {
                setBuyPopupOpen(true);
                setIsPopupCart(false);
              }}
              selectedThemeIndex={selectedThemeIndex}
              onThemeChange={(ind) => setSelectedThemIndex(ind)}
              themes={themesArr.map((item) => item.themeName)}
            />
            <ComingSoon
              status={showComingSoonPopup}
              text={`In-app purchases are on the way, and you'll soon have the opportunity to own exciting assets. Stay tuned!`}
              handleData={() => {
                setShowComingSoonPopup(false);
              }}
            />
          </div>
        </div>
        <div className="streaming-section my-10 md:my-20 z-10 relative">
          <h2 className="font-heading text-lg md:text-2xl mb-5">Themes</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {themesArr?.map((item, i) => (
              <div className="relative" key={item._id}>
                <div
                  className={`aspect-[247/149] rounded-lg border-2 ${
                    i === selectedThemeIndex
                      ? "border-sec"
                      : " border-transparent"
                  } ${item.comingSoon ? "coming-soon" : ""} cursor-pointer`}
                  key={i}
                  onClick={() => setSelectedThemIndex(i)}
                >
                  <img
                    src={item.thumbnail?.url}
                    alt={item.themeName}
                    className="h-full w-full object-cover rounded-lg"
                  />
                </div>
                <p className="font-medium mt-3">{item?.themeName}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="streaming-section my-10 md:my-20 z-10 relative">
          <h2 className="font-heading text-lg md:text-2xl mb-5">Sneaky Peek</h2>
          <div className="max-w-full" ref={sliderContainerRef}>
            <SlickSlider
              {...sliderSettings}
              slidesToShow={sneakPeakItemWidthRatio}
              className="carousel-section"
            >
              {themesArr[selectedThemeIndex].sneakPeakImages.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="w-full max-w-[615px] h-[292px]"
                  >
                    <img
                      className="h-full w-full object-cover"
                      src={item.url}
                      alt=""
                    />
                  </div>
                );
              })}
            </SlickSlider>
          </div>
          <div className="mt-12">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="rounded-lg font-medium bg-gradient-3 border border-sec justify-center py-3 px-5 md:px-10 flex gap-2 mt-4"
            >
              Preview in Marketing Suite
            </button>
            <Preview
              status={open}
              text="To preview the apartment, login to Oculus to have full apartment view inside Marketing Suite."
              heading="Preview in Marketing Suite"
              handleData={() => setOpen(false)}
            />
          </div>
        </div>
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -right-[80px] md:-right-[300px] -bottom-[80px] md:-bottom-[150px]" />
      </div>
      <div className="streaming-section my-12 mt-14 md:my-20 md:mt-28 z-10 relative">
        <div className="max-w-3xl mb-12">
          <h2 className="font-heading text-lg md:text-2xl text-white mb-5">
            Explore Gaming & Streaming inside Apartment
          </h2>
          <p className="text-white/[0.90] text-sm md:text-base leading-normal">
            Experience next-level gaming and streaming. Dive into immersive
            worlds, challenge friends in multiplayer battles, or simply relax
            and enjoy your favorite content. With cutting-edge technology and a
            vast selection of games and entertainment.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {gameImage.map((item, i) => (
            <div className="aspect-[403/207] bg-[#EFEFEF]" key={i}>
              <img
                src={item.img}
                alt={item.title}
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="h-[1px] bg-[#5a5a5a]/[0.20]"></div>
      {/* <h2 className="font-heading text-lg md:text-2xl mt-10 md:mt-20 mb-7">
        Suggested Apartments
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-8 lg:gap-y-16 mb-14 md:mb-28 mx-auto relative z-10">
        {apartments.map((item, i) => (
          <Fade key={i}>
            <MarketPlaceCard item={item} type="large" />
          </Fade>
        ))}
      </div> */}

      {isreportModal ? (
        <AssetsReportModal
          handleModalStatus={handleCloseReportModal}
          idToReport={data?.response?.data?._id}
          contentType={"apartment"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Index;
