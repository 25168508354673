/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-friends-Reducer-functions
 *@developer Aditya P
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

export const UserFriend = createApi({
  reducerPath: "userfriend",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    SearchUserFriend: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.SEARCH_FRIEND,
        method: "POST",
        body: payload,
      }),
    }),
    AddFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.FRIEND_REQUEST_SEND,
        method: "PATCH",
        body: payload,
      }),
    }),
    AcceptFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.ACCEPT_FRIEND_REQUEST,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    RejectFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REJECT_FRIEND_REQUEST,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    PendingFriendrequest: builder.mutation({
      query: (payload) => ({
        url: "",
        method: "PATCH",
        body: payload,
      }),
    }),
    GetBlockFriendRequest: builder.query({
      query: () => ({
        url: EndpointsSlug?.GET_BLOCKED_FRIENDS,
        method: "GET",
      }),
    }),
    GetUserFriendRequest: builder.query({
      query: () => ({
        url: EndpointsSlug?.GET_FRIENDS,
        method: "GET",
      }),
    }),
    GetPendingInvitesRequest: builder.query({
      query: () => ({
        url: EndpointsSlug?.GET_PENDING_INVITES,
        method: "GET",
      }),
    }),
    GetRequestedFriendRequest: builder.query({
      query: () => ({
        url: EndpointsSlug?.GET_REQUESTED_FRIEND_REQUEST,
        method: "GET",
      }),
    }),
    CancelRequestedFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.CANCEL_REQUEST_FRIEND,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    RemoveFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REMOVE_FRIEND,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    BlockFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.BLOCK_FRIEND,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    AcceptPendingRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.ACCEPT_FRIEND,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    UnblockFriendRequest: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.UNBLOCK_FRIEND,
        method: "PATCH",
        body: {
          senderId: payload,
        },
      }),
    }),
    getFriendListApartmentAsset: builder.query({
      query: ({ apartmentId = "" }) => ({
        url:
          EndpointsSlug.GET_FRIEND_LIST_APARTMENT_ASSET +
          "?apartmentId=" +
          apartmentId,
        method: "GET",
      }),
    }),
    inviteFriendApartmentAsset: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.INVITE_FRIEND_APARTMENT_ASSET,
        method: "POST",
        body: payload,
      }),
    }),
    removeFriendApartmentAsset: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REMOVE_FRIEND_APARTMENT_ASSET,
        method: "DELETE",
        body: payload,
      }),
    }),
    getFriendListShipAsset: builder.query({
      query: ({ shipId = "" }) => ({
        url:
          EndpointsSlug.GET_FRIEND_LIST_SHIP_ASSET +
          "?shipId=" +
          shipId,
        method: "GET",
      }),
    }),
    inviteFriendShipAsset: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.INVITE_FRIEND_SHIP_ASSET,
        method: "POST",
        body: payload,
      }),
    }),
    removeFriendShipAsset: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REMOVE_FRIEND_SHIP_ASSET,
        method: "DELETE",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetBlockFriendRequestQuery,
  useGetUserFriendRequestQuery,
  useGetPendingInvitesRequestQuery,
  useGetRequestedFriendRequestQuery,
  useAddFriendRequestMutation,
  useSearchUserFriendMutation,
  useCancelRequestedFriendRequestMutation,
  useRemoveFriendRequestMutation,
  useBlockFriendRequestMutation,
  useAcceptFriendRequestMutation,
  useAcceptPendingRequestMutation,
  useRejectFriendRequestMutation,
  useUnblockFriendRequestMutation,
  useGetFriendListApartmentAssetQuery,
  useInviteFriendApartmentAssetMutation,
  useRemoveFriendApartmentAssetMutation,
  useGetFriendListShipAssetQuery,
  useInviteFriendShipAssetMutation,
  useRemoveFriendShipAssetMutation,
} = UserFriend;
