import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { GlobalStore } from "store/GlobalStore";

import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import Loader from "components/Loader/Loader";
import ShadowFrame from "components/shadow-frame";

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [searchParams] = useSearchParams();

  const productType = searchParams.get("productType");
  const productName = searchParams.get("productName");
  const amount = searchParams.get("amount");
  const productId = searchParams.get("productId");
  const themeId = searchParams.get("themeId");
  const isSubscription = searchParams.get("isSubscription");
  const channelId = searchParams.get("channelId");

  if (
    !searchParams.get("isCart") &&
    !searchParams.get("isSubscription") &&
    (!productType || !amount || !productId)
  ) {
    navigate(-1);
  }

  const [loading, setLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // for paid items, NOT subscription
    async function initiateTrans() {
      try {
        setLoading(true);
        let product = {
          amount: Number(amount),
          productId: productId,
          productType: productType,
          name: productName,
        };

        // for apartments and ships
        if (themeId) {
          product = {
            ...product,
            themeId,
          };
        }

        // payload for checking out a single paid product
        let payload = {
          products: [product],
          paymentGatewayName: "stripe",
          total_amount: Number(amount),
        };

        if ( productType ==="event")  payload["eventId"] = productId

        // if we are checking out a cart, we are getting the payload stored in session storage from the cart page and usingit here
        if (searchParams.get("isCart")) {
          console.log("cart checkout");
          payload = {
            products: JSON.parse(sessionStorage.getItem("cartCheckoutData")),
            paymentGatewayName: "stripe",
            total_amount: JSON.parse(
              sessionStorage.getItem("cartCheckoutData")
            ).reduce((acc, current) => {
              return acc + current.amount;
            }, 0),
          };
        }

        let res = await fetch( productType ==="event"?(BASE_URL +EndpointsSlug.INITIATE_EVENT_TRANS): (BASE_URL +EndpointsSlug.INITIATE_TRANS), {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        });

        if (res.ok) {
          res = await res.json();
        }

        if (res?.response?.statusEnum != 1) {
          throw new Error(res.response?.message);
        }

        setPaymentUrl(res.response?.data?.session?.url);
      } catch (e) {
        console.log(e);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    }

    // for subscription
    async function initiateSubscriptionTrans() {
      let payload = {
        channelId: channelId,
        paymentGatewayName: "stripe",
      };

      try {
        setLoading(true);

        let res = await fetch(
          BASE_URL + EndpointsSlug.CREATE_SUBSCRIPTION_MONTHLY,
          {
            method: "POST",
            body: JSON.stringify(payload),
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.ok) {
          res = await res.json();
        }

        if (res?.response?.statusEnum != 1) {
          throw new Error(res.response?.message);
        }

        setPaymentUrl(res.response?.data?.session?.url);
      } catch (e) {
        console.log(e);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    }

    if (!isSubscription) {
      initiateTrans();
    } else {
      initiateSubscriptionTrans();
    }
  }, []);

  useEffect(() => {
    if (!loading && paymentUrl) {
      const a = document.createElement("a");
      a.href = paymentUrl;
      a.click();
    }
  }, [loading]);

  return (
    <div className="relative min-h-screen w-full px-8">
      <div className="w-full h-full grid place-content-center">
        {loading && (
          <>
            <span className="block mx-auto">
              <Loader />
            </span>
            <p className="mt-2 text-lg text-white/50 font-body">
              You will soon be redirected to payments page.
            </p>
          </>
        )}
        {isError && (
          <p className="text-lg text-white/50 font-body">
            Something went wrong.
          </p>
        )}
      </div>
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default Checkout;
