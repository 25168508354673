import React from "react";
import PopupWrapper from "../PopupWrapper";
import CrossIcon from "assets/images/red_cross.png";

const Alert = ({ open, onClose, canClose,heading, message, isSuccess = true, buttonText='OK' }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="pt-8 pb-4 px-12 rounded-lg bg-[#262626]">
        <div className="__ICON_CONTAINER__ grid place-content-center">
          {isSuccess ? (
            <span className="rounded-full p-2 text-[#a1d683] border-8 border-[#a1d683]/25">
              <svg
                className="stroke-[#a1d683]"
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M21.5 6.99984L9.5 18.9998L4 13.4998L5.41 12.0898L9.5 16.1698L20.09 5.58984L21.5 6.99984Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          ) : (
            <div className="mx-auto rounded-full w-[120px] h-[120px] border-8 border-[#f17474]/25 grid place-content-center">
              <img
                className="w-[75px] h-[75px] object-contain"
                src={CrossIcon}
              />
            </div>
          )}
        </div>
        {isSuccess && <p className="__HEADING__ mt-8 text-3xl font-heading text-center">{heading}</p>}
        <p className={`mt-8 font-body font-medium text-center ${isSuccess ? 'text-lg' : 'text-xl'}`}>
            {message}
          </p>
        <div className="__ACTION_BTN_CONT__">
          <button
            className={`mt-8 mx-auto font-bold rounded-lg btn-gradient text-black py-3 px-12 flex justify-center items-center`}
            type="button"
            onClick={onClose}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Alert;
