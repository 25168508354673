import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ShadowFrame from "components/shadow-frame";
import Loader from "components/Loader/Loader";

const SideQuest = () => {
  const [instruction, setInstruction] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div class="my-10 lg:px-[30px] xl:px-[80px] 2xl:px-[220px] flex flex-col gap-y-[20px] justify-center">
      <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
      {!imageLoaded && (
        <div className="relative h-[calc(100vh-50px)] md:h-[450px] lg:h-[550px] xl:h-[700px] 2xl:h-[calc(100vh-50px)] grid place-content-center">
          <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <Loader />
        </div>
      )}

      <div className="relative">
        <picture className="" onLoad={onImageLoad}>
          <img
            src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest.png`}
            alt="network error"
            className="w-full h-auto  lg:w-auto object-cover"
          />
        </picture>

        <NavLink
          to="https://sidequestvr.com/app/31890"
          target="_blank"
          className="sidequest-button absolute top-[100px] left-[30px] sm:top-[170px] sm:left-[150px] md:top-[250px] md:left-[190px] lg:top-[280px] lg:left-[150px] xl:top-[300px] xl:left-[180px]  2xl:top-[350px] 2xl:left-[240px] font-bold text-xs py-0.5 px-3 lg:py-2.5 lg:px-7 rounded-[8px] bg-[#FBBC5E] text-[#1D1D1D]"
        >
          Download Now
        </NavLink>

        <p className="sidequest-para absolute top-[100px] left-[10px] sm:top-[180px] sm:left-[20px] md:top-[260px] md:left-[40px] lg:top-[310px] lg:left-[60px] xl:top-[330px] xl:left-[100px] 2xl:top-[380px] 2xl:left-[150px] my-5 text-[12px] md:text-[14px]">
          Don’t have SideQuest account?
          <span
            href="https://sidequestvr.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="my-5 text-[#FBBC5E] mx-1 cursor-pointer"
            onClick={() => setInstruction(!instruction)}
          >
            {instruction ? "Hide" : "See"} Instructions
          </span>
        </p>

        {instruction && (
          <div className="bg-[#1D1F25] px-[20px] lg:px-[100px] ">
            <p className="py-5">
              Join the Fourth Star Open Beta on SideQuest! Be among the first to
              experience our immersive VR game before its official launch on the
              Meta Store. SideQuest is a platform that allows us to reach a
              wider audience and gather valuable feedback from users like you.
              Your feedback is crucial in making Fourth Star the best it can be.
              Join us on SideQuest today and be a part of this exciting journey!
            </p>
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 1 - Make sure your Headset is connected to the Oculus App
                  on your mobile device.
                </h1>
                <p className="my-4 text-white/60 ">
                  To do this, you must have an Oculus account logged in on your
                  headset and the app.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step1.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 2 - Select your headset in the mobile app and follow
                  these steps to enable Debug mode.
                </h1>
                <ol className="mt-4 ml-[20px] list-decimal text-white/60 ">
                  <li>
                    Click on <span className="text-[#FBBC5E] ">‘Menu’</span>.
                  </li>
                  <li>
                    The click on{" "}
                    <span className="text-[#FBBC5E] ">‘Devices’</span>.
                  </li>
                  <li>
                    Click on the <span className="text-[#FBBC5E] ">Quest</span>{" "}
                    that you have just connected. 
                  </li>
                  <li>
                    Click on{" "}
                    <span className="text-[#FBBC5E] ">‘Headset Setting’</span>.
                  </li>
                  <li>
                    Then on{" "}
                    <span className="text-[#FBBC5E] ">‘Developer Mode’</span>.
                  </li>
                  <li>
                    Enable <span className="text-[#FBBC5E] ">‘Debug Mode’</span>{" "}
                    .
                  </li>
                </ol>
                <p className="my-5 text-white/60 ">
                  Congrats, the developer mode is enabled. Now we can move to
                  Side Quest Setup.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step2.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>

            <ShadowFrame className="absolute top-3/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 3 - SIGN IN/SIGN UP in SideQuest.
                </h1>
                <p className="mt-4 mb-5 text-white/60 ">
                  Visit{" "}
                  <a
                    href="https://sidequestvr.com/app/31890"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline my-5 text-[#FBBC5E] mx-1"
                  >
                    https://sidequestvr.com
                  </a>
                  to log in or sign up if you don’t have an account. After
                  Signing in click on 'GET SIDEQUEST' to proceed.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step3.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>

            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 4 - Now download the ‘Advanced Installer’.
                </h1>
                <p className="my-4 text-white/60 ">
                  Depending on your OS download the specific installer. After
                  downloading, proceed with installing it.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step4.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>

            <ShadowFrame className="absolute top-4/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 5 - Now connect your Headset with your system using a USB
                  cable.
                </h1>
                <p className="my-4 text-white/60 ">
                  Make sure you enable all the pop-ups that show up in your
                  headset after connecting. 
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step5.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>

            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 6 - Check if your headset is detectable by the SideQuest
                  Application.
                </h1>
                <p className="my-4 text-white/60 ">
                  If this is correct, your headset will be detected by the
                  SideQuest. 
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step6.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 7 - Now type “Fourth Star” in the search results.
                </h1>
                <p className="mt-4 text-white/60 ">
                  Make sure to type “Fourth star” exactly.
                </p>
                <p className=" text-white/60 ">OR</p>
                <p className=" text-white/60 ">
                  Alternatively, you can click on “web browser” then click on
                  “GO TO URL” and then paste this Url there -
                </p>
                <p className=" text-[#FBBC5E] ">
                  <a
                    href="https://sidequestvr.com/app/31890"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline my-5 text-[#FBBC5E] mx-1"
                  >
                    https://sidequestvr.com/app/31890
                  </a>
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step7.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 8 - Now click on “SIDELOAD” to start the download.
                </h1>
                <p className="my-4 text-white/60 ">
                  If you are coming from the browser, then after clicking on
                  side load it will show a pop-up at the bottom right side
                  saying “Sending to Side Quest” and then you will have to open
                  the application for the download to start.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step8.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
                <p className="my-4 text-white/60 ">
                  Downloading/ Installing should look like this.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step8b.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>
            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Step 9 - Then once it is done, check the ‘Unknown Sources’ in
                  your Headset.
                </h1>
                <p className="my-4 text-white/60 ">
                  Once it is installed, navigate to ‘Unknown Sources’ in your
                  headset to find the game, and click it to launch the game.
                </p>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step9.png`}
                  alt="network error"
                  className="w-full h-auto  lg:w-auto"
                />
              </div>
            </div>

            <div className="flex gap-x-4 mt-10">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
                />
                <svg
                  className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-semibold pb-10 ">
                  Step 10 - Congratulations, the game has been successfully
                  installed on your headset. Enjoy!
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="min-h-[52vh] md:min-h-0">
        <img
          src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/comingSoonMeta.png`}
          alt="network error"
          className="w-full h-auto lg:h-full lg:w-auto"
        />
      </div>
    </div>
  );
};

export default SideQuest;
