import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Entertainment-Data-Fetching-Api-Calls
 *@developer Shubham K
 */

export const EventsSlice = createApi({
  reducerPath: "Events",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEventsList: builder.query({
      query: ({ searchQuery = "", page = 1 }) => ({
        url:
          EndpointsSlug.GET_EVENT_LIST +
          "?searchQuery=" +
          searchQuery +
          "&page=" +
          page +
          "&limit=10",
        method: "GET",
      }),
    }),
    getEvent: builder.query({
      query: ({ eventId }) => ({
        url: EndpointsSlug.GET_ONE_EVENT + "/" + eventId,
        method: "GET",
      }),
    }),
    getRecommendedEvent: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_RECC_EVENT,
        method: "GET",
      }),
    }),
    getTodayEventsList: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_TODAY_EVENT_LIST,
        method: "GET",
      }),
    }),
    getUserEventsList: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_USER_EVENT_LIST + "?page=1&limit=9999",
        method: "GET",
      }),
    }),
    postAddParticipantToEvent: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.ADD_PARTICIPANT_TO_EVENT,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetEventsListQuery,
  useGetEventQuery,
  useGetRecommendedEventQuery,
  useGetTodayEventsListQuery,
  useGetUserEventsListQuery,
  usePostAddParticipantToEventMutation,
} = EventsSlice;
