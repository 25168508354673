import axios from "axios";

const webRequestHandler = (url) => {
  const isClientSide = typeof window !== "undefined";
  const token = isClientSide ? localStorage.getItem("accessToken") : null;

  const instance = axios.create({
    baseURL: url,
    headers: {
      Authorization: isClientSide && token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    // ... other options
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // You can modify the response data here, e.g., handling pagination
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default webRequestHandler;
