import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, EndpointsSlug } from 'constant/api/Endpoints'

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-Notification-Reducer-functions
 *@developer Aditya P
 */

export const UserNotification = createApi({
    reducerPath: 'UserNotification',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken') || "";
            headers.set('Content-type', 'application/json; charset=UTF-8')
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
                
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
        GetAllUserNotification: builder.query({
            query: () => ({
                url: EndpointsSlug.GET_USER_ALL_NOTIFICATION,
                method: 'GET',
            })
        }),
        GetSingleNotification: builder.query({
            query: ({notificationId}) => ({
                url: EndpointsSlug.GET_SINGLE_NOTIFICATION,
                method: 'GET',
                params: {notificationId}
            })
        }),
        PatchReadAllNotification: builder.mutation({
            query: (payload) => ({
                url: EndpointsSlug.READ_ALL_NOTIFICATION,
                method: 'PATCH',
                body: {payload}
            })
        })
    })
})

export const {
    useGetAllUserNotificationQuery,
    useGetSingleNotificationQuery,
    usePatchReadAllNotificationMutation
} = UserNotification