import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InvitefriendIcon from "assets/icons/team 1.png";
import Alert from "components/popup/Alert/Alert";
import {
  useGetFriendListApartmentAssetQuery,
  useInviteFriendApartmentAssetMutation,
  useRemoveFriendApartmentAssetMutation,
  useGetFriendListShipAssetQuery,
  useInviteFriendShipAssetMutation,
  useRemoveFriendShipAssetMutation,
} from "store/slicer/friends";
import Loader from "components/Loader/Loader";

const FriendErrorLimitModal = ({ onClose }) => {
  return (
    <>
      <Alert
        open={true}
        onClose={() => onClose()}
        message={
          "Sorry, you've reached the maximum limit of 4 friends for invitations. Please remove a friend before adding another."
        }
        heading={"You can only invite 4 friends."}
        isSuccess={false}
      />
    </>
  );
};

const EmptyFriendListView = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[300px] max-h-[400px] text-center p-8">
      <img src={InvitefriendIcon} className="w-[61px] h-[61px]" />
      <h4 className="text-[16px] font-bold font-body pt-[15px]">
        0 Friends Invited
      </h4>
      <p className="pt-[20px] text-[14px] text-[#7A7A7A] font-bold font-body">
        To invite a friend, simply click on the toggle button next to their
        name.
      </p>
    </div>
  );
};

const ListView = ({ data, onInvitedClick, isInvited, type }) => {
  return (
    <div className="__list__item__section flex justify-between border-b border-b-[#7A7A7A33] py-4">
      <div className="__user__data__section flex gap-[8px]">
        <div className="__avatar__profile__section">
          <img
            src={
              data.photo ||
              "static/media/_user_default_profile.07300db72dd3c12efff9.png"
            }
            className="w-[56px] h-[56px] rounded-full"
          />
        </div>
        <div className="__content__section flex flex-col justify-center items-start">
          <h4 className="text-[16px] font-Inter font-medium">
            {data?.userName}
          </h4>
          <p className="text-[16px] font-Inter font-normal text-[#FFFFFF80]">
            {data.firstName || ""} {data.lastName || ""}
          </p>
        </div>
      </div>
      <div className="__action__switch__section flex justify-center items-center">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            onClick={() => onInvitedClick()}
            type="checkbox"
            value=""
            className="sr-only peer"
            checked={isInvited}
          />
          <div
            className="
                      w-11
                      h-6
                      bg-[#474747] 
                      peer-focus:outline-none 
                      rounded-full 
                      peer 
                      dark:bg-[#FBBC5E] 
                      peer-checked:after:translate-x-full 
                      rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-[#FBBC5E] 
                      after:content-[''] 
                      after:absolute 
                      after:top-[3px] 
                      after:start-[4px] 
                      after:bg-[#7E7E7E] 
                      peer-checked:after:bg-[#FBBC5E] 
                      after:border-[#7E7E7E] 
                      after:border 
                      after:rounded-full 
                      after:h-[18px] 
                      after:w-[18px] 
                      after:transition-all 
                      dark:border-gray-600 
                      peer-checked:bg-[#F3A1514D]"
          ></div>
        </label>
      </div>
    </div>
  );
};

const AssetInvite = ({ type, iapId, particularData, handelBack }) => {
  const navigate = useNavigate();
  const useAssetQueryHook =
    type === "APARTMENT"
      ? useGetFriendListApartmentAssetQuery
      : useGetFriendListShipAssetQuery;

  const useAssetInviteQueryHook =
    type === "APARTMENT"
      ? useInviteFriendApartmentAssetMutation
      : useInviteFriendShipAssetMutation;

  const useAssetRemoveQueryHook =
    type === "APARTMENT"
      ? useRemoveFriendApartmentAssetMutation
      : useRemoveFriendShipAssetMutation;
  const {
    data: friendData,
    isLoading,
    error,
    status,
    refetch,
  } = useAssetQueryHook(
    type === "APARTMENT" ? { apartmentId: iapId } : { shipId: iapId }
  );
  const friendWithoutInvitation = friendData?.response.data.filter(
    (item) => item.access === false
  );
  const friendWithInvitation = friendData?.response.data.filter(
    (item) => item.access === true
  );
  // const [isFriendInvited, setFriendInvited] = useState(true);
  // const [isInvite, setInvite] = useState(false);
  const [FrienderrorModal, setFriendErrorModal] = useState(false);
  const [addInviteFriend] = useAssetInviteQueryHook();
  const [removeInviteFriend] = useAssetRemoveQueryHook();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const handleInviteSwitch = async (id) => {
    if (friendWithInvitation.length > 3) {
      setFriendErrorModal(!FrienderrorModal);
      console.log("Greater than 4 limit");
      return;
    }
    setLoadingAdd(true);
    await addInviteFriend(
      type === "APARTMENT"
        ? {
            apartmentId: iapId,
            friendId: id,
          }
        : {
            shipId: iapId,
            friendId: id,
          }
    );
    refetch();
    setLoadingAdd(false);
  };

  const handleRemoveSwitch = async (id) => {
    setLoadingRemove(true);
    await removeInviteFriend(
      type === "APARTMENT"
        ? {
            apartmentId: iapId,
            friendId: id,
          }
        : {
            shipId: iapId,
            friendId: id,
          }
    );
    refetch();
    setLoadingRemove(false);
    // setInvite(!isInvite);
  };

  return (
    <>
      <div className="__assets__invite__main pl-[15px] max-sm:pl-0">
        <div className="__back__container__section flex justify-between pb-[12px]">
          <button
            onClick={() => handelBack()}
            className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>

        <div className="__heading pt-[30px] max-sm:pt-[15px]">
          <h4 className="text-2xl max-sm:text-sm font-normal font-heading">
            Invite Friends to your {type === "APARTMENT" ? "Apartment" : "Ship"}{" "}
          </h4>
          <p className="text-[#7A7A7A] text-sm max-sm:text-xs font-bold font-body pt-[16px] max-sm:pt-[8px]">
            Extend Invitations to Your Friends and Join the Immersive Experience
            Together.
          </p>
        </div>

        <div className="__invite__dialog__section__main grid grid-cols-2 max-sm:grid-cols-1 gap-x-[20px] gap-y-[20px] pt-[50px]">
          <div className="__list__of__friends__section bg-[#FBBC5E0D] h-[900px] max-h-[960px] overflow-y-scroll rounded-[8px] p-6">
            <h4 className="text-[20px] font-bold font-body text-[#FFFFFFCC]">
              List of friends:
            </h4>
            <div className="__listing__of__friends__section pt-[30px]">
              {/* {InactiveFriendListData?.map((item, index) => {
                return (
                  <ListView
                    key={index}
                    data={item}
                    onInvitedClick={() => handleInviteSwitch(item?.id)}
                    isInvited={isInvite}
                    type="INVITE"
                  />
                );
              })} */}
              {loadingAdd || isLoading ? (
                <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                  <div className="__no_friend_container flex flex-col text-center">
                    <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                      <Loader />
                    </h4>
                  </div>
                </div>
              ) : friendData?.response.statusEnum === 1 ? (
                friendWithoutInvitation.length === 0 ||
                friendData.length === 0 ? (
                  <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                    <div className="__no_friend_container flex flex-col text-center">
                      <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                        {friendData.length === 0
                          ? "You Don’t have a friend yet"
                          : "All your friends are invited"}
                      </h4>
                      <button
                        onClick={() => navigate("/friends")}
                        className="w-[222px] 
                                   py-[6px] 
                                   border 
                                   border-[#F3A151] 
                                   mt-[20px] 
                                   rounded-[8px] 
                                   hover:bg-[#F3A151] 
                                   hover:text-[#000] 
                                   font-medium 
                                   font-body
                                   max-[768px]:py-[3px]
                                   max-[768px]:text-[13px]
                                   "
                      >
                        Invite {friendData.length !== 0 && "more "}friends
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {friendWithoutInvitation.map((item, index) => {
                      return (
                        <ListView
                          key={index}
                          data={item}
                          onInvitedClick={() =>
                            handleInviteSwitch(item?.friendId)
                          }
                          isInvited={false}
                          type="INVITE"
                        />
                      );
                    })}
                  </>
                )
              ) : (
                "There is some problem while fetching the friends! Try again"
              )}
            </div>
          </div>

          <div
            className={`__invite__to__${
              type === "APARTMENT" ? "Apartment" : "Ship"
            }__section bg-[#282828] py-6 px-[24px] border border-[#656565] h-[900px] max-h-[960px] overflow-y-scroll rounded-[8px]`}
          >
            <h4 className="text-[20px] font-bold font-body text-[#FFFFFFCC] text-center">
              Invite to {type === "APARTMENT" ? "Apartment" : "Ship"}
            </h4>

            <div className="__asset__info__section">
              <div className="__asset__banner__section pt-[24px]">
                <img src={particularData?.img} />
              </div>
              <div className="__info__section pt-[12px] flex gap-[20px]">
                <div className="__key__name [&>p]:text-[16px] [&>p]:font-semibold [&>p]:text-[#7A7A7A] flex flex-col">
                  <p>
                    {type === "APARTMENT" ? "Apartment Name:" : "Ship Name:"}
                  </p>
                  <p>
                    {type === "APARTMENT"
                      ? "Apartment Number:"
                      : "Ship Number:"}
                  </p>
                </div>
                <div className="__key__data flex flex-col">
                  <p>{particularData?.iapName}</p>
                  <p>
                    {type === "APARTMENT"
                      ? particularData?.iapDetails?.apartmentNumber
                      : particularData?.iapDetails?.shipNumber}
                  </p>
                </div>
              </div>
            </div>

            <h5 className="pt-[36px] text-[#FBBC5E] text-[14px] font-medium font-body">
              You can Invite up to 4 Friends granting them access to your
              apartment.
            </h5>
            <h5 className="pt-[20px] text-[#7A7A7A] text-[14px] font-bold font-body">
              Invited friends:
            </h5>

            <div className="__invited__friends__list__section">
              {!loadingRemove &&
                friendWithInvitation?.map((item, index) => {
                  return (
                    <ListView
                      key={index}
                      data={item}
                      onInvitedClick={() => handleRemoveSwitch(item?.friendId)}
                      isInvited={true}
                      type="INVITED"
                    />
                  );
                })}
              {(loadingRemove || isLoading) && (
                <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                  <div className="__no_friend_container flex flex-col text-center">
                    <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                      <Loader />
                    </h4>
                  </div>
                </div>
              )}
              {!loadingRemove &&
                friendWithInvitation &&
                friendWithInvitation.length === 0 && <EmptyFriendListView />}
            </div>
          </div>
        </div>
      </div>

      {FrienderrorModal && (
        <FriendErrorLimitModal
          onClose={() => setFriendErrorModal(!FrienderrorModal)}
        />
      )}
    </>
  );
};

export default AssetInvite;
